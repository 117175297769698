import React, { useState, useContext, useEffect } from "react";
import "./edit-profile.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import CatDrop from "../../widgets/categories";
import GlobelFooter from "../../widgets/footer";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const EditProfile = () => {
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);

  const handleEmailBtnClick = () => {
    setIsEditingEmail(!isEditingEmail);
  };

  const handleNameBtnClick = () => {
    setIsEditingName(!isEditingName);
  };

  setTitle("Edit Profile - SwiftCity");
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GlobelHeader />
      <CatDrop />

      <div
        className="edpro-container"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="edpro-body">
          <div
            style={{
              fontSize: "1.5rem",
              padding: "12px 0",
            }}
          >
            Edit profile details
          </div>
          <div className="edpro-tiles">
            <div
              className="edpro-editile"
              style={{
                display: "flex",
                gap: "12px",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                className="edpro-imgbox"
                style={{
                  minWidth: "70px",
                  height: "70px",
                  borderRadius: "50%",
                  backgroundColor: "#f1f1f1",
                  position: "relative",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src="./favicon.ico"
                  alt="Profile"
                />
                <AddAPhotoIcon
                  sx={{
                    color: "#505050",
                    fontSize: "20px",
                    backgroundColor: "white",
                    padding: "1px",
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div
                className="edpro-editname"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <label className="edpro-label" htmlFor="">
                  Full name
                </label>
                {isEditingName ? (
                  <input type="text" />
                ) : (
                  <span>Yogesh Kumar</span>
                )}
              </div>
              <button
                style={{
                  borderColor: isEditingName ? "white" : "#dddddd",
                  backgroundColor: isEditingName ? "rgb(248, 190, 0)" : "white",
                }}
                onClick={handleNameBtnClick}
                className="edpro-action-btn"
              >
                {isEditingName ? "Save" : "Edit"}
              </button>
            </div>
            <div
              className="edpro-editile"
              style={{
                display: "flex",
                gap: "12px",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                className="edpro-editname"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <label className="edpro-label" htmlFor="">
                  Email Address
                </label>
                {isEditingEmail ? (
                  <input type="email" />
                ) : (
                  <span>swiftcity.in@gmail.com</span>
                )}
              </div>
              <button
                style={{
                  borderColor: isEditingEmail ? "white" : "#dddddd",
                  backgroundColor: isEditingEmail
                    ? "rgb(248, 190, 0)"
                    : "white",
                }}
                onClick={handleEmailBtnClick}
                className="edpro-action-btn"
              >
                {isEditingEmail ? "Save" : "Edit"}
              </button>
            </div>
            <div
              className="edpro-editile"
              style={{
                display: "flex",
                gap: "10px",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                className="edpro-editname"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <span className="edpro-label">Phone Number</span>
                <span className="edpro-user-data">+91 8118933789</span>
                <div
                  className="swift-alert"
                  style={{
                    marginTop: "8px",
                  }}
                >
                  Phone number can't be changed once registered!
                </div>
              </div>
            </div>
            <button
              className="edpro-delete-btn"
              style={{
                border: "none",
                fontSize: "0.8rem",
                margin: "12px",
                backgroundColor: "white",
                fontWeight: "500",
              }}
            >
              Deactivate/Delete account →
            </button>
          </div>
        </div>
      </div>
      <GlobelFooter />
    </div>
  );
};

export default EditProfile;
