import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import GlobelHeader from "../../widgets/header";
import { generateClient } from "aws-amplify/api";
import { getListing, reviewsByTime } from "../../graphql/queries";
import "./review.css";
import { setTitle } from "../../widgets/setTitle";
import Rating from "@mui/material/Rating";
import { getUrl } from "aws-amplify/storage";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { useNavigate, useLocation } from "react-router-dom";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ProductReview = () => {
  const client = generateClient();
  const location = useLocation();
  const linkedListingID = new URLSearchParams(location.search).get("slid");
  const [isLoading, setIsLoading] = useState(true);
  const [reviewList, setReviewList] = useState([]);
  const [listingImg, setlistingImg] = useState("");
  const [litingTitle, setLitingTitle] = useState("");
  const [sellerName, setSellerName] = useState("");
  let sellerID = "";
  const [isReviewPopup, setReviewPopup] = useState(false);
  const [reviewImgInd, setReviewImgInd] = useState(0);
  const [listingRating, setListingRating] = useState("");
  const [popupIndRev, setPopupIndRev] = useState({});
  const [revPopupImgs, setRevPopupImgs] = useState([]);
  const revPopupRef = useRef(null);
  const [liRatingPercent, setLiRatingPercent] = useState({
    four: 0,
    three: 0,
    two: 0,
    one: 0,
  });

  const getListingReviews = async () => {
    try {
      const res = await client.graphql({
        query: reviewsByTime,
        variables: {
          type: "Review",
          sortDirection: "DESC",
          filter: {
            listing_id: { eq: linkedListingID },
            and: [
              {
                approved: { eq: true },
              },
              {
                is_review: { eq: true },
              },
            ],
          },
        },
      });

      setReviewList(res.data.reviewsByTime.items);
      document.body.style.overflow = "auto";
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Reviews:", error);
    }
  };

  const getMyListing = async () => {
    setTitle("Customer reviews");

    try {
      document.body.style.overflow = "hidden";
      const result = await client.graphql({
        query: getListing,
        variables: { id: linkedListingID },
      });
      const url = await fetchImageUrl(result.data.getListing.p_image_paths[0]);
      setlistingImg(url);
      setTitle(`Customer reviews: ${result.data.getListing.p_title}`);
      setLitingTitle(result.data.getListing.p_title);
      setSellerName(result.data.getListing.p_seller_id);
      setListingRating(result.data.getListing.p_rating.toString());
      sellerID = result.data.getListing.p_seller_id;
      const ratingsCount = result.data.getListing.p_ratings_type_count;
      const totalRatings =
        ratingsCount.five +
        ratingsCount.four +
        ratingsCount.three +
        ratingsCount.two +
        ratingsCount.one;
      setLiRatingPercent({
        four: calculateRatePercentage(ratingsCount.four, totalRatings),
        three: calculateRatePercentage(ratingsCount.three, totalRatings),
        two: calculateRatePercentage(ratingsCount.two, totalRatings),
        one: calculateRatePercentage(ratingsCount.one, totalRatings),
      });
      getListingReviews();
    } catch (error) {
      console.error("Error fetching Listing", error);
    }
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  const convertTimeStamp = (awsTime) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(awsTime).toLocaleDateString("en-US", options);
    return date;
  };

  const setPopupImgList = async (rev, ind) => {
    try {
      const imgUrls = await Promise.all(
        rev.images.map(async (path) => {
          const imgUrl = await fetchImageUrl(path);
          return imgUrl;
        })
      );
      setRevPopupImgs(imgUrls);
      setPopupIndRev(rev);
      setReviewImgInd(ind);
      setReviewPopup(true);
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error("Error fetching image URLs:", error);
    }
  };

  const reviewImgNext = () => {
    if (revPopupImgs.length - 1 === reviewImgInd) {
      setReviewImgInd(0);
    } else {
      setReviewImgInd(reviewImgInd + 1);
    }
  };

  const reviewImgPrev = () => {
    if (reviewImgInd === 0) {
      setReviewImgInd(revPopupImgs.length - 1);
    } else {
      setReviewImgInd(reviewImgInd - 1);
    }
  };

  const reviewPopupClose = () => {
    document.body.style.overflow = "auto";
    setRevPopupImgs([]);
    setReviewPopup(false);
  };

  const calcuFourRate = () => {
    const totalRatings =
      100 -
      liRatingPercent.four -
      liRatingPercent.three -
      liRatingPercent.two -
      liRatingPercent.one;
    return totalRatings;
  };

  const calculateRatePercentage = (min, max) => {
    if (max === 0) return 0;
    return parseInt((min / max) * 100);
  };

  useEffect(() => {
    getMyListing();
  }, []);

  return (
    <>
      <GlobelHeader />
      <div className="prbody">
        <div className="pr-top-container">
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                height: "80px",
                width: "64px",
                backgroundColor: "#efefef",
                borderRadius: "4px",
              }}
            >
              <img
                style={{
                  height: "80px",
                  width: "64px",
                  objectFit: "cover",
                  borderRadius: "4px",
                }}
                src={listingImg}
                alt="product-view"
              />
            </div>
            <div
              className="pr-listing-data"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <h3
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "500",
                }}
              >
                {litingTitle}
              </h3>
              <div
                style={{
                  fontSize: "0.8rem",
                }}
              >
                <span>Seller:</span>
                <span
                  style={{
                    fontWeight: "600",
                    color: "#0084ff",
                    marginLeft: "5px",
                  }}
                >
                  {sellerName}
                </span>
              </div>
              <button
                style={{
                  marginLeft: "auto",
                  marginTop: "auto",
                  backgroundColor: "white",
                  color: "#0084ff",
                  padding: "6px 10px",
                  borderRadius: "18px",
                  border: "1px solid #0084ff",
                  fontWeight: "600",
                }}
              >
                Write a review
              </button>
            </div>
          </div>
          <div className="jo56h">
            <div
              style={{
                padding: "10px",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                borderRadius: "20px",
                border: "1px solid #dddddd",
              }}
            >
              <div
                style={{
                  fontSize: "1.7rem",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "5px",
                }}
              >
                <span
                  style={{
                    fontSize: "1.8rem",
                    fontWeight: "600",
                    color: "#ff0048",
                  }}
                >
                  {listingRating}
                </span>
                <StarSharpIcon sx={{ fontSize: 30, color: "#ff0048" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderLeft: "1px solid #dddddd",
                  paddingLeft: "5px",
                }}
              >
                <GroupsRoundedIcon
                  sx={{ fontSize: 20, color: "#000", margin: "0 5px" }}
                />
                <span
                  style={{
                    fontSize: "0.9rem",
                    color: "#000",
                  }}
                >
                  {reviewList.length} people
                </span>
              </div>
            </div>
            <div className="review-data-box">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "#0085ba",
                      fontWeight: "600",
                    }}
                  >
                    5
                  </span>
                  <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "#0085ba",
                      fontWeight: "600",
                    }}
                  >
                    4
                  </span>
                  <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "#0085ba",
                      fontWeight: "600",
                    }}
                  >
                    3
                  </span>
                  <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "#0085ba",
                      fontWeight: "600",
                    }}
                  >
                    2
                  </span>
                  <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.7rem",
                      color: "#0085ba",
                      fontWeight: "600",
                    }}
                  >
                    1
                  </span>
                  <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
                </div>
              </div>
              <div
                className="rate-row-colum"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    margin: "0 4px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: `${calcuFourRate()}%`,
                      backgroundColor: "#0085ba",
                      height: "10px",
                      transition: "width 0.3s ease",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100%",
                    margin: "0 4px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: `${liRatingPercent.four}%`,
                      backgroundColor: "#0085ba",
                      height: "10px",
                      transition: "width 0.3s ease",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100%",
                    margin: "0 4px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: `${liRatingPercent.three}%`,
                      backgroundColor: "#0085ba",
                      height: "10px",
                      transition: "width 0.3s ease",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100%",
                    margin: "0 4px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: `${liRatingPercent.two}%`,
                      backgroundColor: "#0085ba",
                      height: "10px",
                      transition: "width 0.3s ease",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    width: "100%",
                    margin: "0 4px",
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    overflow: "hidden",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  <div
                    style={{
                      width: `${liRatingPercent.one}%`,
                      backgroundColor: "#0085ba",
                      height: "10px",
                      transition: "width 0.3s ease",
                    }}
                  ></div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                    height: "10px",
                    width: "fit-content",
                  }}
                >
                  {`${calcuFourRate()}%`}
                </div>
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                    height: "10px",
                    width: "fit-content",
                  }}
                >
                  {`${liRatingPercent.four}%`}
                </div>
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                    height: "10px",
                    width: "fit-content",
                  }}
                >
                  {`${liRatingPercent.three}%`}
                </div>
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                    height: "10px",
                    width: "fit-content",
                  }}
                >
                  {`${liRatingPercent.two}%`}
                </div>
                <div
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                    height: "10px",
                    width: "fit-content",
                  }}
                >
                  {`${liRatingPercent.one}%`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pr-rvspagination">
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "600",
            }}
          >
            {`Customer reviews (${reviewList.length})`}
          </span>
          <div
            className="prew-list"
            style={{
              marginTop: "12px",
            }}
          >
            {reviewList.map((rvw, rvInd) => (
              <div
                className="rv-li-tile"
                key={rvInd}
                style={{
                  backgroundColor: rvInd % 2 === 0 ? "#f4f4f4" : "white",
                  padding: "15px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div
                  className="rv-li-tile-head"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="li-rv-profile"
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                      }}
                    >
                      <StorageImage
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        alt="cat"
                        path={rvw.user_img}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "600",
                        }}
                      >
                        {rvw.user_name}
                      </span>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "600",
                          fontStyle: "italic",
                          color: "#5b5b5b",
                        }}
                      >
                        {convertTimeStamp(rvw.createdAt)}
                      </span>
                    </div>
                  </div>
                  <Rating
                    name="half-rating-read"
                    defaultValue={rvw.rating}
                    precision={0.1}
                    readOnly
                    sx={{
                      color: "#ff0048",
                    }}
                  />
                </div>
                <div
                  className="rv-liti-body"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      color: "#1f1f1f",
                    }}
                  >
                    {rvw.review_title}
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      color: "#383838",
                      marginTop: "4px",
                    }}
                  >
                    {rvw.review_desc}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    {rvw.images.map((imgg, imgInd) => (
                      <div
                        className="li-rvimg"
                        key={imgInd}
                        onClick={() => setPopupImgList(rvw, imgInd)}
                        style={{
                          borderRadius: "2px",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      >
                        <StorageImage
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "2px",
                            objectFit: "cover",
                          }}
                          alt="review-swiftcity"
                          path={imgg}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isReviewPopup && (
        <div className="lisingrev-overlay">
          <div className="lisingrev-box" ref={revPopupRef}>
            <div
              className="lisingrev-head"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                All photos
              </span>
              <button
                className="close-lisingrev-btn"
                onClick={reviewPopupClose}
              >
                <CloseRoundedIcon
                  sx={{
                    fontSize: 26,
                    color: "#000",
                    fontWeight: "600",
                  }}
                />
              </button>
            </div>
            <div
              className="lisingrev-content"
              style={{
                display: "flex",
                gap: "12px",
              }}
            >
              <div
                className="main-lirevimglistcont"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  objectFit: "cover",
                  position: "relative",
                }}
              >
                {revPopupImgs.length > 1 && (
                  <button
                    onClick={reviewImgPrev}
                    style={{
                      left: "0",
                      borderRadius: "50%",
                      minWidth: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      backgroundColor: "rgba(41, 41, 41, 0.5)",
                      zIndex: "10",
                      position: "absolute",
                    }}
                  >
                    <ArrowBackIosRoundedIcon
                      sx={{
                        fontSize: 20,
                        color: "#fff",
                      }}
                    />
                  </button>
                )}
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#dddddd",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    className="lirvindimg"
                    style={{
                      width: "100%",
                      aspectRatio: "1/1",
                      borderRadius: "2px",
                      objectFit: "contain",
                    }}
                    alt="review-swiftcity"
                    src={revPopupImgs[reviewImgInd]}
                  />
                </div>
                {revPopupImgs.length > 1 && (
                  <button
                    onClick={reviewImgNext}
                    style={{
                      right: "0",
                      borderRadius: "50%",
                      minWidth: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      backgroundColor: "rgba(41, 41, 41, 0.5)",
                      zIndex: "10",
                      position: "absolute",
                    }}
                  >
                    <ArrowForwardIosRoundedIcon
                      sx={{
                        fontSize: 20,
                        color: "#fff",
                      }}
                    />
                  </button>
                )}
              </div>
              <div
                className="rv-anodatabox"
                style={{
                  flexDirection: "column",
                  maxWidth: "300px",
                  padding: "10px",
                  borderRadius: "12px",
                  backgroundColor: "#f3f3f3",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "30px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="li-rv-profile"
                      style={{
                        width: "35px",
                        height: "35px",
                        minWidth: "35px",
                        borderRadius: "50%",
                        border: "1px solid blue",
                      }}
                    >
                      <StorageImage
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                          padding: "1px",
                        }}
                        alt="cat"
                        path={popupIndRev.user_img}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "600",
                        }}
                      >
                        {popupIndRev.user_name}
                      </span>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "600",
                          fontStyle: "italic",
                          color: "#5b5b5b",
                        }}
                      >
                        {convertTimeStamp(popupIndRev.createdAt)}
                      </span>
                    </div>
                  </div>
                  <div className="rating-data-box">
                    <Rating
                      name="half-rating-read"
                      size="small"
                      defaultValue={popupIndRev.rating}
                      precision={0.1}
                      readOnly
                      sx={{
                        color: "#ff0048",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "#1f1f1f",
                    marginTop: "15px",
                  }}
                >
                  {popupIndRev.review_title}
                </div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: "#383838",
                    marginTop: "4px",
                  }}
                >
                  {popupIndRev.review_desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default ProductReview;
