import React, { useEffect } from "react";

const Toast = ({ message, showToast, setShowToast }) => {
  useEffect(() => {
    let timer;
    if (showToast) {
      timer = setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showToast, setShowToast]);

  return (
    <div className={`toast ${showToast ? "show" : ""}`}>
      <div className="toast-message">{message}</div>
    </div>
  );
};

export default Toast;
