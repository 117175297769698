import React, { useState, useRef, useEffect, useContext } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import "./widgets.css";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { UserContext } from "./../userContext";

const GlobelHeader = ({ top, isMobLocSel, onMouseEnter }) => {
  const { user, loading } = useContext(UserContext);
  const suggestionsList = [
    "Apple",
    "Banana",
    "Orange",
    "Grapes",
    "Mango",
    "Peach",
    "Strawberry",
    "Apple",
    "Banana",
    "Orange",
    "Grapes",
    "Mango",
    "Peach",
    "Strawberry",
    "Apple",
    "Banana",
    "Orange",
    "Grapes",
    "Mango",
    "Peach",
    "Strawberry",
    "Apple",
    "Banana",
    "Orange",
    "Grapes",
    "Mango",
    "Peach",
    "Strawberry",
  ];

  const locations = [
    { city: "Shimla", town: "Mashobra", pincode: "171007" },
    { city: "Shimla", town: "Kufri", pincode: "171012" },
    { city: "Manali", town: "Old Manali", pincode: "175131" },
    { city: "Dharamshala", town: "McLeod Ganj", pincode: "176219" },
    { city: "Solan", town: "Kasauli", pincode: "173204" },
    { city: "Kullu", town: "Naggar", pincode: "175130" },
    { city: "Bilaspur", town: "Ghumarwin", pincode: "174021" },
    { city: "Chamba", town: "Dalhousie", pincode: "176304" },
    { city: "Una", town: "Gagret", pincode: "177201" },
    { city: "Mandi", town: "Joginder Nagar", pincode: "175015" },
    { city: "Hamirpur", town: "Nadaun", pincode: "177033" },
    { city: "Kangra", town: "Palampur", pincode: "176061" },
    { city: "Solan", town: "Barog", pincode: "173211" },
    { city: "Kinnaur", town: "Kalpa", pincode: "172108" },
    { city: "Kullu", town: "Manikaran", pincode: "175105" },
    { city: "Mandi", town: "Sundernagar", pincode: "175018" },
    { city: "Chamba", town: "Khajjiar", pincode: "176314" },
    { city: "Kangra", town: "Dharamkot", pincode: "176219" },
    { city: "Solan", town: "Nalagarh", pincode: "174101" },
    { city: "Kullu", town: "Banjar", pincode: "175123" },
    { city: "Shimla", town: "Chail", pincode: "173217" },
    { city: "Mandi", town: "Tattapani", pincode: "175009" },
    { city: "Lahaul-Spiti", town: "Keylong", pincode: "175132" },
    { city: "Bilaspur", town: "Naina Devi", pincode: "174310" },
    { city: "Hamirpur", town: "Sujanpur", pincode: "176110" },
    { city: "Kangra", town: "Kangra", pincode: "176001" },
    { city: "Solan", town: "Chail", pincode: "173217" },
    { city: "Una", town: "Amb", pincode: "177203" },
    { city: "Kinnaur", town: "Sangla", pincode: "172106" },
    { city: "Mandi", town: "Pandoh", pincode: "175124" },
    { city: "Shimla", town: "Fagu", pincode: "171209" },
    { city: "Kullu", town: "Kasol", pincode: "175105" },
    { city: "Chamba", town: "Banikhet", pincode: "176303" },
    { city: "Lahaul-Spiti", town: "Kaza", pincode: "172114" },
    { city: "Solan", town: "Parwanoo", pincode: "173220" },
    { city: "Shimla", town: "Tara Devi", pincode: "171010" },
    { city: "Mandi", town: "Rewalsar", pincode: "175023" },
    { city: "Kinnaur", town: "Puh", pincode: "172111" },
    { city: "Kullu", town: "Sainj", pincode: "175134" },
    { city: "Una", town: "Mehatpur", pincode: "174315" },
    { city: "Chamba", town: "Bharmour", pincode: "176315" },
    { city: "Hamirpur", town: "Tauni Devi", pincode: "177023" },
    { city: "Shimla", town: "Theog", pincode: "171201" },
    { city: "Mandi", town: "Karsog", pincode: "175011" },
    { city: "Kangra", town: "Nagrota Bagwan", pincode: "176047" },
    { city: "Kinnaur", town: "Rakchham", pincode: "172106" },
    { city: "Lahaul-Spiti", town: "Tabo", pincode: "172113" },
    { city: "Solan", town: "Solan", pincode: "173212" },
  ];

  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const searchSuggRef = useRef(null);
  const locPopRef = useRef(null);
  const locDropRef = useRef(null);
  const [isSearchDrop, setIsSearchDrop] = useState(false);
  const [isLocationPop, setIsLocationPop] = useState(false);
  const [filteredlocs, setFilteredlocs] = useState([]);
  const [citySuggShow, setCitySuggShow] = useState(false);
  const [selectedPin, setSelectedPin] = useState("171007");
  const [locDropInput, setLocDropInput] = useState("");
  const [isValidPin, setIsValidPin] = useState(false);

  const handleChange = (e) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    const filtered = suggestionsList.filter((item) =>
      item.toLowerCase().includes(userInput.toLowerCase())
    );

    if (filtered.length > 0) {
      setIsSearchDrop(true);
      setFilteredSuggestions(filtered);
    } else {
      setIsSearchDrop(false);
    }
  };

  const handleLocChange = (event) => {
    const value = event.target.value.trim();
    const preValue = locDropInput;
    if (/^\d*$/.test(value)) {
      if (value !== "") {
        setLocDropInput(value);
        const filteredResults = locations
          .filter((loc) => loc.pincode.includes(value))
          .map((loc) => `${loc.pincode}`);
        setFilteredlocs(filteredResults);
        setCitySuggShow(filteredResults.length > 0);
        checkIsEnteredPin(value);
      } else {
        setLocDropInput("");
        const filteredResults = locations.map((location) => location.pincode);
        setFilteredlocs(filteredResults);
        setCitySuggShow(true);
      }
    }
  };

  const dropLocClick = (pincode) => {
    setFilteredlocs([pincode]);
    setLocDropInput(pincode);
    checkIsEnteredPin(pincode);
    setCitySuggShow(false);
  };

  const getCitybyPin = (pincode) => {
    const location = locations.find((loc) => loc.pincode === pincode);
    if (location) {
      return `${location.city}`;
    } else {
      return "";
    }
  };

  const getTownCitybyPin = (pincode) => {
    const location = locations.find((loc) => loc.pincode === pincode);
    if (location) {
      return `${location.town}, ${location.city}`;
    } else {
      return "";
    }
  };

  const checkIsEnteredPin = (value) => {
    const bool = locations.find((loc) => loc.pincode === value);
    setIsValidPin(bool);
  };

  const onPinSubmit = () => {
    if (isValidPin) {
      setSelectedPin(locDropInput);
      setIsLocationPop(false);
    }
  };

  const handleDropClose = (event) => {
    if (
      searchSuggRef.current &&
      !searchSuggRef.current.contains(event.target)
    ) {
      setIsSearchDrop(false);
    }
  };

  const toggleLocPopup = () => {
    setIsLocationPop(!isLocationPop);
  };

  const handleSuggClick = (value) => {
    setInputValue(value);
    setIsSearchDrop(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDropClose);
    return () => {
      document.removeEventListener("mousedown", handleDropClose);
    };
  }, []);

  const handleLocPopClose = (event) => {
    if (locPopRef.current && !locPopRef.current.contains(event.target)) {
      setIsLocationPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleLocDropClose);
    return () => {
      document.removeEventListener("mousedown", handleLocDropClose);
    };
  }, []);

  const handleLocDropClose = (event) => {
    if (locDropRef.current && !locDropRef.current.contains(event.target)) {
      setCitySuggShow(false);
    }
  };

  const handleKeyDown = (event) => {
    const value = event.target.value.trim();
    if (event.key === "Enter" && value !== "") {
      window.location.href = `/items?search=${value}`;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleLocPopClose);
    return () => {
      document.removeEventListener("mousedown", handleLocPopClose);
    };
  }, []);

  useEffect(() => {
    initHeader();
  }, []);

  const initHeader = () => {
    const pincodes = locations.map((location) => location.pincode);
    setFilteredlocs(pincodes);
  };

  return (
    <>
      <div
        className="globel-header"
        onMouseEnter={onMouseEnter}
        style={{
          display: "flex",
          alignItems: "center",
          zIndex: "999",
          background: "linear-gradient(to top, #0084ff, #00b7ff )",
          top: top,
          position: "sticky",
          borderBottom: "1px solid #ebebeb",
          padding: "8px 12px",
        }}
      >
        <div
          style={{
            width: "33px",
            height: "33px",
            cursor: "pointer",
          }}
          onClick={() => (window.location.href = "/")}
        >
          <img
            style={{
              width: "33px",
              height: "33px",
              objectFit: "cover",
            }}
            src="./assets/images/pnglogo.png"
            alt="SwiftCity logo"
          />
        </div>
        <div
          className="hd-mylocation"
          style={{
            alignItems: "center",
            padding: "2px 4px",
            cursor: "pointer",
            userSelect: "none",
          }}
          onClick={toggleLocPopup}
        >
          <FmdGoodOutlinedIcon sx={{ fontSize: 22, color: "#fff" }} />
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              color: "white",
            }}
          >
            {`${getCitybyPin(selectedPin)}, ${selectedPin}`}
          </span>
          {!isLocationPop ? (
            <KeyboardArrowDownRoundedIcon
              sx={{
                fontSize: 24,
                color: "#fff",
                marginLeft: "auto",
              }}
            />
          ) : (
            <KeyboardArrowUpRoundedIcon
              sx={{
                fontSize: 24,
                color: "#fff",
                marginLeft: "auto",
              }}
            />
          )}
        </div>
        <div
          className="hm-search-bar"
          style={{
            border: "none",
            backgroundColor: "rgb(250, 250, 250)",
            display: "flex",
            flexDirection: "column",
            padding: "5px",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SearchRoundedIcon
              sx={{ fontSize: 22, color: "#5c5c5c", padding: "2px" }}
            />
            <input
              type="search"
              placeholder="Search products, brands and more..."
              id="listingsearch"
              value={inputValue}
              onChange={handleChange}
              onClick={handleChange}
              onKeyDown={handleKeyDown}
              spellCheck="false"
              autoComplete="off"
              style={{
                border: "none",
                outline: "none",
                padding: "3px",
                fontSize: "1rem",
                width: "100%",
              }}
            />
          </div>
          {inputValue && filteredSuggestions.length > 0 && isSearchDrop > 0 && (
            <div className="suggestions-dropdown" ref={searchSuggRef}>
              {filteredSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggClick(suggestion)}
                >
                  <SearchRoundedIcon sx={{ fontSize: 18, color: "#575757" }} />
                  <span>{suggestion}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="hm-head-actions"
          style={{
            display: "flex",
            gap: "15px",
          }}
        >
          <button
            onClick={() => (window.location.href = "/cart")}
            style={{
              width: "35px",
              height: "35px",
              padding: "0",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <svg
              viewBox="0 0 64 64"
              width="30"
              height="30"
              fill="none"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-cart"
            >
              <circle cx="23" cy="56" r="4"></circle>
              <circle cx="49" cy="56" r="4"></circle>
              <path d="M2 10h8l10 35h32l8-24H50"></path>
            </svg>
            <span className="hm-cart-count">
              {user?.user_cart ? user.user_cart.length : 0}
            </span>
          </button>
          <button
            onClick={() => (window.location.href = "/account")}
            style={{
              width: "35px",
              height: "35px",
              padding: "0",
              backgroundColor: "white",
              border: "none",
              borderRadius: "50%",
            }}
          >
            <img
              style={{
                width: "35px",
                height: "35px",
              }}
              src="./assets/images/app_logo.png"
              alt=""
            />
          </button>
        </div>
      </div>
      {isMobLocSel && (
        <div
          className="hd-mob-pinbar"
          onClick={toggleLocPopup}
          style={{
            alignItems: "center",
            gap: "7px",
            background: "linear-gradient(130deg, #0084ff, #00b7ff )",
            padding: "6px 12px",
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          <FmdGoodOutlinedIcon sx={{ fontSize: 22, color: "#fff" }} />
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "600",
              color: "#fff",
            }}
          >
            {`${getTownCitybyPin(selectedPin)} ${selectedPin}`}
          </span>
          {!isLocationPop ? (
            <KeyboardArrowDownRoundedIcon
              sx={{
                fontSize: 24,
                color: "#fff",
                marginLeft: "auto",
              }}
            />
          ) : (
            <KeyboardArrowUpRoundedIcon
              sx={{
                fontSize: 24,
                color: "#fff",
                marginLeft: "auto",
              }}
            />
          )}
        </div>
      )}
      {isLocationPop && (
        <div className="hd-locpopup-overlay">
          <div className="hd-locpopup-box" ref={locPopRef}>
            <div
              className="hd-locpop-head"
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "15px 15px 0 0",
                backgroundColor: "#efefef",
                padding: "12px",
              }}
            >
              <FmdGoodOutlinedIcon sx={{ fontSize: 30, color: "#000" }} />
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                Choose your delivery location
              </span>
            </div>
            <div
              className="hd-locpop-body"
              style={{
                padding: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "10px",
                }}
              >
                <label
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                  }}
                  htmlFor="hd-locsearch"
                >
                  Pincode
                </label>
                <div style={{ position: "relative" }} ref={locDropRef}>
                  <input
                    type="tel"
                    value={locDropInput}
                    id="hd-locsearch"
                    onChange={handleLocChange}
                    placeholder="Your pincode..."
                    autoComplete="off"
                    maxLength={6}
                    style={{
                      width: "100%",
                      padding: "8px 10px",
                      paddingRight: "30px",
                      boxSizing: "border-box",
                      border: "1px solid #dddddd",
                      marginTop: "1px",
                      fontSize: "1rem",
                      overflow: "hidden",
                    }}
                  />
                  <button
                    onClick={() => {
                      setCitySuggShow(!citySuggShow);
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "0px",
                      cursor: "pointer",
                      transform: "translateY(-50%)",
                      padding: "0",
                      paddingRight: "5px",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    {!citySuggShow ? (
                      <KeyboardArrowDownRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: "#000",
                        }}
                      />
                    ) : (
                      <KeyboardArrowUpRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: "#000",
                        }}
                      />
                    )}
                  </button>
                  {citySuggShow && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        right: 0,
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        zIndex: 1,
                        maxHeight: "25vh",
                        overflowY: "auto",
                      }}
                    >
                      {filteredlocs.map((pin, index) => (
                        <div
                          className="addr-loc-tile"
                          key={index}
                          onClick={() => dropLocClick(pin)}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            borderBottom: "1px solid #eee",
                            fontSize: "0.9rem",
                          }}
                        >
                          {`${getTownCitybyPin(pin)} ${pin} `}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={() => onPinSubmit()}
                style={{
                  padding: "8px 12px",
                  backgroundColor: isValidPin ? "#007bff" : "#bbbbbb",
                  color: isValidPin ? "white" : "#000",
                  fontWeight: "600",
                  fontSize: "1rem",
                  border: "none",
                  width: "100%",
                  borderRadius: "20px",
                  marginTop: "10px",
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      {inputValue && filteredSuggestions.length > 0 && isSearchDrop > 0 && (
        <div className="hd-sugg-overlay"></div>
      )}
    </>
  );
};

export default GlobelHeader;
