import React, { useState, useEffect } from "react";
import GlobelHeader from "../../widgets/header";
import { generateClient } from "aws-amplify/api";
import { getListing } from "../../graphql/queries";
import Toast from "../../widgets/toast";
import * as mutations from "../../graphql/mutations";
import "./review.css";
import { setTitle } from "../../widgets/setTitle";
import Rating from "@mui/material/Rating";
import { uploadData } from "aws-amplify/storage";
import { getUrl } from "aws-amplify/storage";
import { useLocation } from "react-router-dom";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const CreateReview = () => {
  const client = generateClient();
  const location = useLocation();
  const linkedListingID = new URLSearchParams(location.search).get("slid");
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewed, setIsReviewed] = useState(false);
  const [reviewImages, setReviewImages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [rateValue, setRateValue] = useState(0);
  const [listingImg, setlistingImg] = useState("");
  const [litingTitle, setLitingTitle] = useState("");
  const [listingRating, setListingRating] = useState(0);
  const [inputTitle, setInputTitle] = useState("");
  const [inputDesc, setInputDesc] = useState("");
  const [variationId, setVariationId] = useState("");

  const [toastText, setToast] = useState(
    "Maximum of 4 product images allowed!"
  );

  const getMyListing = async () => {
    setTitle("Create review");
    try {
      const result = await client.graphql({
        query: getListing,
        variables: { id: linkedListingID },
      });
      const url = await fetchImageUrl(result.data.getListing.p_image_paths[0]);
      setlistingImg(url);
      setTitle(`Create review: ${result.data.getListing.p_title}`);
      setLitingTitle(result.data.getListing.p_title);
      setListingRating(result.data.getListing.p_rating);
      setVariationId(result.data.getListing.p_variation_id);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Listing", error);
    }
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 4 - reviewImages.length);
    if (files.length < e.target.files.length) {
      setToast("Maximum of 10 product variations allowed!");
      setShowToast(true);
    }
    setReviewImages([...reviewImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    const newImages = [...reviewImages];
    newImages.splice(index, 1);
    setReviewImages(newImages);
  };

  const newRatingAddition = async (imgPaths) => {
    const awsCurrentDateTime = new Date().toISOString();
    const brDesc = inputDesc.replaceAll("\n", "<br>");
    await client
      .graphql({
        query: mutations.createReview,
        variables: {
          input: {
            approved: true,
            images: imgPaths,
            listing_id: linkedListingID,
            is_review: inputDesc.length < 30 ? false : true,
            variation_id: variationId,
            rating: rateValue,
            review_title: inputTitle,
            review_desc: brDesc,
            review_time: awsCurrentDateTime,
            user_id: "user001",
            user_img: "userImg.png",
            user_name: "Demo user",
            likes_count: 1,
            dislike_count: 0,
            reports_count: 0,
            type: "Review",
          },
        },
      })
      .then(() => {
        setIsReviewed(true);
        setIsLoading(false);
        setToast("Review submitted successfully.");
        setShowToast(true);
      })
      .catch((e) => {
        console.error(`error submitting review: ${e}`);
      });
  };

  const uploadReview = async () => {
    if (rateValue === 0) {
      setToast("Please select rating first!");
      setShowToast(true);
      return;
    }
    setIsLoading(true);
    let listPaths = [];
    const uploadPromises = reviewImages.map(async (imgFile) => {
      const imgPath = await uploadFileToStorage(imgFile, imgFile.name);
      listPaths.push(imgPath);
    });
    await Promise.all(uploadPromises);
    newRatingAddition(listPaths);
  };

  const uploadFileToStorage = async (file, fileName) => {
    try {
      const result = await uploadData({
        path: `public/reviews/${variationId}/${fileName}`,
        data: file,
      }).result;
      return result.path;
    } catch (error) {
      return "";
    }
  };

  useEffect(() => {
    getMyListing();
  }, []);

  return (
    <>
      <GlobelHeader />
      <div
        className="alr-box"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
        >
          Create review
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            marginTop: "25px",
            borderBottom: "1px solid #dddddd",
            paddingBottom: "8px",
          }}
        >
          <div
            style={{
              height: "80px",
              width: "64px",
              backgroundColor: "#efefef",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                height: "80px",
                width: "64px",
                objectFit: "cover",
                borderRadius: "4px",
              }}
              src={listingImg}
              alt="product-view"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3
              style={{
                fontSize: "1rem",
                fontWeight: "500",
              }}
            >
              {litingTitle}
            </h3>
            <div className="item-rating-row-1">
              <Rating
                name="half-rating-read"
                defaultValue={listingRating}
                precision={0.1}
                size="small"
                readOnly
                sx={{
                  color: "#ff0048",
                }}
              />
              <GroupsRoundedIcon
                sx={{ fontSize: 22, color: "#000", margin: "0 5px" }}
              />
              <span className="item-ratings-count">{299}</span>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "15px",
          }}
        >
          <div className="page-headline">How would you rate this item?</div>
          <div className="stars-row">
            <div className="stardiv" onClick={() => setRateValue(1)}>
              <StarSharpIcon
                sx={{
                  fontSize: 35,
                  color: rateValue > 0 ? "#ff0048" : "#929292",
                }}
              />
              <span
                className="starTxt"
                style={{
                  color: rateValue > 0 ? "#ff0048" : "#929292",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Poor
              </span>
            </div>
            <div className="stardiv" onClick={() => setRateValue(2)}>
              <StarSharpIcon
                sx={{
                  fontSize: 35,
                  color: rateValue > 1 ? "#ff0048" : "#929292",
                }}
              />
              <span
                style={{
                  color: rateValue > 1 ? "#ff0048" : "#929292",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
                className="starTxt"
              >
                Average
              </span>
            </div>
            <div className="stardiv" onClick={() => setRateValue(3)}>
              <StarSharpIcon
                sx={{
                  fontSize: 35,
                  color: rateValue > 2 ? "#ff0048" : "#929292",
                }}
              />
              <span
                style={{
                  color: rateValue > 2 ? "#ff0048" : "#929292",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
                className="starTxt"
              >
                Good
              </span>
            </div>
            <div className="stardiv" onClick={() => setRateValue(4)}>
              <StarSharpIcon
                sx={{
                  fontSize: 35,
                  color: rateValue > 3 ? "#ff0048" : "#929292",
                }}
              />
              <span
                style={{
                  color: rateValue > 3 ? "#ff0048" : "#929292",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
                className="starTxt"
              >
                Very Good
              </span>
            </div>
            <div className="stardiv" onClick={() => setRateValue(5)}>
              <StarSharpIcon
                sx={{
                  fontSize: 35,
                  color: rateValue > 4 ? "#ff0048" : "#929292",
                }}
              />
              <span
                style={{
                  color: rateValue > 4 ? "#ff0048" : "#929292",
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
                className="starTxt"
              >
                Excellent
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            htmlFor="alr-title"
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
              marginBottom: "2px",
            }}
          >
            Review title
          </label>
          <input
            type="text"
            id="alr-title"
            value={inputTitle}
            onChange={(e) => setInputTitle(e.target.value)}
            style={{
              marginBottom: "10px",
              padding: "8px",
            }}
            maxLength={60}
          />
          <label
            htmlFor="alr-desc"
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
              marginBottom: "2px",
            }}
          >
            Review descreption
          </label>
          <textarea
            value={inputDesc}
            onChange={(e) => setInputDesc(e.target.value)}
            id="alr-desc"
            rows="10"
            maxLength={500}
          ></textarea>
          <input
            type="file"
            id="alr-fileInput"
            style={{
              display: "none",
            }}
            accept="image/*"
            onChange={handleImageChange}
            multiple
          ></input>
          <div
            className="alr-image-container"
            style={{
              margin: "15px 0",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {reviewImages.map((image, index) => (
              <div className="var-image-item" key={index}>
                <img src={URL.createObjectURL(image)} alt={`Item ${index}`} />
                <button
                  className="var-img-remove-btn"
                  onClick={() => handleRemoveImage(index)}
                >
                  <CancelRoundedIcon sx={{ fontSize: 15, color: "#ffffff" }} />
                </button>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {reviewImages.length < 4 && (
              <label htmlFor="alr-fileInput" className="alr-add-img">
                Add images
              </label>
            )}
            <button className="alr-review-submit" onClick={uploadReview}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
      <Toast
        message={toastText}
        showToast={showToast}
        setShowToast={setShowToast}
      />
    </>
  );
};

export default CreateReview;
