import React from "react";
import { UserProvider } from "./userContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./views/home/home";
import SignIn from "./views/auth/signin";
import "./App.css";
import Categories from "./views/categories/categories";
import Items from "./views/categories/items";
import Listing from "./views/categories/listing";
import ProductReview from "./views/categories/product-reviews";
import CreateReview from "./views/categories/create-review";
import Cart from "./views/checkout/cart";
import CheckOut from "./views/checkout/checkout";
import AddAddress from "./views/profile/add-address";
import MyAccount from "./views/profile/account";
import MyAddresses from "./views/profile/my-addresses";
import EditProfile from "./views/profile/edit-profile";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/items" element={<Items />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/product-reviews" element={<ProductReview />} />
          <Route path="create-review" element={<CreateReview />} />
          <Route path="cart" element={<Cart />} />
          <Route path="checkout" element={<CheckOut />} />
          <Route path="add-address" element={<AddAddress />} />
          <Route path="account" element={<MyAccount />} />
          <Route path="my-addresses" element={<MyAddresses />} />
          <Route path="edit-profile" element={<EditProfile />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;

// AKIA6GBMGPBKXALXP6ZU
// c1jTw55rbmNpU17m8L7H+iQG/ZDisaRHTGXW5Goy  46
