import React, { useState, useRef, useContext, useEffect } from "react";
import "./checkout.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import CatDrop from "../../widgets/categories";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import EditLocationAltRoundedIcon from "@mui/icons-material/EditLocationAltRounded";
import GlobelFooter from "../../widgets/footer";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import { getListing } from "../../graphql/queries";
import { getUrl } from "aws-amplify/storage";
import Snackbar from "@mui/material/Snackbar";
import { Fade } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { updateUser } from "../../graphql/mutations";

const CheckOut = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { client, user, loading, fetchUserData } = useContext(UserContext);
  setTitle("Checkout - SwiftCity");
  const [currentStep, setCurrentStep] = useState(0);
  const [addressInd, setAddressInd] = useState(0);
  const [isViewAll, setIsViewAll] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderImgs, setOrderImgs] = useState([]);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [totalOrderItems, setTotalOrderItems] = useState(0);
  const [orderTotalQtys, setOrderTotalQtys] = useState(0);
  const [orderTotalMrp, setOrderTotalMrp] = useState(0);
  const [orderTotalDiscount, setOrderTotalDiscount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [toastText, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [orderPlaced, setOrderPlaced] = useState(false);

  const formatIndianNumber = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const handleCheckoutBtnClick = () => {
    if (currentStep === 0) {
      setCurrentStep(1);
    } else if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setOrderPlaced(true);
    }
  };

  const handleStepClick = (index) => {
    if (index < currentStep) {
      setCurrentStep(index);
    }
  };

  const getOffPers = (mrp, price) => {
    const off = ((mrp - price) / mrp) * 100;
    if (off) {
      return parseInt(off);
    } else {
      return 0;
    }
  };

  const handleSnackClose = () => {
    setShowToast(false);
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  const getOrderDraft = async () => {
    setIsLoading(true);
    try {
      let itemsList = await Promise.all(
        user.order_draft.map(async (orderItem) => {
          const listing = await getSingleListing(orderItem.listing_id);
          return listing;
        })
      );
      const imgUrls = await Promise.all(
        itemsList.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      let orderMrp = 0;
      let orderPrice = 0;
      let orderQty = 0;
      let orderWeight = 0;
      let orderCharges = 0;
      itemsList.forEach((item, index) => {
        const mesInd = getCartMeasureInd(
          item.p_measurements,
          user.order_draft[index].selected_measurement
        );
        if (item.p_measurements[mesInd].p_qty > 5) {
          orderMrp += item.p_measurements[mesInd].p_mrp;
          orderPrice += item.p_measurements[mesInd].p_price;
          orderQty += user.order_draft[index].quantity;
          orderWeight += item.p_weight;
          if (item.p_weight < 500) {
            orderCharges = 49;
          } else if (item.p_weight > 500 && item.p_weight < 1000) {
            orderCharges = 79;
          } else if (item.p_weight > 1000 && item.p_weight < 2000) {
            orderCharges = 99;
          } else if (item.p_weight > 2000) {
            orderCharges = 199;
          }
          itemsList[index].is_gift = user.order_draft[index].is_gift;
        } else {
          itemsList.splice(index, 1);
        }
      });
      setOrderList(itemsList);
      setOrderImgs(imgUrls);
      setOrderSubtotal(orderPrice);
      setTotalOrderItems(itemsList.length);
      setOrderTotalQtys(orderQty);
      setOrderTotalMrp(orderMrp);
      setOrderTotalDiscount(getOffPers(orderMrp, orderPrice));
      setDeliveryCharges(orderCharges);
    } catch (error) {
      console.error("Error fetching order items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const minItemQty = async (qty, ind) => {
    setIsLoading(true);
    if (qty > 1) {
      let userDraftOrder = user.order_draft;
      let normalizedData = userDraftOrder.map((doc) => ({
        create_time: doc.create_time,
        listing_id: doc.listing_id,
        variation_type: doc.variation_type,
        variation_title: doc.variation_title,
        measurement_type: doc.measurement_type,
        selected_measurement: doc.selected_measurement,
        quantity: doc.quantity,
        is_gift: doc.is_gift,
        gift_message: doc.gift_message,
        gift_type: doc.gift_type,
        covering: doc.covering,
        deliver_gift_on: doc.deliver_gift_on,
        gift_sender: doc.gift_sender,
      }));
      normalizedData[ind].quantity = qty - 1;
      try {
        await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
              order_draft: normalizedData,
            },
          },
        });
        fetchUserData();
      } catch (error) {
        console.error(
          "Error updating cart:",
          error.response?.errors || error.message || error
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setToast("At least 1 unit is required.");
      setShowToast(true);
    }
  };

  const plusItemQty = async (qty, ind) => {
    setIsLoading(true);
    if (qty < 10) {
      let userDraftOrder = user.order_draft;
      let normalizedData = userDraftOrder.map((doc) => ({
        create_time: doc.create_time,
        listing_id: doc.listing_id,
        variation_type: doc.variation_type,
        variation_title: doc.variation_title,
        measurement_type: doc.measurement_type,
        selected_measurement: doc.selected_measurement,
        quantity: doc.quantity,
        is_gift: doc.is_gift,
        gift_message: doc.gift_message,
        gift_type: doc.gift_type,
        covering: doc.covering,
        deliver_gift_on: doc.deliver_gift_on,
        gift_sender: doc.gift_sender,
      }));
      normalizedData[ind].quantity = qty + 1;
      try {
        await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
              order_draft: normalizedData,
            },
          },
        });
        fetchUserData();
      } catch (error) {
        console.error(
          "Error updating cart:",
          error.response?.errors || error.message || error
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setToast("Maximum of 10 units allowed per item.");
      setShowToast(true);
    }
  };

  const getCartMeasureInd = (mesList, selMes) => {
    let firstInd = mesList.findIndex((item) => item.p_measurement === selMes);
    if (firstInd === -1) {
      firstInd = 0;
    }
    return firstInd;
  };

  const getSingleListing = async (id) => {
    try {
      const result = await client.graphql({
        query: getListing,
        variables: { id: id },
      });
      return result.data.getListing;
    } catch (error) {
      console.error("Error fetching single items:", error);
    }
  };

  useEffect(() => {
    if (!loading) {
      getOrderDraft();
    }
  }, [loading]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GlobelHeader isMobLocSel={false} top={0} />
      <CatDrop />
      {!orderPlaced && (
        <div className="chk-container">
          <div className="chk-row-one">
            <div className="chk-progress-bar">
              <div
                className={`chk-step ${
                  currentStep >= 0 ? "chk-completed" : ""
                }`}
              >
                <div
                  className="chk-circle"
                  onClick={() => handleStepClick(0)}
                  style={{
                    cursor: currentStep > 0 ? "pointer" : "default",
                  }}
                >
                  {currentStep > 0 ? (
                    <DoneRoundedIcon
                      sx={{
                        fontSize: 22,
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "1"
                  )}
                </div>
                <p
                  style={{
                    fontWeight: currentStep === 0 ? "600" : "500",
                  }}
                >
                  Address
                </p>
              </div>
              <div
                className={`chk-progress-line ${
                  currentStep >= 1 ? "chk-filled" : ""
                }`}
              ></div>
              <div
                className={`chk-step ${
                  currentStep >= 1 ? "chk-completed" : ""
                }`}
              >
                <div
                  className="chk-circle"
                  onClick={() => handleStepClick(1)}
                  style={{
                    cursor: currentStep > 1 ? "pointer" : "default",
                  }}
                >
                  {currentStep > 1 ? (
                    <DoneRoundedIcon
                      sx={{
                        fontSize: 22,
                        color: "#fff",
                      }}
                    />
                  ) : (
                    "2"
                  )}
                </div>
                <p
                  style={{
                    fontWeight: currentStep === 1 ? "600" : "500",
                  }}
                >
                  Summary
                </p>
              </div>
              <div
                className={`chk-progress-line ${
                  currentStep >= 2 ? "chk-filled" : ""
                }`}
              ></div>
              <div
                className={`chk-step ${
                  currentStep === 2 ? "chk-completed" : ""
                }`}
              >
                <div className="chk-circle">3</div>
                <p
                  style={{
                    fontWeight: currentStep === 2 ? "600" : "500",
                  }}
                >
                  Payment
                </p>
              </div>
            </div>
            {currentStep === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "10px",
                    marginTop: "25px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    1. Select delivery location
                  </span>
                  <button
                    onClick={() =>
                      navigate("/add-address?activity=checking-out", {
                        replace: true,
                      })
                    }
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      backgroundColor: "white",
                      padding: "0",
                      margin: "0",
                      border: "none",
                      textDecoration: "underline",
                      color: "#0084ff",
                    }}
                  >
                    Add new
                  </button>
                </div>
                <div className="chk-addr-list">
                  {user?.user_addresses
                    .slice(0, isViewAll ? user.user_addresses.length : 4)
                    .map((address, ind) => (
                      <div
                        key={ind}
                        className="chk-addr-tile"
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          userSelect: "none",
                          backgroundColor:
                            addressInd === ind ? "#f8be00" : "#e9e9e9",
                          borderColor:
                            addressInd === ind ? "#f8be00" : "#dddddd",
                        }}
                        onClick={() => setAddressInd(ind)}
                      >
                        <div className="chk-radio-row">
                          <input
                            checked={addressInd === ind}
                            onChange={() => setAddressInd(ind)}
                            type="radio"
                            id={`chk-input-${ind * 7}`}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px",
                            width: "100%",
                            backgroundColor: "white",
                            borderRadius: "8px",
                            background:
                              addressInd === ind
                                ? "linear-gradient(rgb(255, 255, 255), rgb(225, 240, 255))"
                                : "white",
                          }}
                        >
                          <div
                            className="chk-nxtbox-head"
                            style={{
                              display: "flex",
                              position: "relative",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "0.9rem",
                                fontWeight: "600",
                              }}
                            >
                              {address.full_name}
                            </span>
                            {addressInd === ind ? (
                              <button
                                className="chk-addr-edit"
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                  borderRadius: "8px",
                                  width: "30px",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid #dddddd",
                                  right: "-6px",
                                  top: "-6px",
                                  height: "30px",
                                }}
                              >
                                <EditLocationAltRoundedIcon
                                  sx={{
                                    fontSize: 18,
                                    color: "#4e4e4e",
                                  }}
                                />
                              </button>
                            ) : (
                              <span></span>
                            )}
                          </div>

                          <span
                            style={{
                              fontSize: "0.9rem",
                            }}
                          >
                            {`${address.flat_house}, ${address.area_street}, ${address.city}, ${address.state}, ${address.pincode}`}
                          </span>
                          <span
                            style={{
                              fontSize: "0.9rem",
                            }}
                          >
                            {address.phone}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                {user?.user_addresses.length > 4 && !isViewAll && (
                  <button
                    onClick={() => setIsViewAll(true)}
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: "600",
                      backgroundColor: "white",
                      padding: "0",
                      margin: "0 5px 20px auto",
                      border: "none",
                      textDecoration: "underline",
                      color: "#0084ff",
                    }}
                  >
                    View all
                  </button>
                )}
              </>
            )}
            {currentStep === 1 && (
              <>
                <div
                  style={{
                    marginTop: "25px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    2. Order summary
                  </span>
                </div>
                <div
                  className="crt-list-items"
                  style={{
                    marginTop: "15px",
                  }}
                >
                  {orderList.map((orderItm, orderIndx) => (
                    <div key={orderIndx} className="crt-item-tile">
                      <div className="crt-img-box">
                        <img
                          className="crt-item-img"
                          src={orderImgs[orderIndx]}
                          alt="Order view"
                        />
                      </div>
                      <div className="crt-tile-sub-box">
                        <div className="cart-item-title">
                          {orderItm.p_title}
                        </div>
                        <div
                          className="it-item-prov"
                          style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "8px",
                          }}
                        >
                          <div
                            className="it-em-rp"
                            style={{
                              display: "flex",
                            }}
                          >
                            <span
                              className="it-rupee-symbol"
                              style={{
                                fontSize: "0.8rem",
                              }}
                            >
                              ₹
                            </span>
                            <span
                              className="it-itprice"
                              style={{
                                fontSize: "1.4rem",
                              }}
                            >
                              {formatIndianNumber(
                                orderItm.p_measurements[
                                  getCartMeasureInd(
                                    orderItm.p_measurements,
                                    user.order_draft[orderIndx]
                                      .selected_measurement
                                  )
                                ].p_price
                              )}
                            </span>
                          </div>
                          <span
                            className="it-itmrp"
                            style={{
                              fontWeight: "500",
                              fontSize: "1rem",
                              textDecoration: "line-through",
                              color: "#626262",
                            }}
                          >
                            ₹
                            {formatIndianNumber(
                              orderItm.p_measurements[
                                getCartMeasureInd(
                                  orderItm.p_measurements,
                                  user.order_draft[orderIndx]
                                    .selected_measurement
                                )
                              ].p_mrp
                            )}
                          </span>
                          <span
                            className="it-off"
                            style={{
                              fontWeight: "600",
                              fontSize: "1rem",
                              color: "#0084ff",
                            }}
                          >
                            {`${getOffPers(
                              orderItm.p_measurements[
                                getCartMeasureInd(
                                  orderItm.p_measurements,
                                  user.order_draft[orderIndx]
                                    .selected_measurement
                                )
                              ].p_mrp,
                              orderItm.p_measurements[
                                getCartMeasureInd(
                                  orderItm.p_measurements,
                                  user.order_draft[orderIndx]
                                    .selected_measurement
                                )
                              ].p_price
                            )}% off`}{" "}
                          </span>
                        </div>
                        {orderItm.p_measurements[
                          getCartMeasureInd(
                            orderItm.p_measurements,
                            user.order_draft[orderIndx].selected_measurement
                          )
                        ].p_qty < 20 && (
                          <span className="stock-label">{`Only ${
                            orderItm.p_measurements[
                              getCartMeasureInd(
                                orderItm.p_measurements,
                                user.order_draft[orderIndx].selected_measurement
                              )
                            ].p_qty
                          } left in stock`}</span>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            userSelect: "none",
                          }}
                        >
                          <input
                            type="checkbox"
                            value={orderItm.is_gift}
                            onChange={() =>
                              setOrderList((preItems) => {
                                const newItems = [...preItems];
                                newItems[orderIndx].is_gift =
                                  !newItems[orderIndx].is_gift;
                                return newItems;
                              })
                            }
                            id={`gift${orderIndx * 2.5}`}
                          />
                          <label
                            htmlFor={`gift${orderIndx * 2.5}`}
                            style={{
                              fontSize: "0.8rem",
                              cursor: "pointer",
                              color: "#0084ff",
                            }}
                          >
                            send as a gift
                          </label>
                        </div>
                        {user.order_draft[orderIndx].variation_type.trim() !==
                          "" && (
                          <div className="selections">
                            <span className="col-ti">
                              {`${user.order_draft[orderIndx].variation_type}: `}
                            </span>
                            <span className="sel-col">
                              {user.order_draft[orderIndx].variation_title}
                            </span>
                          </div>
                        )}
                        {user.order_draft[orderIndx].measurement_type !==
                          "none" && (
                          <div className="selections">
                            <span className="siz-ti">
                              {`${user.order_draft[orderIndx].measurement_type}: `}
                            </span>
                            <span className="sel-siz">
                              {user.order_draft[orderIndx].selected_measurement}
                            </span>
                          </div>
                        )}
                        <div className="item-btn-row">
                          <div className="qty-btn">
                            <button
                              className="qty-dec"
                              onClick={() =>
                                minItemQty(
                                  user.order_draft[orderIndx].quantity,
                                  orderIndx
                                )
                              }
                            >
                              -
                            </button>
                            <span className="item-qty">
                              {user.order_draft[orderIndx].quantity}
                            </span>
                            <button
                              onClick={() =>
                                plusItemQty(
                                  user.order_draft[orderIndx].quantity,
                                  orderIndx
                                )
                              }
                              className="qty-inc"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <div className="chk-payment-container">
                <div
                  style={{
                    marginTop: "25px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    3. Make payment
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div
                    className="chk-payment-type-tile"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      borderRadius: "8px",
                      userSelect: "none",
                      backgroundColor:
                        paymentMethod === "online" ? "#f8be00" : "#e9e9e9",
                      borderColor:
                        paymentMethod === "online" ? "#f8be00" : "#dddddd",
                    }}
                    onClick={() => setPaymentMethod("online")}
                  >
                    <div className="chk-radio-row">
                      <input
                        checked={paymentMethod === "online"}
                        type="radio"
                        id="chk-online-payment-input"
                        onChange={() => setPaymentMethod("online")}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                        width: "100%",
                        border: "2px solid",
                        borderColor:
                          paymentMethod === "online" ? "#f8be00" : "#e9e9e9",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        background:
                          paymentMethod === "online"
                            ? "linear-gradient(rgb(255, 255, 255), rgb(225, 240, 255))"
                            : "white",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        Pay online
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          Credit card, debit card, Net banking, UPI apps, wallet
                        </span>
                        <img
                          src="./payment_methods.jpg"
                          style={{
                            width: "100px",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="chk-payment-type-tile"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "8px",
                      marginBottom: "20px",
                      userSelect: "none",
                      backgroundColor:
                        paymentMethod === "cash" ? "#f8be00" : "#e9e9e9",
                      borderColor:
                        paymentMethod === "cash" ? "#f8be00" : "#dddddd",
                    }}
                    onClick={() => setPaymentMethod("cash")}
                  >
                    <div className="chk-radio-row">
                      <input
                        checked={paymentMethod === "cash"}
                        type="radio"
                        id="chk-cash-payment-input"
                        onChange={() => setPaymentMethod("cash")}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: "10px",
                        border: "2px solid",
                        borderColor:
                          paymentMethod === "cash" ? "#f8be00" : "#e9e9e9",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        background:
                          paymentMethod === "cash"
                            ? "linear-gradient(rgb(255, 255, 255), rgb(225, 240, 255))"
                            : "white",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        Pay upon delivery
                      </div>
                      <div
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        Pay upon delivery with cash, UPI, Netbanking etc.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="crt-aggregation-container">
            <div
              style={{
                padding: " 12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                }}
              >
                Subtotal:
              </span>
              <span
                className="crt-aggri-total"
                style={{
                  fontWeight: "600",
                  color: "rgb(186, 115, 0)",
                }}
              >
                {`₹${formatIndianNumber(orderSubtotal)}`}
              </span>
              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <span className="crt-aggri-txt">Total items:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {totalOrderItems}
                </span>
              </div>
              <div>
                <span className="crt-aggri-txt">Total items in quantity:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {orderTotalQtys}
                </span>
              </div>
              <div>
                <span className="crt-aggri-txt">Total price of items:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {`₹${formatIndianNumber(orderTotalMrp)}`}
                </span>
              </div>
              <div>
                <span className="crt-aggri-txt">Discount:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {`-₹${formatIndianNumber(
                    orderTotalMrp - orderSubtotal
                  )} (${orderTotalDiscount}%)`}{" "}
                </span>
              </div>
              {/* <div>
              <span className="crt-aggri-txt">GST:</span>{" "}
              <span
                className="crt-aggri-txt"
                style={{
                  fontWeight: "600",
                }}
              >
                ₹2,999 (18%)
              </span>
            </div> */}
              <div>
                <span className="crt-aggri-txt">Deliver charges:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {`₹${deliveryCharges}`}
                </span>
              </div>
              <button
                onClick={handleCheckoutBtnClick}
                className="crt-proceed"
                style={{
                  padding: "8px 12px",
                  backgroundColor: "#f8be00",
                  fontWeight: "600",
                  border: "none",
                  margin: "15px 0",
                  borderRadius: "2px",
                  fontSize: "0.9rem",
                }}
              >
                {currentStep === 1
                  ? "Select payment method"
                  : currentStep === 2
                  ? "Confirm order"
                  : "Continue with selected"}
              </button>
            </div>
          </div>
        </div>
      )}
      {orderPlaced && (
        <div className="chk-order-placed">
          <div className="chk-done-box">
            <CheckRoundedIcon
              sx={{
                width: "0",
                height: "0",
                color: "#fff",
                transform: "rotate(-45deg)",
                animation: "rotateAndGrow 1.5s forwards",
              }}
            />
          </div>
          <span className="chk-order-done-txt">Order placed</span>
          <div className="chk-order-done-action">
            <button
              style={{
                border: "1px solid #a3a3a3",
                color: "rgb(54, 54, 54)",
              }}
              className="chk-odact"
            >
              View Orders
            </button>
            <button
              onClick={() => setOrderPlaced(!orderPlaced)}
              style={{
                border: "1px solid #a3a3a3",
                color: "rgb(54, 54, 54)",
              }}
              className="chk-odact"
            >
              Continue Shopping
            </button>
          </div>
        </div>
      )}
      <GlobelFooter />
      <Snackbar
        open={showToast}
        onClose={handleSnackClose}
        TransitionComponent={Fade}
        message={toastText}
        key={Fade.name}
        autoHideDuration={3000}
      />
      {(isLoading || loading) && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default CheckOut;
