import React, { useState, useRef, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

const CatDrop = () => {
  const catDrawerRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerPosition, setDrawerPosition] = useState({ top: 0 });

  const handleMouseEnterCategory = (event, category) => {
    const rect = event.target.getBoundingClientRect();
    setDrawerPosition({ top: rect.bottom + window.scrollY });
    setSelectedCategory(category);
    setIsDrawerOpen(true);
  };

  const handleMouseLeaveDrawer = () => {
    setSelectedCategory(null);
    setIsDrawerOpen(false);
  };

  const handleClickOutsideCategory = (event) => {
    if (catDrawerRef.current && !catDrawerRef.current.contains(event.target)) {
      setSelectedCategory(null);
      setIsDrawerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCategory);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCategory);
    };
  }, []);
  return (
    <div
      className="hm-categories"
      ref={catDrawerRef}
      onMouseLeave={handleMouseLeaveDrawer}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-evenly",
          borderBottom: "1px solid #ebebeb",
          borderTop: "1px solid #ebebeb",
          backgroundColor: "white",
        }}
      >
        {categories.map((cats, index) => (
          <button
            key={index}
            className="hm-category-button"
            onMouseEnter={(e) => handleMouseEnterCategory(e, cats)}
            style={{
              color: selectedCategory === cats ? "#0084ff" : "black",
            }}
          >
            {cats.category}
            {selectedCategory === cats ? (
              <KeyboardArrowUpRoundedIcon
                sx={{ fontSize: 22, color: "#0084ff" }}
              />
            ) : (
              <KeyboardArrowDownRoundedIcon
                sx={{ fontSize: 22, color: "#bbbbbb" }}
              />
            )}
          </button>
        ))}
      </div>
      {isDrawerOpen && selectedCategory && (
        <div
          style={{
            position: "absolute",
            top: drawerPosition.top,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            border: "1px solid #dddddd",
            padding: "20px",
            zIndex: 499,
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
          }}
        >
          {selectedCategory.subcategories.map((sub, subIndex) => (
            <div
              key={subIndex}
              style={{
                flex: "1",
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  fontWeight: "600",
                  color: "#363636",
                }}
              >
                {sub.name}
              </div>
              {sub.options.map((option, optionIndex) => (
                <div
                  className="hm-subcategory-items"
                  key={optionIndex}
                  onClick={() =>
                    (window.location.href = `/items?category=${selectedCategory.category}/${sub.name}/${option}`)
                  }
                >
                  {option}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const categories = [
  {
    category: "Men",
    subcategories: [
      {
        name: "Top wear",
        options: ["T-Shirts", "Shirts", "Suits, Blazers"],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Bottom wear",
        options: [
          "Jeans",
          "Trousers",
          "Track pants",
          "Formal pants",
          "Shorts",
          "Cargos",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Footwear",
        options: [
          "Sports shoes",
          "Casual shoes",
          "Formal shoes",
          "Sandals & Floaters",
          "Flip-Flops",
          "Boots",
          "Sneakers",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Grooming",
        options: [
          "Deodorants",
          "Perfumes",
          "Beard special",
          "Grooming kits",
          "Trimmers",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Winter wear",
        options: ["Sweatshirts", "Jackets", "Sweater", "Tracksuits"],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Ethnic wear",
        options: ["Kurta", "Ethnic sets", "Sherwanis", "Dhoti/Lungi"],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Innerwear",
        options: ["Trunks", "Vests", "Boxers", "Night Suits", "Pyjamas"],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Accessories",
        options: [
          "Backpacks",
          "Wallets",
          "Watches",
          "Belts",
          "Sunglasses",
          "Travel accessories",
          "Jewellwery",
        ],
        subcat_image: "",
        option_images: [],
      },
    ],
    category_image: "",
  },
  {
    category: "Women",
    category_image: "",
    subcategories: [
      {
        name: "Fashion",
        options: [
          "Top wear",
          "Dresses",
          "Jeans",
          "Shorts",
          "Skirts",
          "Trousers",
          "Sports wear",
          "Winter wear",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Innerwear",
        options: ["Bras", "Panties", "Lingerie sets", "Night wear"],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Ethnic wear",
        options: [
          "Sarees",
          "Kurtas",
          "Lehenga choli",
          "Salwar suits",
          "Leggings",
          "Palazzos",
          "Ethnic trousers",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Footwear",
        options: [
          "Flats",
          "Sandals",
          "Heels",
          "Sports shoes",
          "Casual shoes",
          "Slippers & Flip-Flops",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Beauty & Personal care",
        options: [
          "Make up",
          "Skin care",
          "Hair care",
          "Deodorants & Perfumes",
          "Appliances",
          "Bath & Spa",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Accessories",
        options: [
          "Jewellery",
          "Handbags",
          "Wallets & Belts",
          "Travel accessories",
          "Sunglasses",
          "Watches",
        ],
        subcat_image: "",
        option_images: [],
      },
    ],
  },
  {
    category: "Home & Kitchen",
    category_image: "",
    subcategories: [
      {
        name: "Furnishing",
        options: [
          "Bedsheets",
          "Curtains",
          "Bath Towels",
          "Blankets",
          "Cushions & Pillows",
          "Floor coverings",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Kitchen",
        options: [
          "Pans",
          "Tawas",
          "Pressure cookers",
          "Kitchen tools",
          "Mugs",
          "Dinner set",
          "Lunch Boxes",
          "Water bottles",
          "Containers",
          "Flasks",
          "Cleaning supplies",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Decoration",
        options: [
          "Paintings",
          "Clocks",
          "Showpieces & Figurines",
          "Lights & Lamps",
          "Festive decor",
          "Gardening",
        ],
        subcat_image: "",
        option_images: [],
      },
    ],
  },
  {
    category: "Sports, Study & More",
    category_image: "",
    subcategories: [
      {
        name: "Sports & Fitness",
        options: [
          "Sports wear",
          "Sports shoes",
          "Fitness accessories",
          "Sports equepments",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Books",
        options: [
          "Entrance",
          "Academics",
          "Literature & Fiction",
          "Non fiction",
          "Self-help",
        ],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Stationery",
        options: ["Pens", "Diaries", "Folders", "Desk organizers"],
        subcat_image: "",
        option_images: [],
      },
      {
        name: "Auto & Instruments",
        options: [
          "Riding gears",
          "Car accessories",
          "Science lab",
          "Packaging",
          "Music instruments",
          "Gaming accessories",
        ],
        subcat_image: "",
        option_images: [],
      },
    ],
  },
];

export default CatDrop;
