import React, { createContext, useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { getUser } from "./graphql/queries";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const client = generateClient();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    setLoading(true);

    try {
      const result = await client.graphql({
        query: getUser,
        variables: { id: "73c547aa-82f0-4594-9a5a-4d589b7079d7" },
      });
      const userData = result.data.getUser;
      setUser(userData);
      console.log(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={{ client, user, loading, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};
