import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import { initializeApp } from "firebase/app";
import config from "./amplifyconfiguration.json";

Amplify.configure(config);
initializeApp({
  apiKey: "AIzaSyCEl9MXyrjP55ebzPveeEAaI7l9D9hqlcY",
  authDomain: "swiftcenter-3121e.firebaseapp.com",
  projectId: "swiftcenter-3121e",
  storageBucket: "swiftcenter-3121e.firebasestorage.app",
  messagingSenderId: "280008242444",
  appId: "1:280008242444:web:e3532e293d8249a7ef4529",
});
const root = ReactDOM.createRoot(document.getElementById("container"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// firebase deploy --only hosting:swiftcity-india
