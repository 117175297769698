import React, { useState, useRef, useEffect, useContext } from "react";
import "../checkout/checkout.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import GlobelFooter from "../../widgets/footer";
import { UserContext } from "../../userContext";
import { updateUser } from "../../graphql/mutations";
import { useNavigate, useLocation } from "react-router-dom";

const AddAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { client, user, loading, fetchUserData } = useContext(UserContext);
  const locDropRef = useRef(null);
  const screenType = new URLSearchParams(location.search).get("activity");
  const stateDropRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChekingOut, setIsChekingOut] = useState(false);
  const [cityInput, setCityInput] = useState("");
  const [landmarkInput, setLandmarkInput] = useState("");
  const [roadInput, setRoadInput] = useState("");
  const [houseInput, setHouseInput] = useState("");
  const [numberInput, setNumberInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [filteredlocs, setFilteredlocs] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [citySuggShow, setCitySuggShow] = useState(false);
  const [stateSuggShow, setStateSuggShow] = useState(false);
  const [stateInput, setStateInput] = useState("");
  const [locDropInput, setLocDropInput] = useState("");
  const [isValidPin, setIsValidPin] = useState(false);
  const [addressType, setaAdressType] = useState("Home");
  setTitle("Add new address - SwiftCity");

  const updateMyAddresses = async () => {
    setIsLoading(true);
    const date = new Date();
    const awsDateTime = date.toISOString();
    let normalizedData = user.user_addresses.map((doc) => ({
      geo_point: doc.geo_point,
      flat_house: doc.flat_house,
      area_street: doc.area_street,
      landmark: doc.landmark,
      pincode: doc.pincode,
      town: doc.town,
      city: doc.city,
      state: doc.state,
      region: doc.region,
      country: doc.country,
      adding_time: doc.adding_time,
      additional_info: doc.additional_info,
      phone: doc.phone,
      additional_phone: doc.additional_phone,
      first_name: doc.first_name,
      last_name: doc.last_name,
      short_name: doc.short_name,
      full_name: doc.full_name,
      address_type: doc.address_type,
      delivery_days: doc.delivery_days,
      delivery_timing: doc.delivery_timing,
      is_default_address: doc.is_default_address,
    }));
    normalizedData.push({
      geo_point: "none",
      flat_house: houseInput.trim(),
      area_street: roadInput.trim(),
      landmark: landmarkInput.trim(),
      pincode: parseInt(locDropInput),
      town: "",
      city: landmarkInput.trim(),
      state: landmarkInput.trim(),
      region: "North",
      country: "India",
      adding_time: awsDateTime,
      additional_info: "",
      phone: `+91${numberInput}`,
      additional_phone: "",
      first_name: "",
      last_name: "",
      short_name: "",
      full_name: nameInput.trim(),
      address_type: addressType,
      delivery_days: [],
      delivery_timing: "",
      is_default_address: false,
    });
    try {
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
            user_addresses: normalizedData,
          },
        },
      });
      fetchUserData();
      if (isChekingOut) {
        navigate("/checkout", { replace: true });
      } else {
        navigate("/my-addresses", { replace: true });
      }
    } catch (error) {
      console.error(
        "Error updating addresses:",
        error.response?.errors || error.message || error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value.trim();
    if (/^\d*$/.test(value)) {
      setNumberInput(value);
    }
  };

  const handleLocChange = (event) => {
    const value = event.target.value.trim();
    if (/^\d*$/.test(value)) {
      if (value !== "") {
        setLocDropInput(value);
        const filteredResults = locations
          .filter((loc) => loc.pincode.includes(value))
          .map((loc) => `${loc.pincode}`);
        setFilteredlocs(filteredResults);
        setCitySuggShow(filteredResults.length > 0);
        checkIsEnteredPin(value);
      } else {
        setLocDropInput("");
        const filteredResults = locations.map((location) => location.pincode);
        setFilteredlocs(filteredResults);
        setCitySuggShow(true);
      }
    }
  };

  const handleStateInputChange = (event) => {
    const value = event.target.value.trim();
    if (value !== "") {
      setStateInput(value);
      const filteredResults = locations
        .filter((loc) =>
          loc.state.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        )
        .map((loc) => `${loc.state}`)
        .filter((state, index, self) => self.indexOf(state) === index);
      setFilteredStates(filteredResults);
      setStateSuggShow(filteredResults.length > 0);
    } else {
      setStateInput("");
      const filteredResults = locations.map((location) => location.state);
      setFilteredStates(filteredResults);
      setStateSuggShow(true);
    }
  };

  const dropLocClick = (pincode) => {
    const foundLocation = locations.find(
      (location) => location.pincode === pincode
    );
    setFilteredlocs([pincode]);
    setLocDropInput(pincode);
    checkIsEnteredPin(pincode);
    setCitySuggShow(false);
    if (foundLocation) {
      setStateInput(foundLocation.state);
      setCityInput(foundLocation.city);
    }
  };

  const dropStateTileClick = (state) => {
    setFilteredStates([state]);
    setStateInput(state);
    setStateSuggShow(false);
  };

  const getTownCitybyPin = (pincode) => {
    const location = locations.find((loc) => loc.pincode === pincode);
    if (location) {
      return `${location.town}, ${location.city}`;
    } else {
      return "";
    }
  };

  const checkIsEnteredPin = (value) => {
    const bool = locations.find((loc) => loc.pincode === value);
    setIsValidPin(bool);
  };

  const initAddrLocs = () => {
    const pincodes = locations.map((location) => location.pincode);
    if (screenType && screenType === "checking-out") {
      setIsChekingOut(true);
    }
    const states = locations
      .map((location) => location.state)
      .filter((state, index, self) => self.indexOf(state) === index);
    setFilteredlocs(pincodes);
    setFilteredStates(states);
  };

  useEffect(() => {
    initAddrLocs();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleLocDropClose);
    return () => {
      document.removeEventListener("mousedown", handleLocDropClose);
    };
  }, []);

  const handleLocDropClose = (event) => {
    if (locDropRef.current && !locDropRef.current.contains(event.target)) {
      setCitySuggShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleStateDropClose);
    return () => {
      document.removeEventListener("mousedown", handleStateDropClose);
    };
  }, []);

  const handleStateDropClose = (event) => {
    if (stateDropRef.current && !stateDropRef.current.contains(event.target)) {
      setStateSuggShow(false);
    }
  };

  return (
    <>
      <GlobelHeader isMobLocSel={false} top={0} />
      <div
        className="adr-container"
        style={{
          padding: "20px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontSize: "1.1rem",
            fontWeight: "600",
          }}
        >
          Add new address
        </span>
        <label htmlFor="addr-name-chk">Full name</label>
        <input
          type="text"
          onChange={(e) => setNameInput(e.target.value)}
          value={nameInput}
          maxLength={40}
          id="addr-name-chk"
          style={{
            padding: "8px",
            borderRadius: "4px",
            fontSize: "1rem",
            border: "1px solid #ccc",
            width: "100%",
            outline: "none",
            transition: "border-color 0.3s",
          }}
        />
        <label htmlFor="chk-addr-phone-input">Phone number</label>
        <div className="log-input-row" style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              color: "#656565",
            }}
          >
            +91
          </span>
          <input
            type="tel"
            onChange={(e) => handlePhoneChange(e)}
            value={numberInput}
            maxLength={10}
            id="chk-addr-phone-input"
            placeholder="Phone number"
            onFocus={(e) => (e.target.style.borderColor = "#0084ff")}
            onBlur={(e) => (e.target.style.borderColor = "#ccc")}
            style={{
              padding: "8px 8px 8px 40px",
              borderRadius: "4px",
              fontSize: "1rem",
              border: "1px solid #ccc",
              width: "100%",
              outline: "none",
              transition: "border-color 0.3s",
            }}
          />
        </div>
        <label htmlFor="addr-house-chk">Flat, House no., Building</label>
        <input
          type="text"
          onChange={(e) => setHouseInput(e.target.value)}
          value={houseInput}
          maxLength={60}
          id="addr-house-chk"
          style={{
            padding: "8px",
            borderRadius: "4px",
            fontSize: "1rem",
            border: "1px solid #ccc",
            width: "100%",
            outline: "none",
            transition: "border-color 0.3s",
          }}
        />
        <label htmlFor="addr-road-chk">Road name, Area, Colony</label>
        <input
          type="text"
          maxLength={60}
          onChange={(e) => setRoadInput(e.target.value)}
          value={roadInput}
          id="addr-road-chk"
          style={{
            padding: "8px",
            borderRadius: "4px",
            fontSize: "1rem",
            border: "1px solid #ccc",
            width: "100%",
            outline: "none",
            transition: "border-color 0.3s",
          }}
        />
        <label htmlFor="addr-land-chk">Landmark</label>
        <input
          type="text"
          onChange={(e) => setLandmarkInput(e.target.value)}
          value={landmarkInput}
          id="addr-land-chk"
          maxLength={50}
          placeholder="E.g. near Govt. school"
          style={{
            padding: "8px",
            borderRadius: "4px",
            fontSize: "1rem",
            border: "1px solid #ccc",
            width: "100%",
            outline: "none",
            transition: "border-color 0.3s",
          }}
        />
        <label htmlFor="chk-addr-pin-input">Pincode</label>
        <div style={{ position: "relative" }} ref={locDropRef}>
          <input
            type="text"
            value={locDropInput}
            id="chk-addr-pin-input"
            onChange={(e) => handleLocChange(e)}
            placeholder="Your pincode..."
            autoComplete="off"
            maxLength={6}
            style={{
              width: "100%",
              padding: "8px 10px",
              paddingRight: "30px",
              boxSizing: "border-box",
              border: "1px solid #dddddd",
              marginTop: "1px",
              fontSize: "1rem",
              overflow: "hidden",
            }}
          />
          <button
            onClick={() => setCitySuggShow(!citySuggShow)}
            style={{
              position: "absolute",
              top: "50%",
              right: "0px",
              cursor: "pointer",
              transform: "translateY(-50%)",
              padding: "0",
              paddingRight: "5px",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            {!citySuggShow ? (
              <KeyboardArrowDownRoundedIcon
                sx={{
                  fontSize: 24,
                  color: "#000",
                }}
              />
            ) : (
              <KeyboardArrowUpRoundedIcon
                sx={{
                  fontSize: 24,
                  color: "#000",
                }}
              />
            )}
          </button>
          {citySuggShow && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1,
                maxHeight: "25vh",
                overflowY: "auto",
              }}
            >
              {filteredlocs.map((pin, index) => (
                <div
                  className="addr-loc-tile"
                  key={index}
                  onClick={() => dropLocClick(pin)}
                  style={{
                    padding: "8px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    fontSize: "0.9rem",
                  }}
                >
                  {`${getTownCitybyPin(pin)} ${pin} `}
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div
            style={{
              width: "calc(50% - 5px)",
            }}
          >
            <label htmlFor="addr-city-chk">City</label>
            <input
              type="text"
              onChange={(e) => setCityInput(e.target.value)}
              value={cityInput}
              maxLength={40}
              id="addr-city-chk"
              style={{
                padding: "8px",
                borderRadius: "4px",
                fontSize: "1rem",
                border: "1px solid #ccc",
                width: "100%",
                outline: "none",
                transition: "border-color 0.3s",
              }}
            />
          </div>
          <div
            style={{
              width: "calc(50% - 5px)",
            }}
          >
            <label htmlFor="addr-state-chk">State</label>
            <div style={{ position: "relative" }} ref={stateDropRef}>
              <input
                type="text"
                value={stateInput}
                id="addr-state-chk"
                onChange={(e) => handleStateInputChange(e)}
                placeholder="Your pincode..."
                autoComplete="off"
                maxLength={30}
                style={{
                  width: "100%",
                  padding: "8px 10px",
                  paddingRight: "30px",
                  boxSizing: "border-box",
                  border: "1px solid #dddddd",
                  marginTop: "1px",
                  fontSize: "1rem",
                  overflow: "hidden",
                }}
              />
              <button
                onClick={() => setStateSuggShow(!stateSuggShow)}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "0px",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                  padding: "0",
                  paddingRight: "5px",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                {!stateSuggShow ? (
                  <KeyboardArrowDownRoundedIcon
                    sx={{
                      fontSize: 24,
                      color: "#000",
                    }}
                  />
                ) : (
                  <KeyboardArrowUpRoundedIcon
                    sx={{
                      fontSize: 24,
                      color: "#000",
                    }}
                  />
                )}
              </button>
              {stateSuggShow && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "100%",
                    left: 0,
                    right: 0,
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    zIndex: 1,
                    maxHeight: "25vh",
                    overflowY: "auto",
                  }}
                >
                  {filteredStates.map((state, index) => (
                    <div
                      className="addr-loc-tile"
                      key={index}
                      onClick={() => dropStateTileClick(state)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        borderBottom: "1px solid #eee",
                        fontSize: "0.9rem",
                      }}
                    >
                      {state}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <label htmlFor="addr-city-chk">Address type</label>
        <div
          style={{
            display: "flex",
            padding: "2px",
            borderRadius: "2px",
            border: "2px solid #dddddd",
          }}
        >
          <div
            onClick={() => setaAdressType("Home")}
            style={{
              width: "33%",
              padding: "3px 0",
              fontSize: "0.9rem",
              fontWeight: "600",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: addressType === "Home" ? "green" : "white",
              color: addressType === "Home" ? "white" : "#323232",
            }}
          >
            Home
          </div>
          <div
            onClick={() => setaAdressType("Apartment")}
            style={{
              width: "34%",
              padding: "3px 0",
              fontSize: "0.9rem",
              fontWeight: "600",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: addressType === "Apartment" ? "green" : "white",
              color: addressType === "Apartment" ? "white" : "#323232",
            }}
          >
            Apartment
          </div>
          <div
            onClick={() => setaAdressType("Office")}
            style={{
              width: "33%",
              padding: "3px 0",
              fontSize: "0.9rem",
              fontWeight: "600",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: addressType === "Office" ? "green" : "white",
              color: addressType === "Office" ? "white" : "#323232",
            }}
          >
            Office
          </div>
        </div>
        <button
          onClick={() => updateMyAddresses()}
          style={{
            border: "none",
            color: "#000",
            backgroundColor: "#f8be00",
            padding: "8px 16px",
            borderRadius: "2px",
            fontSize: "0.9rem",
            fontWeight: "600",
            marginLeft: "auto",
            marginTop: "25px",
          }}
        >
          Save address
        </button>
      </div>
      <GlobelFooter />
      {isLoading && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

const locations = [
  {
    city: "Shimla",
    town: "Mashobra",
    pincode: "171007",
    state: "Himachal Pradesh",
    district: "Shimla",
    subdistrict: "Shimla",
    country: "India",
  },
  {
    city: "Shimla",
    town: "Baldeyan",
    pincode: "171006",
    state: "Himachal Pradesh",
    district: "Shimla",
    subdistrict: "Shimla",
    country: "India",
  },
  {
    city: "Manali",
    town: "Old Manali",
    pincode: "175131",
    state: "Himachal Pradesh",
    district: "Kullu",
    subdistrict: "Manali",
    country: "India",
  },
  {
    city: "Dehradun",
    town: "Rajpur",
    pincode: "248009",
    state: "Uttarakhand",
    district: "Dehradun",
    subdistrict: "Dehradun",
    country: "India",
  },
  {
    city: "Jaipur",
    town: "Malviya Nagar",
    pincode: "302017",
    state: "Rajasthan",
    district: "Jaipur",
    subdistrict: "Jaipur",
    country: "India",
  },
  {
    city: "Mumbai",
    town: "Andheri West",
    pincode: "400053",
    state: "Maharashtra",
    district: "Mumbai Suburban",
    subdistrict: "Andheri",
    country: "India",
  },
  {
    city: "Bengaluru",
    town: "Koramangala",
    pincode: "560034",
    state: "Karnataka",
    district: "Bengaluru Urban",
    subdistrict: "Bengaluru South",
    country: "India",
  },
];

export default AddAddress;
