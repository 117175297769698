import React, { useState, useRef, useEffect, useContext } from "react";
import "./items.css";
import { generateClient } from "aws-amplify/api";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import { getUrl } from "aws-amplify/storage";
import { useLocation } from "react-router-dom";
import Rating from "@mui/material/Rating";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import Slider from "@mui/material/Slider";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { searchListings } from "../../graphql/queries";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import Pagination from "@mui/material/Pagination";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CatDrop from "../../widgets/categories";
import GlobelFooter from "../../widgets/footer";
import { UserContext } from "../../userContext";
import BookmarksRoundedIcon from "@mui/icons-material/BookmarksRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";

const Items = () => {
  const location = useLocation();
  const { user, loading } = useContext(UserContext);
  const categoryParam = new URLSearchParams(location.search).get("category");
  const searchParam = new URLSearchParams(location.search).get("search");
  const listPeram = new URLSearchParams(location.search).get("myList");
  const listingCategory = categoryParam
    ? decodeURIComponent(categoryParam)
    : null;
  const listingSearch = searchParam ? decodeURIComponent(searchParam) : null;
  const myListType = listPeram ? decodeURIComponent(listPeram) : null;
  const client = generateClient();
  const [specialChecked, setSpecialChecked] = useState(false);
  const filterDivRef = useRef(null);
  const [priceRange, setPriceRange] = useState([199, 9999]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedPrLimits, setSelectedPrLimits] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const drawerRef = useRef(null);
  const sortDrawerRef = useRef(null);
  const minDistance = 500;
  const [selectedSortInd, setSelectedSortInd] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isTabFilter, setIsTabFilter] = useState(false);
  const [isIndImgLoaded, setIsIndImgLoaded] = useState([]);
  const [isFirstInit, setFirstInit] = useState(true);
  const [filterSelected, setFilterSelected] = useState({
    price: [199, 9999],
    rating: 0,
    offer: "",
    gender: "",
    cod: false,
    special: false,
    swiftdelivery: false,
    deal: "",
  });

  const fetchLimit = 4;

  const brands = [
    "Nike",
    "Adidas",
    "Puma",
    "Reebok",
    "Under Armour",
    "New Balance",
    "Asics",
    "Converse",
    "Vans",
    "Skechers",
  ];

  const sortTypes = [
    "Relevance",
    "Popularity",
    "₹ high-low",
    "₹ low-high",
    "Latest first",
  ];

  const popularBrands = [
    "Nike",
    "Solimo",
    "Adidas",
    "Puma",
    "Reebok",
    "Under Armour",
    "New Balance",
    "Asics",
  ];

  const filterPriceLimits = [299, 599, 999, 1999];
  const [isFromSearch, setIsFromSearch] = useState(false);

  const [selectedColors, setSelectedColors] = useState([]);
  const [listingsImgUrls, setListingImgUrls] = useState([]);

  const [selectedOffPer, setSelectedOffPer] = useState("");
  const [selectedDeal, setSelectedDeal] = useState("");

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const [filterIndex, setFilterIndex] = useState(0);
  const [selectedGender, setSelectedGender] = useState("");
  const [ratingFilter, setRatingFilter] = useState(0);
  const [isSwiftDelivery, setIsSwiftDelivery] = useState(false);
  const [isOnCod, setIsOnCod] = useState(false);

  const colors = [
    "multicolor",
    "red",
    "gray",
    "white",
    "brown",
    "beige",
    "black",
    "pink",
    "orange",
    "yellow",
    "green",
    "blue",
    "lightblue",
    "purple",
    "transparent",
  ];

  const offPercentageText = [
    "10% off or more",
    "25% off or more",
    "50% off or more",
    "70% off or more",
  ];

  const [sortType, setSortType] = useState({});

  const [fetchFilter, setFetchFilter] = useState({
    and: [],
  });

  const matchColor = (inputText) => {
    const lowerCaseInput = inputText.toLowerCase();
    return itemsColorList.some((color) =>
      lowerCaseInput.includes(color.toLowerCase())
    );
  };

  const [networkDate, setNetworkDate] = useState(null);
  const [isSunday, setIsSunday] = useState(false);

  useEffect(() => {
    const fetchNetworkDate = async () => {
      try {
        const response = await fetch("http://worldtimeapi.org/api/ip");
        const data = await response.json();
        const date = new Date(data.datetime);

        setNetworkDate(date);
        setIsSunday(date.getDay() === 0);
      } catch (error) {
        console.error("Error fetching the network date:", error);
      }
    };

    fetchNetworkDate();
  }, []);

  const setPrLimit = (MaxPrice) => {
    if (selectedPrLimits === MaxPrice) {
      setSelectedPrLimits("");
      setPriceRange([199, 9999]);
    } else {
      setSelectedPrLimits(MaxPrice);
      setPriceRange([99, MaxPrice]);
    }
  };

  const handleSorting = async (sortInd) => {
    setSelectedSortInd(sortInd);
    if (sortInd === 1) {
      setSortType({ field: "p_total_sold", direction: "desc" });
    } else if (sortInd === 2) {
      setSortType({ field: "p_default_price", direction: "desc" });
    } else if (sortInd === 3) {
      setSortType({ field: "p_default_price", direction: "asc" });
    } else if (sortInd === 4) {
      setSortType({ field: "p_verification_time", direction: "desc" });
    } else {
      setSortType({});
    }
    setIsSortOpen(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    getFilteredData();
  }, [sortType]);

  useEffect(() => {
    getFilteredData();
  }, [fetchFilter]);

  const getFilteredData = async () => {
    if (isFirstInit) {
      return;
    }
    setLoading(true);
    try {
      const res = await client.graphql({
        query: searchListings,
        variables: {
          filter: fetchFilter,
          sort: sortType,
          limit: fetchLimit,
        },
      });
      var { items: newItems, total: totalCount } = res.data.searchListings;
      const tPag = totalCount / fetchLimit;
      setPagesCount(Math.ceil(tPag));
      setFetchCount(totalCount);
      const imgUrls = await Promise.all(
        newItems.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      const initialBoolList = newItems.map(() => false);
      setIsIndImgLoaded(initialBoolList);
      setListingImgUrls(imgUrls);
      setFilteredItems(newItems);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterApply = () => {
    var nestedList = [];
    if (selectedGender !== "") {
      nestedList.push({
        p_gender: { eq: selectedGender },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        gender: selectedGender,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        gender: "",
      }));
    }
    if (specialChecked) {
      nestedList.push({
        p_swiftcity_special: { eq: true },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        special: true,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        special: false,
      }));
    }
    if (isSwiftDelivery) {
      nestedList.push({
        p_delivery_type: { eq: "Express" },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        swiftdelivery: true,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        swiftdelivery: false,
      }));
    }
    if (isOnCod) {
      nestedList.push({
        p_on_cod: { eq: true },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        cod: true,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        cod: false,
      }));
    }
    if (priceRange[0] > 199 || priceRange[1] < 9999) {
      nestedList.push({
        p_default_price: { range: [priceRange[0], priceRange[1]] },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        price: [priceRange[0], priceRange[1]],
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        price: [199, 9999],
      }));
    }
    if (ratingFilter === 3 || ratingFilter === 4) {
      nestedList.push({
        p_rating: { gte: ratingFilter },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        rating: ratingFilter,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        rating: 0,
      }));
    }
    if (selectedBrands.length > 0) {
      nestedList.push({
        p_brand: { match: selectedBrands },
      });
    }
    if (selectedColors.length > 0) {
      nestedList.push({
        p_color: { match: selectedColors },
      });
    }
    if (selectedOffPer === "10% off or more") {
      nestedList.push({
        p_default_off: { gte: 10 },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        offer: selectedOffPer,
      }));
    } else if (selectedOffPer === "25% off or more") {
      nestedList.push({
        p_default_off: { gte: 25 },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        offer: selectedOffPer,
      }));
    } else if (selectedOffPer === "50% off or more") {
      nestedList.push({
        p_default_off: { gte: 50 },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        offer: selectedOffPer,
      }));
    } else if (selectedOffPer === "70% off or more") {
      nestedList.push({
        p_default_off: { gte: 70 },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        offer: selectedOffPer,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        offer: "",
      }));
    }
    if (selectedDeal === "sunday") {
      nestedList.push({
        p_sundaysale: { eq: true },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        deal: selectedDeal,
      }));
    } else if (selectedDeal === "limited") {
      nestedList.push({
        p_limited_sale: { eq: true },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        deal: selectedDeal,
      }));
    } else if (selectedDeal === "seasonal") {
      nestedList.push({
        p_seasonaloffer: { eq: true },
      });
      setFilterSelected((prevData) => ({
        ...prevData,
        deal: selectedDeal,
      }));
    } else {
      setFilterSelected((prevData) => ({
        ...prevData,
        deal: "",
      }));
    }
    if (isFromSearch) {
      const searchValue = listingSearch.toLowerCase();
      if (selectedColors.length > 0 || matchColor(searchValue.trim())) {
        setFetchFilter({
          p_title: { match: searchValue },
          and: [{ p_default_off: { gt: 0 } }, ...nestedList],
        });
      } else {
        setFetchFilter({
          p_title: { match: searchValue },
          and: [{ p_default_variation: { eq: true } }, ...nestedList],
        });
      }
    } else {
      if (selectedColors.length > 0) {
        setFetchFilter({
          p_category: { match: listingCategory },
          and: [{ p_default_off: { gt: 0 } }, ...nestedList],
        });
      } else {
        setFetchFilter({
          p_category: { match: listingCategory },
          and: [{ p_default_variation: { eq: true } }, ...nestedList],
        });
      }
    }
    setFilterCount(nestedList.length);
    setIsFilterOpen(false);
    document.body.style.overflow = "auto";
  };

  const getOffPers = (mrp, price) => {
    const off = ((mrp - price) / mrp) * 100;
    return parseInt(off);
  };

  const handlePageChange = async (e, value) => {
    setLoading(true);
    const skipCount = (value - 1) * fetchLimit;
    try {
      const res = await client.graphql({
        query: searchListings,
        variables: {
          filter: fetchFilter,
          sort: sortType,
          from: skipCount,
          limit: fetchLimit,
        },
      });
      var { items: newItems, total: totalCount } = res.data.searchListings;
      const tPag = totalCount / fetchLimit;
      setPagesCount(Math.ceil(tPag));
      setFetchCount(totalCount);
      const imgUrls = await Promise.all(
        newItems.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      const initialBoolList = newItems.map(() => false);
      setIsIndImgLoaded(initialBoolList);
      setListingImgUrls(imgUrls);
      setFilteredItems(newItems);
      setCurrentPage(value);
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };

  const initItems = async () => {
    let initFilters = {};
    if (listingCategory) {
      initFilters = {
        p_category: { match: listingCategory },
        and: [
          {
            p_default_variation: { eq: true },
          },
        ],
      };
      setTitle(`Category: ${listingCategory}`);
      setIsFromSearch(false);
    } else if (myListType) {
      const listValue = myListType.trim();
      if (listValue === "likedItems") {
        initFilters = {
          id: { match: user.user_liked_items.toString() },
          and: [{ p_default_off: { gt: 0 } }],
        };
        setTitle("Liked Items");
      } else if (listValue === "savedItems") {
        initFilters = {
          id: { match: user.user_saved_items.toString() },
          and: [{ p_default_off: { gt: 0 } }],
        };
        setTitle("Saved Items");
      } else if (listValue === "recentItems") {
        initFilters = {
          id: { match: user.user_viewed_items.toString() },
          and: [{ p_default_off: { gt: 0 } }],
        };
        setTitle("Recemtly Viewed Items");
      }
    } else {
      const searchValue = listingSearch.trim();
      if (matchColor(searchValue)) {
        initFilters = {
          p_title: { match: searchValue },
          and: [{ p_default_off: { gt: 0 } }],
        };
      } else {
        initFilters = {
          p_title: { match: searchValue },
          and: [{ p_default_variation: { eq: true } }],
        };
        console.log("called without red");
      }
      setTitle(`Search: ${searchValue}`);
      setIsFromSearch(true);
    }
    setFetchFilter(initFilters);
    setLoading(true);
    try {
      const res = await client.graphql({
        query: searchListings,
        variables: {
          filter: initFilters,
          limit: fetchLimit,
        },
      });
      var { items: newItems, total: totalCount } = res.data.searchListings;
      const tPag = totalCount / fetchLimit;
      setPagesCount(Math.ceil(tPag));
      setFetchCount(totalCount);
      const imgUrls = await Promise.all(
        newItems.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      const initialBoolList = newItems.map(() => false);
      setIsIndImgLoaded(initialBoolList);
      setListingImgUrls(imgUrls);
      setFilteredItems(newItems);
      setCurrentPage(1);
      setFirstInit(false);
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabFilterClose = (event) => {
    if (filterDivRef.current && !filterDivRef.current.contains(event.target)) {
      setIsTabFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTabFilterClose);
    return () => {
      document.removeEventListener("mousedown", handleTabFilterClose);
    };
  }, []);

  useEffect(() => {
    initItems();
  }, [loading]);

  const toggleDrawer = () => {
    if (isFilterOpen) {
      setIsFilterOpen(false);
      document.body.style.overflow = "auto";
    } else {
      setIsFilterOpen(true);
      document.body.style.overflow = "hidden";
    }
  };

  const handleFilterClear = () => {
    setSelectedGender("");
    setSpecialChecked(false);
    setIsSwiftDelivery(false);
    setIsOnCod(false);
    setPriceRange([199, 9999]);
    setRatingFilter(0);
    setSelectedBrands([]);
    setSelectedColors([]);
    setSelectedOffPer("");
    setSelectedDeal("");
    setFilterCount(0);
    setFilterSelected({
      price: [199, 9999],
      rating: 0,
      offer: "",
      gender: "",
      cod: false,
      special: false,
      swiftdelivery: false,
      deal: "",
    });
    if (isFromSearch) {
      const searchValue = listingSearch.toLowerCase();
      if (matchColor(searchValue.trim())) {
        setFetchFilter((prevData) => ({
          p_title: { match: searchValue },
          and: [{ p_default_off: { gt: 0 } }],
        }));
      } else {
        setFetchFilter((prevData) => ({
          p_title: { match: searchValue },
          and: [{ p_default_variation: { eq: true } }],
        }));
      }
    } else {
      setFetchFilter((prevData) => ({
        p_category: { match: listingCategory },
        and: [{ p_default_variation: { eq: true } }],
      }));
    }
    setIsFilterOpen(false);
    setIsTabFilter(false);
    document.body.style.overflow = "auto";
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  const handlePriceRangeChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 9999 - minDistance);
        setPriceRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setPriceRange([clamped - minDistance, clamped]);
      }
    } else {
      setPriceRange(newValue);
    }
  };

  const handleColorClick = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors(selectedColors.filter((c) => c !== color));
    } else {
      setSelectedColors([...selectedColors, color]);
    }
  };

  const handleBrandBtnClick = (brand) => {
    if (selectedBrands.includes(brand)) {
      setSelectedBrands(selectedBrands.filter((b) => b !== brand));
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  const handleOffPerClick = (offPer) => {
    if (selectedOffPer === offPer) {
      setSelectedOffPer("");
    } else {
      setSelectedOffPer(offPer);
    }
  };

  const handleGenderClick = (gdr) => {
    if (selectedGender === gdr) {
      setSelectedGender("");
    } else {
      setSelectedGender(gdr);
    }
  };

  const handleFilterDealSelect = (type) => {
    if (selectedDeal === type) {
      setSelectedDeal("");
    } else {
      setSelectedDeal(type);
    }
  };

  const formatIndianNumber = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  function rangeText(value) {
    return `₹${value}`;
  }

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsFilterOpen(false);
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickSortOutside = (event) => {
    if (
      sortDrawerRef.current &&
      !sortDrawerRef.current.contains(event.target)
    ) {
      setIsSortOpen(false);
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickSortOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickSortOutside);
    };
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: "#0084ff",
          position: "sticky",
          top: "0",
          zIndex: "999",
        }}
      >
        <GlobelHeader top={0} isMobLocSel={false} />
        <div
          className="it-smalldivi-filter-container"
          style={{
            borderBottom: "1px solid #dddddd",
          }}
        >
          <div
            className="it-selected-filters"
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "8px",
              overflowX: "auto",
              padding: "5px",
            }}
          >
            {filterSelected.special && (
              <div
                className="it-swiftcity-spectial-bool"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "linear-gradient(to left, #ffebc5, #c7e5ff)",
                  borderRadius: "20px 0 20px 0",
                  gap: "5px",
                  padding: "5px 8px",
                }}
              >
                <img
                  style={{
                    width: "80px",
                  }}
                  src="./assets/images/swift_special.png"
                  alt="swift-sp"
                />
              </div>
            )}
            <button
              className="it-smalldivi-rating-filterbtn"
              onClick={() => {
                setIsSortOpen(true);
                document.body.style.overflow = "hidden";
              }}
              style={{
                border: "1px solid",
                borderColor: isSortOpen ? "#007bff" : "#b7b7b7",
                borderRadius: "20px",
                width: "fit-content",
                padding: "4px 6px",
                minHeight: "30px",
                fontWeight: "600",
                background: "linear-gradient(to left, white, #c7e5ff)",
                color: isSortOpen ? "#007bff" : "black",
                display: "flex",
                gap: "3px",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              {sortTypes[selectedSortInd]}
              {!isSortOpen ? (
                <KeyboardArrowDownRoundedIcon
                  sx={{
                    fontSize: 22,
                    color: "black",
                    backgroundColor: "#ddd",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <KeyboardArrowUpRoundedIcon
                  sx={{
                    fontSize: 22,
                    color: "white",
                    backgroundColor: "#007bff",
                    borderRadius: "50%",
                  }}
                />
              )}
            </button>
            {filterSelected.rating > 2 && (
              <button
                className="it-smalldivi-rating-filterbtn"
                style={{
                  border: "1px solid #aed9ff",
                  borderRadius: "20px",
                  width: "fit-content",
                  padding: "5px 10px",
                  minHeight: "30px",
                  fontWeight: "600",
                  background: "linear-gradient(to left, white, #c7e5ff)",
                  color: "#007bff",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                Ratings (&#9733;{filterSelected.rating}+)
              </button>
            )}
            {(filterSelected.price[0] !== 199 ||
              filterSelected.price[1] !== 9999) && (
              <button
                className="it-smalldivi-rating-filterbtn"
                style={{
                  border: "1px solid #aed9ff",
                  borderRadius: "20px",
                  width: "fit-content",
                  padding: "5px 10px",
                  minHeight: "30px",
                  fontWeight: "600",
                  background: "linear-gradient(to left, white, #c7e5ff)",
                  color: "#007bff",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                {`Price (₹${formatIndianNumber(
                  filterSelected.price[0]
                )} - ₹${formatIndianNumber(filterSelected.price[1])})`}
              </button>
            )}

            {filterSelected.offer !== "" && (
              <button
                className="it-smalldivi-rating-filterbtn"
                style={{
                  border: "1px solid #aed9ff",
                  borderRadius: "20px",
                  width: "fit-content",
                  padding: "5px 10px",
                  minHeight: "30px",
                  fontWeight: "600",
                  background: "linear-gradient(to left, white, #c7e5ff)",
                  color: "#007bff",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                {filterSelected.offer}
              </button>
            )}
            {filterSelected.deal !== "" && (
              <button
                className="it-smalldivi-rating-filterbtn"
                style={{
                  border: "1px solid #aed9ff",
                  borderRadius: "20px",
                  width: "fit-content",
                  padding: "5px 10px",
                  minHeight: "30px",
                  fontWeight: "600",
                  background: "linear-gradient(to left, white, #c7e5ff)",
                  color: "#007bff",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                {filterSelected.deal === "sunday"
                  ? "Sunday sale"
                  : filterSelected.deal === "limited"
                  ? "Limited offer"
                  : "Seasonal deal"}{" "}
              </button>
            )}
            {filterSelected.gender !== "" && (
              <button
                className="it-smalldivi-rating-filterbtn"
                style={{
                  border: "1px solid #aed9ff",
                  borderRadius: "20px",
                  width: "fit-content",
                  padding: "5px 10px",
                  minHeight: "30px",
                  fontWeight: "600",
                  background: "linear-gradient(to left, white, #c7e5ff)",
                  color: "#007bff",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                {filterSelected.gender}
              </button>
            )}
            {filterSelected.cod && (
              <button
                className="it-smalldivi-rating-filterbtn"
                style={{
                  border: "1px solid #aed9ff",
                  borderRadius: "20px",
                  width: "fit-content",
                  padding: "5px 10px",
                  minHeight: "30px",
                  fontWeight: "600",
                  background: "linear-gradient(to left, white, #c7e5ff)",
                  color: "#007bff",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                COD available
              </button>
            )}
          </div>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              height: "50px",
              border: "none",
              borderLeft: "1px solid #dddddd",
            }}
            className="it-filter-button"
            onClick={toggleDrawer}
          >
            <FilterAltRoundedIcon sx={{ fontSize: 30, padding: "3px" }} />
            <span>
              {filterCount > 0 ? `Filters(${filterCount})` : "Filters"}
            </span>
            {!isFilterOpen ? (
              <KeyboardArrowDownRoundedIcon
                sx={{
                  fontSize: 24,
                  color: "#000",
                }}
              />
            ) : (
              <KeyboardArrowUpRoundedIcon
                sx={{
                  fontSize: 24,
                  color: "#000",
                }}
              />
            )}
          </button>
        </div>
      </div>
      <CatDrop />
      <div
        className="itm-main"
        style={{
          display: "flex",
          gap: "12px",
        }}
      >
        <div
          ref={filterDivRef}
          className={`itm-filter-container-desk ${isTabFilter ? "open" : ""}`}
        >
          <div
            className="it-filter-head"
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "10px",
              gap: "8px",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              {filterCount > 0 ? `Filters(${filterCount})` : "Filters"}
            </span>
            <button
              onClick={handleFilterClear}
              style={{
                border: "none",
                marginLeft: "auto",
                backgroundColor: "white",
                fontSize: "12px",
                color: "red",
                fontWeight: "500",
              }}
            >
              Clear
            </button>
            <button
              onClick={handleFilterApply}
              style={{
                border: "none",
                backgroundColor: "#007bff",
                padding: "5px 10px",
                color: "white",
                fontWeight: "600",
                borderRadius: "4px",
              }}
            >
              Apply
            </button>
          </div>
          <div className="it-filter-divider"></div>
          <div
            className="it-cat-filter"
            style={{
              marginTop: "10px",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
                fontWeight: "600",
              }}
            >
              Gender
            </span>
            <div className="it-cat-tiles" style={{ gap: "10px" }}>
              <div
                className="it-single-cat-tile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <input
                  type="checkbox"
                  id="gndr-chk-male-dt12"
                  checked={selectedGender === "Man"}
                  onChange={() => handleGenderClick("Man")}
                />
                <label
                  htmlFor="gndr-chk-male-dt12"
                  className="it-catbtn"
                  style={{
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Man
                </label>
              </div>
              <div
                className="it-single-cat-tile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <input
                  type="checkbox"
                  id="gndr-chk-female-dt34"
                  checked={selectedGender === "Women"}
                  onChange={() => handleGenderClick("Women")}
                />
                <label
                  htmlFor="gndr-chk-female-dt34"
                  className="it-catbtn"
                  style={{
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Woman
                </label>
              </div>
            </div>
          </div>
          <div className="it-filter-divider"></div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              margin: "15px 0",
            }}
          >
            <div
              className="it-special-filter"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px",
                border: "1px solid #f4f3f3",
                borderRadius: "20px",
              }}
            >
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                }}
              >
                SwiftCity special
              </span>
              <div className="switch-container">
                <div
                  className={`switch ${specialChecked ? "on" : "off"}`}
                  onClick={() => setSpecialChecked(!specialChecked)}
                >
                  <div className="switch-circle"></div>
                </div>
              </div>
            </div>
            <div
              className="it-special-filter"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px",
                border: "1px solid #f4f3f3",
                borderRadius: "20px",
              }}
            >
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                }}
              >
                Swift Delivery (1-3 days)
              </span>
              <div className="switch-container">
                <div
                  className={`switch ${isSwiftDelivery ? "on" : "off"}`}
                  onClick={() => setIsSwiftDelivery(!isSwiftDelivery)}
                >
                  <div className="switch-circle"></div>
                </div>
              </div>
            </div>
            <div
              className="it-special-filter"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px",
                border: "1px solid #f4f3f3",
                borderRadius: "20px",
              }}
            >
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                }}
              >
                Eligible for COD
              </span>
              <div className="switch-container">
                <div
                  className={`switch ${isOnCod ? "on" : "off"}`}
                  onClick={() => setIsOnCod(!isOnCod)}
                >
                  <div className="switch-circle"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="it-filter-divider"></div>
          <div className="it-ratings-filter" style={{ padding: "5px 0" }}>
            <span
              style={{
                fontSize: "0.9rem",
                fontWeight: "600",
              }}
            >
              Customer ratings
            </span>
            <div
              className="it-ratecount-filer-tile"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "13px",
                padding: "5px",
                border: "1px solid #f4f3f3",
                borderRadius: "20px",
                marginBottom: "10px",
                marginTop: "8px",
              }}
            >
              <Rating
                name="rating-read"
                defaultValue={4.4}
                precision={0.2}
                readOnly
              />
              <span style={{ marginRight: "auto" }}>(4+)</span>
              <div className="switch-container">
                <div
                  className={`switch ${ratingFilter === 4 ? "on" : "off"}`}
                  onClick={() => setRatingFilter(ratingFilter === 4 ? 0 : 4)}
                >
                  <div className="switch-circle"></div>
                </div>
              </div>
            </div>
            <div
              className="it-ratecount-filer-tile"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "13px",
                padding: "5px",
                border: "1px solid #f4f3f3",
                borderRadius: "20px",
                marginBottom: "10px",
                marginTop: "8px",
              }}
            >
              <Rating
                name="rating-read"
                defaultValue={3.4}
                precision={0.2}
                readOnly
              />
              <span style={{ marginRight: "auto" }}>(3+)</span>
              <div className="switch-container">
                <div
                  className={`switch ${ratingFilter === 3 ? "on" : "off"}`}
                  onClick={() => setRatingFilter(ratingFilter === 3 ? 0 : 3)}
                >
                  <div className="switch-circle"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="it-price-range-container"
            style={{
              padding: "5px",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
                fontWeight: "600",
              }}
            >
              Price (₹{formatIndianNumber(priceRange[0])}- ₹
              {formatIndianNumber(priceRange[1])})
            </span>
            <div
              className="it-range-filer-row"
              style={{
                padding: "10px",
              }}
            >
              <Slider
                getAriaLabel={(index) =>
                  index === 0 ? "Minimum price" : "Maximum price"
                }
                value={priceRange}
                onChange={handlePriceRangeChange}
                valueLabelDisplay="off"
                min={199}
                max={10000}
                getAriaValueText={rangeText}
                disableSwap
                style={{
                  color: "#007bff",
                }}
              />
            </div>
          </div>
          {/* <div
            className="it-brand-filter"
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <div className="it-brafilter-input-row">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  value={brandDropInput}
                  id="it-brasearch"
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleFilterBrandEnter(e)}
                  placeholder="Search for brands..."
                  style={{
                    width: "100%",
                    padding: "8px",
                    paddingRight: "30px",
                    boxSizing: "border-box",
                    border: "1px solid #dddddd",
                  }}
                />
                <button
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "0px",
                    cursor: "pointer",
                    transform: "translateY(-50%)",
                    padding: "0",
                    paddingRight: "5px",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  {!showDropdown ? (
                    <KeyboardArrowDownRoundedIcon
                      sx={{
                        fontSize: 24,
                        color: "#000",
                      }}
                    />
                  ) : (
                    <KeyboardArrowUpRoundedIcon
                      sx={{
                        fontSize: 24,
                        color: "#000",
                      }}
                    />
                  )}
                </button>

                {showDropdown && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      zIndex: 1,
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredBrands.map((brand, index) => (
                      <div
                        key={index}
                        onClick={() => handleBrandClick(brand)}
                        style={{
                          padding: "3px 6px",
                          cursor: "pointer",
                          borderBottom: "1px solid #eee",
                          fontSize: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {brand}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {selectedBrands.length > 0 && (
              <div
                className="it-sel-brafilter-listcont"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  alignItems: "end",
                }}
              >
                <div className="it-selected-brands">
                  {selectedBrands.map((bra, index) => (
                    <div key={index} className="it-single-bra-box">
                      <span>{bra}</span>
                      <button
                        className="it-sin-bra-cut"
                        onClick={() => handleFilterBrandRemove(index)}
                      >
                        <CancelRoundedIcon
                          sx={{ color: "red", fontSize: 15 }}
                        />
                      </button>
                    </div>
                  ))}
                </div>
                <button
                  style={{
                    backgroundColor: "#007bff",
                    border: "none",
                    padding: "0",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArrowForwardRoundedIcon
                    sx={{ fontSize: 30, color: "#fff", padding: "5px" }}
                  />
                </button>
              </div>
            )}
          </div> */}
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
            }}
          >
            Popular brands
          </span>
          <div
            className="it-btand-suggs"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              margin: "15px 0",
            }}
          >
            {popularBrands.map((brand, index) => (
              <button
                key={index}
                className="it-brandsugg-btn"
                onClick={() => handleBrandBtnClick(brand)}
                style={{
                  padding: "5px 10px",
                  border: "none",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  backgroundColor: selectedBrands.includes(brand)
                    ? "#007bff"
                    : "#f0f0f0",
                  borderRadius: "15px",
                  color: selectedBrands.includes(brand) ? "white" : "black",
                }}
              >
                {brand}
              </button>
            ))}
          </div>

          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
            }}
          >
            {selectedColors.length > 0
              ? `Colours (${selectedColors.length} applied)`
              : "Colours"}
          </span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "6px",
              padding: "8px",
            }}
          >
            {colors.map((color, index) => (
              <div
                key={index * 3}
                className="outer-color-border"
                style={{
                  backgroundColor: selectedColors.includes(color)
                    ? "#007bff"
                    : "#dddddd",
                  borderRadius: "50%",
                  position: "relative",
                }}
              >
                {color === "multicolor" ? (
                  <div
                    key={index}
                    onClick={() => handleColorClick(color)}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      cursor: "pointer",
                      border: "2px solid white",
                      margin: "2px",
                      display: "flex",
                      flexWrap: "wrap",
                      transform: "rotate(45deg)",
                    }}
                  >
                    <span
                      style={{
                        width: "50%",
                        height: "50%",
                        backgroundColor: "red",
                        borderRadius: "20px 0 0 0",
                      }}
                    ></span>
                    <span
                      style={{
                        width: "50%",
                        height: "50%",
                        backgroundColor: "green",
                        borderRadius: " 0 20px 0 0",
                      }}
                    ></span>
                    <span
                      style={{
                        width: "50%",
                        height: "50%",
                        backgroundColor: "yellow",
                        borderRadius: "0 0 0 20px",
                      }}
                    ></span>
                    <span
                      style={{
                        width: "50%",
                        height: "50%",
                        backgroundColor: "black",
                        borderRadius: "0 0 20px 0",
                      }}
                    ></span>
                  </div>
                ) : (
                  <div
                    key={index}
                    onClick={() => handleColorClick(color)}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: color,
                      borderRadius: "50%",
                      cursor: "pointer",
                      border: "2px solid white",
                      margin: "2px",
                    }}
                  ></div>
                )}
                <p
                  className="color-tooltip"
                  style={{
                    textTransform: "capitalize",
                    userSelect: "none",
                  }}
                >
                  {color}
                </p>
              </div>
            ))}
          </div>
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
            }}
          >
            Discount
          </span>
          <div
            className="it-discount-filter"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              alignItems: "start",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #f4f3f3",
              borderRadius: "20px",
            }}
          >
            {offPercentageText.map((offPer, index) => (
              <div
                key={index}
                className="it-discount-btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  border: "none",
                  backgroundColor: "white",
                  fontSize: "13px",
                  userSelect: "none",
                }}
              >
                <input
                  type="checkbox"
                  id={`desk-off-${index}`}
                  checked={selectedOffPer === offPer ? true : false}
                  onChange={() => handleOffPerClick(offPer)}
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor={`desk-off-${index}`}
                  style={{
                    fontWeight: selectedOffPer === offPer ? "600" : "500",
                    color: selectedOffPer === offPer ? "#007bff" : "black",
                    cursor: "pointer",
                  }}
                >
                  {offPer}
                </label>
              </div>
            ))}
          </div>
          <span
            style={{
              fontSize: "0.9rem",
              fontWeight: "600",
            }}
          >
            Offers & deals
          </span>
          <div
            className="it-offers-deals"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "start",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #f4f3f3",
              borderRadius: "20px",
            }}
          >
            {isSunday && (
              <div
                className="it-single-offer-tile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  userSelect: "none",
                }}
              >
                <input
                  type="checkbox"
                  id="offer-chk0"
                  checked={selectedDeal === "sunday" ? true : false}
                  onChange={() => handleFilterDealSelect("sunday")}
                />
                <label
                  htmlFor="offer-chk0"
                  className="it-offerbtn"
                  style={{
                    cursor: "pointer",
                    fontWeight: selectedDeal === "sunday" ? "600" : "500",
                    color: selectedDeal === "sunday" ? "#007bff" : "black",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Sunday sale
                </label>
              </div>
            )}

            <div
              className="it-single-offer-tile"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                userSelect: "none",
              }}
            >
              <input
                type="checkbox"
                id="offer-chk"
                checked={selectedDeal === "limited" ? true : false}
                onChange={() => handleFilterDealSelect("limited")}
              />
              <label
                htmlFor="offer-chk"
                className="it-offerbtn"
                style={{
                  cursor: "pointer",
                  fontWeight: selectedDeal === "limited" ? "600" : "500",
                  color: selectedDeal === "limited" ? "#007bff" : "black",
                  fontSize: "13px",
                  padding: "3px",
                }}
              >
                Limited time deals
              </label>
            </div>
            <div
              className="it-single-offer-tile"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                userSelect: "none",
              }}
            >
              <input
                type="checkbox"
                id="offer-chk2"
                checked={selectedDeal === "seasonal" ? true : false}
                onChange={() => handleFilterDealSelect("seasonal")}
              />
              <label
                htmlFor="offer-chk2"
                className="it-offerbtn"
                style={{
                  cursor: "pointer",
                  fontWeight: selectedDeal === "seasonal" ? "600" : "500",
                  color: selectedDeal === "seasonal" ? "#007bff" : "black",
                  fontSize: "13px",
                  padding: "3px",
                }}
              >
                Seasonal delas
              </label>
            </div>
          </div>
        </div>
        <div className="itm-list-container">
          <div className="it-sort-row">
            <span className="it-desk-divi-sortheading">Sort by:</span>
            {sortTypes.map((sortTitle, index) => (
              <button
                key={index * 2.6}
                className="it-sort-btn"
                onClick={() => handleSorting(index)}
                style={{
                  backgroundColor:
                    index === selectedSortInd ? "#0084ff" : "#f0f0f0",
                  color: index === selectedSortInd ? "white" : "black",
                }}
              >
                {sortTitle}
              </button>
            ))}
            <button
              className={`it-filter-btn ${isTabFilter ? "open" : ""}`}
              style={{
                color: isTabFilter ? "white" : "black",
                backgroundColor: isTabFilter ? "#0084ff" : "#f0f0f0",
              }}
              onClick={() => setIsTabFilter(!isTabFilter)}
            >
              Filter
              <TuneRoundedIcon
                sx={{
                  fontSize: 20,
                  padding: "3px",
                  color: isTabFilter ? "white" : "black",
                }}
              />
            </button>
          </div>

          {filteredItems.length === 0 ? (
            <div
              className="no-filter-items"
              style={{
                backgroundColor: "#f5f5f5",
                padding: "12px",
                margin: "20px 12px 12px 12px",
                border: "1px solid #dddddd",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                No results found!
              </span>
              <span
                style={{
                  fontSize: "0.9rem",
                }}
              >
                Try different keywords or remove filters
              </span>
            </div>
          ) : (
            <div className="it-items-flex">
              {filteredItems.map((filterItem, index) => (
                <div key={`${index * 2}`} className="it-sign-bg">
                  {!isIndImgLoaded[index] && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="shimmer-box"
                        style={{
                          aspectRatio: "4/5",
                          width: "100%",
                        }}
                      ></div>
                      <div
                        className="shimmer-box"
                        style={{
                          height: "20px",
                          width: "60%",
                        }}
                      ></div>
                      <div
                        className="shimmer-box"
                        style={{
                          height: "20px",
                          width: "90%",
                        }}
                      ></div>
                      <div
                        className="shimmer-box"
                        style={{
                          height: "20px",
                          width: "50%",
                        }}
                      ></div>
                      <div
                        className="shimmer-box"
                        style={{
                          height: "20px",
                          width: "80%",
                        }}
                      ></div>
                    </div>
                  )}
                  <div
                    className="it-single-item"
                    onClick={() =>
                      window.open(
                        `/listing?title=${encodeURIComponent(
                          filterItem.p_title
                        )}&slid=${encodeURIComponent(filterItem.id)}`
                      )
                    }
                    style={{
                      display: isIndImgLoaded[index] ? "block" : "none",
                      textDecoration: "none",
                    }}
                  >
                    <div
                      className="it-img-container"
                      style={{
                        width: "100%",
                        aspectRatio: "4/5",
                        borderRadius: "6px 6px 0 0",
                        backgroundImage: "url('./preloaderimg.jpeg')",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <div className="it-ongoing-offer">
                        {filterItem.p_sundaysale && isSunday ? (
                          <span
                            style={{
                              padding: "0 10px 0 3px",
                            }}
                          >
                            Sunday sale
                          </span>
                        ) : filterItem.p_seasonaloffer ? (
                          <span
                            style={{
                              padding: "0 10px 0 3px",
                            }}
                          >
                            Seasonal offer
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <img
                        src={listingsImgUrls[index]}
                        onLoad={() =>
                          setIsIndImgLoaded((preBools) => {
                            const newList = [...preBools];
                            newList[index] = true;
                            return newList;
                          })
                        }
                        alt={`Listing_${index * 307}swiftciy.png`}
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                          aspectRatio: "4/5",
                          borderRadius: "4px 4px 0 0",
                        }}
                      />
                      {user && (
                        <div
                          className="my-list-icon"
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {user.user_saved_items.includes(filterItem.id) && (
                            <BookmarksRoundedIcon
                              sx={{
                                fontSize: 32,
                                color: "#505050",
                                backgroundColor: "white",
                                borderRadius: "8px",
                                padding: "4px",
                                border: "1px solid #dddddd",
                              }}
                            />
                          )}
                          {user.user_liked_items.includes(filterItem.id) && (
                            <ThumbUpAltRoundedIcon
                              sx={{
                                fontSize: 32,
                                color: "#505050",
                                backgroundColor: "white",
                                borderRadius: "8px",
                                padding: "4px",
                                border: "1px solid #dddddd",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className="it-item-dadiv"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                        zIndex: "200",
                      }}
                    >
                      {filterItem.p_swiftcity_special && (
                        <img
                          style={{
                            width: "60px",
                            margin: "auto",
                          }}
                          src="./assets/images/swift_special.png"
                          alt="swift_sp"
                        />
                      )}

                      <div
                        className="it-brand"
                        style={{
                          fontWeight: "600",
                          fontSize: "0.8rem",
                          margin: "auto",
                        }}
                      >
                        {filterItem.p_brand}
                      </div>
                      <div className="it-title">{filterItem.p_title}</div>
                      <div
                        className="it-ratings-row"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          margin: "auto",
                          gap: "4px",
                        }}
                      >
                        <Rating
                          name="half-rating-read"
                          // defaultValue={filterItem.p_rating}
                          defaultValue={3.3}
                          precision={0.1}
                          readOnly
                          sx={{
                            color: "#ff0048",
                          }}
                        />
                        <span
                          className="it-ratings-count"
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {`(${filterItem.p_ratings_count})`}
                        </span>
                      </div>
                      <div
                        className="it-item-prov"
                        style={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        <div
                          className="it-em-rp"
                          style={{
                            display: "flex",
                          }}
                        >
                          <span
                            className="it-rupee-symbol"
                            style={{
                              fontSize: "0.8rem",
                            }}
                          >
                            ₹
                          </span>
                          <span
                            className="it-itprice"
                            style={{
                              fontSize: "1.4rem",
                            }}
                          >
                            {formatIndianNumber(
                              filterItem.p_measurements[0].p_price
                            )}
                          </span>
                        </div>
                        <span
                          className="it-itmrp"
                          style={{
                            fontWeight: "500",
                            fontSize: "1rem",
                            textDecoration: "line-through",
                            color: "#626262",
                          }}
                        >
                          ₹
                          {formatIndianNumber(
                            filterItem.p_measurements[0].p_mrp
                          )}
                        </span>
                        <span
                          className="it-off"
                          style={{
                            fontWeight: "600",
                            fontSize: "1rem",
                            color: "#0084ff",
                          }}
                        >
                          {`${getOffPers(
                            filterItem.p_measurements[0].p_mrp,
                            filterItem.p_measurements[0].p_price
                          )}% off`}
                        </span>
                      </div>
                      <span
                        className="it-ismorevari-txt"
                        style={{
                          fontSize: "0.8rem",
                          color: "#0085ba",
                          padding: "5px",
                          textAlign: "center",
                        }}
                      >
                        Multiple variations available »
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {fetchCount > fetchLimit && (
            <div
              className="it-pagination-box"
              style={{
                margin: "20px",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={pagesCount}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </div>
          )}
        </div>
        <div className={`bottom-drawer-overlay ${isFilterOpen ? "open" : ""}`}>
          <div
            ref={drawerRef}
            className={`it-bottom-drawer ${isFilterOpen ? "open" : ""}`}
            style={{
              backgroundColor: "white",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px 20px 0 0",
              marginTop: "100px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="drawer-head"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "18px 12px",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {filterCount > 0 ? `Filters(${filterCount})` : "Filters"}
              </div>
              {fetchFilter.and.length > 0 && (
                <button
                  onClick={handleFilterClear}
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    marginLeft: "auto",
                    marginRight: "10px",
                    fontWeight: "600",
                    color: "red",
                    textDecoration: "underline",
                  }}
                >
                  Clear all
                </button>
              )}
            </div>
            <div
              className="it-filter-drawer-body"
              style={{
                display: "flex",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                className="it-filter-list"
                style={{
                  width: "33%",
                  borderRight: "1px solid #dddddd",
                  minHeight: "45vh",
                }}
              >
                <div
                  className="it-filter-list-tile"
                  style={{
                    backgroundColor: filterIndex === 0 ? "#007bff" : "#f0f0f0",
                    color: filterIndex === 0 ? "white" : "black",
                  }}
                  onClick={() => setFilterIndex(0)}
                >
                  SwiftCity special
                </div>
                <div
                  style={{
                    backgroundColor: filterIndex === 1 ? "#007bff" : "white",
                    color: filterIndex === 1 ? "white" : "black",
                  }}
                  className="it-filter-list-tile"
                  onClick={() => setFilterIndex(1)}
                >
                  Gender
                </div>
                <div
                  className="it-filter-list-tile"
                  style={{
                    backgroundColor: filterIndex === 2 ? "#007bff" : "#f0f0f0",
                    color: filterIndex === 2 ? "white" : "black",
                  }}
                  onClick={() => setFilterIndex(2)}
                >
                  Customer ratings
                </div>
                <div
                  className="it-filter-list-tile"
                  style={{
                    backgroundColor: filterIndex === 3 ? "#007bff" : "white",
                    color: filterIndex === 3 ? "white" : "black",
                  }}
                  onClick={() => setFilterIndex(3)}
                >
                  Price
                </div>
                <div
                  className="it-filter-list-tile"
                  style={{
                    backgroundColor: filterIndex === 4 ? "#007bff" : "#f0f0f0",
                    color: filterIndex === 4 ? "white" : "black",
                  }}
                  onClick={() => setFilterIndex(4)}
                >
                  Brands
                </div>
                <div
                  className="it-filter-list-tile"
                  style={{
                    backgroundColor: filterIndex === 5 ? "#007bff" : "white",
                    color: filterIndex === 5 ? "white" : "black",
                  }}
                  onClick={() => setFilterIndex(5)}
                >
                  Colours
                </div>
                <div
                  className="it-filter-list-tile"
                  style={{
                    backgroundColor: filterIndex === 6 ? "#007bff" : "#f0f0f0",
                    color: filterIndex === 6 ? "white" : "black",
                  }}
                  onClick={() => setFilterIndex(6)}
                >
                  Deals & Discounts
                </div>
              </div>
              <div
                className="it-filter-content"
                style={{
                  padding: "12px",
                  width: "66%",
                }}
              >
                {filterIndex === 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      className="it-special-filter"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                      >
                        SwiftCity special
                      </span>
                      <div className="switch-container">
                        <div
                          className={`switch ${specialChecked ? "on" : "off"}`}
                          onClick={() => setSpecialChecked(!specialChecked)}
                        >
                          <div className="switch-circle"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="it-special-filter"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                      >
                        Swift Delivery (1-3 days)
                      </span>
                      <div className="switch-container">
                        <div
                          className={`switch ${isSwiftDelivery ? "on" : "off"}`}
                          onClick={() => setIsSwiftDelivery(!isSwiftDelivery)}
                        >
                          <div className="switch-circle"></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="it-special-filter"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                      >
                        Eligible for COD
                      </span>
                      <div className="switch-container">
                        <div
                          className={`switch ${isOnCod ? "on" : "off"}`}
                          onClick={() => setIsOnCod(!isOnCod)}
                        >
                          <div className="switch-circle"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {filterIndex === 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div className="it-cat-filter">
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                      >
                        Gender
                      </span>
                      <div className="it-cat-tiles" style={{ gap: "10px" }}>
                        <div
                          className="it-single-cat-tile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="gndr-chk-male"
                            checked={selectedGender === "Man"}
                            onChange={() => handleGenderClick("Man")}
                          />
                          <label
                            htmlFor="gndr-chk-male"
                            className="it-catbtn"
                            style={{
                              cursor: "pointer",
                              fontWeight: "500",
                              fontSize: "15px",
                              padding: "3px",
                            }}
                          >
                            Man
                          </label>
                        </div>
                        <div
                          className="it-single-cat-tile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="gndr-chk-female"
                            checked={selectedGender === "Women"}
                            onChange={() => handleGenderClick("Women")}
                          />
                          <label
                            htmlFor="gndr-chk-female"
                            className="it-catbtn"
                            style={{
                              cursor: "pointer",
                              fontWeight: "500",
                              fontSize: "15px",
                              padding: "3px",
                            }}
                          >
                            Woman
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {filterIndex === 2 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div className="it-ratings-filter">
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                      >
                        Customer ratings
                      </span>
                      <div
                        className="it-ratecount-filer-tile"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "13px",
                          padding: "5px",
                          border: "1px solid #f4f3f3",
                          borderRadius: "20px",
                          marginBottom: "10px",
                          marginTop: "8px",
                        }}
                      >
                        <Rating
                          name="rating-read"
                          defaultValue={4.4}
                          precision={0.2}
                          readOnly
                        />
                        <span style={{ marginRight: "auto" }}>(4+)</span>
                        <div className="switch-container">
                          <div
                            className={`switch ${
                              ratingFilter === 4 ? "on" : "off"
                            }`}
                            onClick={() =>
                              setRatingFilter(ratingFilter === 4 ? 0 : 4)
                            }
                          >
                            <div className="switch-circle"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="it-ratecount-filer-tile"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "13px",
                          padding: "5px",
                          border: "1px solid #f4f3f3",
                          borderRadius: "20px",
                        }}
                      >
                        <Rating
                          name="rating-read"
                          defaultValue={3.4}
                          precision={0.2}
                          readOnly
                        />
                        <span style={{ marginRight: "auto" }}>(3+)</span>
                        <div className="switch-container">
                          <div
                            className={`switch ${
                              ratingFilter === 3 ? "on" : "off"
                            }`}
                            onClick={() =>
                              setRatingFilter(ratingFilter === 3 ? 0 : 3)
                            }
                          >
                            <div className="switch-circle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {filterIndex === 3 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div className="it-price-range-container">
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                      >
                        Price (₹{formatIndianNumber(priceRange[0])}-₹
                        {formatIndianNumber(priceRange[1])})
                      </span>
                      <div
                        className="it-range-filer-row"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          padding: "5px",
                        }}
                      >
                        <Slider
                          getAriaLabel={(index) =>
                            index === 0 ? "Minimum price" : "Maximum price"
                          }
                          value={priceRange}
                          onChange={handlePriceRangeChange}
                          valueLabelDisplay="off"
                          min={199}
                          max={10000}
                          getAriaValueText={rangeText}
                          disableSwap
                          style={{
                            color: "#007bff",
                          }}
                        />

                        <button
                          style={{
                            backgroundColor: "#007bff",
                            border: "none",
                            padding: "0",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowForwardRoundedIcon
                            sx={{
                              fontSize: 30,
                              color: "#fff",
                              padding: "5px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <div
                      className="it-price-suggs"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                      }}
                    >
                      {filterPriceLimits.map((prLimit, index) => (
                        <button
                          key={index}
                          className="it-pricesugg-btn"
                          onClick={() => setPrLimit(prLimit)}
                          style={{
                            padding: "5px 10px",
                            border: "none",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            backgroundColor:
                              selectedPrLimits === prLimit
                                ? "#007bff"
                                : "#f0f0f0",
                            borderRadius: "15px",
                            color:
                              selectedPrLimits === prLimit ? "white" : "black",
                          }}
                        >
                          {`Under ₹${formatIndianNumber(prLimit)}`}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {filterIndex === 4 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      Popular brands
                    </span>
                    <div
                      className="it-btand-suggs"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                      }}
                    >
                      {popularBrands.map((brand, index) => (
                        <button
                          key={index}
                          className="it-brandsugg-btn"
                          onClick={() => handleBrandBtnClick(brand)}
                          style={{
                            padding: "5px 10px",
                            border: "none",
                            fontSize: "0.9rem",
                            fontWeight: "500",
                            backgroundColor: selectedBrands.includes(brand)
                              ? "#007bff"
                              : "#f0f0f0",
                            borderRadius: "15px",
                            color: selectedBrands.includes(brand)
                              ? "white"
                              : "black",
                          }}
                        >
                          {brand}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {filterIndex === 5 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      {selectedColors.length > 0
                        ? `Colours (${selectedColors.length} applied)`
                        : "Colours"}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "12px",
                        padding: "8px",
                      }}
                    >
                      {colors.map((color, index) => (
                        <div
                          key={index * 1.5}
                          className="outer-color-border"
                          style={{
                            backgroundColor: selectedColors.includes(color)
                              ? "#007bff"
                              : "#dddddd",
                            borderRadius: "50%",
                            position: "relative",
                          }}
                        >
                          {color === "multicolor" ? (
                            <div
                              key={index}
                              onClick={() => handleColorClick(color)}
                              style={{
                                width: "40px",
                                height: "40px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                cursor: "pointer",
                                border: "2px solid white",
                                margin: "2px",
                                display: "flex",
                                flexWrap: "wrap",
                                transform: "rotate(45deg)",
                              }}
                            >
                              <span
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  backgroundColor: "red",
                                  borderRadius: "20px 0 0 0",
                                }}
                              ></span>
                              <span
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  backgroundColor: "green",
                                  borderRadius: " 0 20px 0 0",
                                }}
                              ></span>
                              <span
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  backgroundColor: "yellow",
                                  borderRadius: "0 0 0 20px",
                                }}
                              ></span>
                              <span
                                style={{
                                  width: "50%",
                                  height: "50%",
                                  backgroundColor: "black",
                                  borderRadius: "0 0 20px 0",
                                }}
                              ></span>
                            </div>
                          ) : (
                            <div
                              key={index}
                              onClick={() => handleColorClick(color)}
                              style={{
                                width: "40px",
                                height: "40px",
                                backgroundColor: color,
                                borderRadius: "50%",
                                cursor: "pointer",
                                border: "2px solid white",
                                margin: "2px",
                              }}
                            ></div>
                          )}
                          <p
                            className="color-tooltip"
                            style={{
                              textTransform: "capitalize",
                              userSelect: "none",
                            }}
                          >
                            {color}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {filterIndex === 6 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      Discount
                    </span>
                    <div
                      className="it-discount-filter"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        alignItems: "start",
                        padding: "8px",
                        marginBottom: "10px",
                        border: "1px solid #f4f3f3",
                        borderRadius: "20px",
                      }}
                    >
                      {offPercentageText.map((offPer, index) => (
                        <div
                          key={index}
                          className="it-discount-btn"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            border: "none",
                            backgroundColor: "white",
                            userSelect: "none",
                          }}
                        >
                          <input
                            type="checkbox"
                            id={`andro-off-${index}`}
                            checked={selectedOffPer === offPer ? true : false}
                            onChange={() => handleOffPerClick(offPer)}
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            htmlFor={`andro-off-${index}`}
                            style={{
                              fontWeight:
                                selectedOffPer === offPer ? "600" : "500",
                              color:
                                selectedOffPer === offPer ? "#007bff" : "black",
                              cursor: "pointer",
                            }}
                          >
                            {offPer}
                          </label>
                        </div>
                      ))}
                    </div>
                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      Offers & deals
                    </span>
                    <div
                      className="it-offers-deals"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        alignItems: "start",
                        padding: "10px",
                        marginBottom: "10px",
                        border: "1px solid #f4f3f3",
                        borderRadius: "20px",
                      }}
                    >
                      {isSunday && (
                        <div
                          className="it-single-offer-tile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            userSelect: "none",
                          }}
                        >
                          <input
                            type="checkbox"
                            id="offer-chkrtpc"
                            checked={selectedDeal === "sunday" ? true : false}
                            onChange={() => handleFilterDealSelect("sunday")}
                          />
                          <label
                            htmlFor="offer-chkrtpc"
                            className="it-offerbtn"
                            style={{
                              fontWeight:
                                selectedDeal === "sunday" ? "600" : "500",
                              color:
                                selectedDeal === "sunday" ? "#007bff" : "black",
                              cursor: "pointer",
                            }}
                          >
                            Sunday sale
                          </label>
                        </div>
                      )}

                      <div
                        className="it-single-offer-tile"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          userSelect: "none",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="offer-chk345px"
                          checked={selectedDeal === "limited" ? true : false}
                          onChange={() => handleFilterDealSelect("limited")}
                        />
                        <label
                          htmlFor="offer-chk345px"
                          className="it-offerbtn"
                          style={{
                            fontWeight:
                              selectedDeal === "limited" ? "600" : "500",
                            color:
                              selectedDeal === "limited" ? "#007bff" : "black",
                            cursor: "pointer",
                          }}
                        >
                          Limited time deals
                        </label>
                      </div>
                      <div
                        className="it-single-offer-tile"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          userSelect: "none",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="offer-chk678g"
                          checked={selectedDeal === "seasonal" ? true : false}
                          onChange={() => handleFilterDealSelect("seasonal")}
                        />
                        <label
                          htmlFor="offer-chk678g"
                          className="it-offerbtn"
                          style={{
                            fontWeight:
                              selectedDeal === "seasonal" ? "600" : "500",
                            color:
                              selectedDeal === "seasonal" ? "#007bff" : "black",
                            cursor: "pointer",
                          }}
                        >
                          Seasonal delas
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <button
              onClick={handleFilterApply}
              style={{
                border: "none",
                backgroundColor: "orange",
                padding: "8px 10px 8px 10px",
                fontSize: "18px",
                fontWeight: "500",
                margin: "15px",
                borderRadius: "20px",
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className={`bottom-sort-overlay ${isSortOpen ? "open" : ""}`}>
          <div
            ref={sortDrawerRef}
            className={`it-sort-drawer ${isSortOpen ? "open" : ""}`}
            style={{
              backgroundColor: "white",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px 20px 0 0",
              marginTop: "100px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="drawer-head"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "18px 12px",
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                Sort by:
              </div>
            </div>
            <div
              className="it-sort-flex"
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "12px",
                margin: "20px",
              }}
            >
              {sortTypes.map((sortTitle, index) => (
                <button
                  key={index * 2.6}
                  className="it-sort-btn"
                  onClick={() => handleSorting(index)}
                  style={{
                    backgroundColor:
                      index === selectedSortInd ? "#0084ff" : "#f0f0f0",
                    color: index === selectedSortInd ? "white" : "black",
                  }}
                >
                  {sortTitle}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <GlobelFooter />
      {isLoading && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

const itemsColorList = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
  "brown",
  "black",
  "white",
  "gray",
  "grey",
  "violet",
  "indigo",
  "cyan",
  "magenta",
  "turquoise",
  "teal",
  "maroon",
  "beige",
  "navy",
  "lavender",
  "gold",
  "silver",
  "bronze",
  "peach",
  "lime",
  "olive",
  "coral",
  "salmon",
  "plum",
  "khaki",
  "chocolate",
  "ivory",
  "azure",
  "aquamarine",
  "mint",
  "crimson",
  "burgundy",
  "sienna",
  "charcoal",
  "emerald",
  "amber",
  "fuchsia",
  "mustard",
  "orchid",
  "periwinkle",
  "rose",
  "cerulean",
  "slate",
  "copper",
  "tan",
  "seafoam",
  "ochre",
  "eggplant",
  "indigo",
  "mauve",
  "ruby",
  "amethyst",
  "jade",
  "sky blue",
  "forest green",
  "midnight blue",
  "sand",
  "blush",
  "scarlet",
  "canary",
  "denim",
  "pastel pink",
  "pastel blue",
  "pastel green",
  "pastel yellow",
  "bubblegum",
  "tangerine",
  "sepia",
  "pistachio",
  "coral pink",
  "light gray",
  "dark gray",
  "light green",
  "dark green",
  "light blue",
  "dark blue",
  "neon green",
  "neon pink",
  "neon yellow",
  "neon orange",
  "baby blue",
  "dusty rose",
  "electric blue",
  "gunmetal",
  "mahogany",
  "sunset orange",
  "brick red",
  "wine",
  "raspberry",
  "lemon",
  "mint green",
  "honey",
  "pearl",
  "cobalt",
  "ivory",
  "flamingo",
  "steel blue",
  "stormy sky",
  "ultramarine",
];

export default Items;
