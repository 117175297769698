import React, { useState, useRef, useEffect } from "react";
import "./home.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import GlobelFooter from "../../widgets/footer";
import CatDrop from "../../widgets/categories";
import MySlider from "../../widgets/slider";

const categories = [
  {
    category: "Men",
    category_image:
      "https://burst.shopifycdn.com/photos/man-in-white-and-light-tan-outfit.jpg?width=1000&format=pjpg&exif=0&iptc=0",
    subcategories: [
      {
        name: "Top wear",
        options: ["T-Shirts", "Shirts", "Suits, Blazers"],
      },
      {
        name: "Bottom wear",
        options: [
          "Jeans",
          "Trousers",
          "Track pants",
          "Formal pants",
          "Shorts",
          "Cargos",
        ],
      },
      {
        name: "Footwear",
        options: [
          "Sports shoes",
          "Casual shoes",
          "Formal shoes",
          "Sandals & Floaters",
          "Flip-Flops",
          "Boots",
          "Sneakers",
        ],
      },
      {
        name: "Grooming",
        options: [
          "Deodorants",
          "Perfumes",
          "Beard special",
          "Grooming kits",
          "Trimmers",
        ],
      },
      {
        name: "Winter wear",
        options: ["Sweatshirts", "Jackets", "Sweater", "Tracksuits"],
      },
      {
        name: "Ethnic wear",
        options: ["Kurta", "Ethnic sets", "Sherwanis", "Dhoti/Lungi"],
      },
      {
        name: "Innerwear",
        options: ["Trunks", "Vests", "Boxers", "Night Suits", "Pyjamas"],
      },
      {
        name: "Accessories",
        options: [
          "Backpacks",
          "Wallets",
          "Watches",
          "Belts",
          "Sunglasses",
          "Travel accessories",
          "Jewellwery",
        ],
      },
    ],
  },
  {
    category: "Women",
    category_image:
      "https://www.shutterstock.com/image-photo/full-length-photo-young-asian-600nw-2201967609.jpg",
    subcategories: [
      {
        name: "Fahion",
        options: [
          "Top wear",
          "Dresses",
          "Jeans",
          "Shorts",
          "Skirts",
          "Trousers",
          "Sports wear",
          "Winter wear",
        ],
      },
      {
        name: "Innerwear",
        options: ["Bras", "Panties", "Lingerie sets", "Night wear"],
      },
      {
        name: "Ethnic wear",
        options: [
          "Sarees",
          "Kurtas",
          "Lehenga choli",
          "Salwar suits",
          "Leggings",
          "Palazzos",
          "Ethnic trousers",
        ],
      },
      {
        name: "Footwear",
        options: [
          "Flats",
          "Sandals",
          "Heels",
          "Sports shoes",
          "Casual shoes",
          "Slippers & Flip-Flops",
        ],
      },
      {
        name: "Beauty & Personal care",
        options: [
          "Make up",
          "Skin care",
          "Hair care",
          "Deodorants & Perfumes",
          "Appliances",
          "Bath & Spa",
        ],
      },
      {
        name: "Accessories",
        options: [
          "Jewellery",
          "Handbags",
          "Wallets & Belts",
          "Travel accessories",
          "Sunglasses",
          "Watches",
        ],
      },
    ],
  },
  {
    category: "Home and Kitchen",
    category_image:
      "https://5.imimg.com/data5/ANDROID/Default/2022/2/SJ/HA/AJ/31543065/product-jpeg-500x500.jpeg",
    subcategories: [
      {
        name: "Furnishing",
        options: [
          "Bedsheets",
          "Curtains",
          "Bath Towels",
          "Blankets",
          "Cushions & Pillows",
          "Floor coverings",
        ],
      },
      {
        name: "Kitchen",
        options: [
          "Pans",
          "Tawas",
          "Pressure cookers",
          "Kitchen tools",
          "Mugs",
          "Dinner set",
          "Lunch Boxes",
          "Water bottles",
          "Containers",
          "Flasks",
          "Cleaning supplies",
        ],
      },
      {
        name: "Decoration",
        options: [
          "Paintings",
          "Clocks",
          "Showpieces & Figurines",
          "Lights & Lamps",
          "Festive decor",
          "Gardening",
        ],
      },
    ],
  },
  {
    category: "Sports and Study",
    category_image: "https://sk-sports.in/wp-content/uploads/2024/08/4.webp",
    subcategories: [
      {
        name: "Sports & Fitness",
        options: [
          "Sports wear",
          "Sports shoes",
          "Fitness accessories",
          "Sports equepments",
        ],
      },
      {
        name: "Books",
        options: [
          "Entrance",
          "Academics",
          "Literature & Fiction",
          "Non fiction",
          "Self-help",
        ],
      },
      {
        name: "Stationery",
        options: ["Pens", "Diaries", "Folders", "Desk organizers"],
      },
      {
        name: "Auto & Instruments",
        options: [
          "Riding gears",
          "Car accessories",
          "Science lab",
          "Packaging",
          "Music instruments",
          "Gaming accessories",
        ],
      },
    ],
  },
];

const HomePage = () => {
  setTitle("SwiftCity");
  const slides = [
    "https://t3.ftcdn.net/jpg/03/16/37/64/360_F_316376413_nYL2jpLONPQPOsy31DE86n7FPpSxPIi3.jpg",
  ];

  return (
    <>
      <GlobelHeader top={"0"} isMobLocSel={true} />
      <div>
        <CatDrop />
        <MySlider slides={slides} />
        <div className="hmmob-categ">
          {categories.map((categor, index) => (
            <div
              key={index}
              className="hmmob-tile"
              onClick={() =>
                (window.location.href = `/categories?category=${categor.category}`)
              }
            >
              <img src={categor.category_image} alt="hm-cato" />
              <span
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {categor.category}
              </span>
            </div>
          ))}
        </div>
        <div className="hm-sec-box-row">
          <div className="hm-home-sec">
            <div className="hm-sec-head">
              <h2>Some text</h2>
              <button className="hm-sec-head-bt">&#10095;</button>
            </div>
            <div className="hm-sec-body-grid">
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1u6jdFJrttI8l9VulR7Zs9G2lL84SFWZCAg&s"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Smart watches</p>
                  <h6>Min 60% off</h6>
                </div>
              </div>
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://www.jiomart.com/images/product/original/rvhszur1oy/scizzers-professional-hair-clipper-for-men-waterproof-hair-trimmer-and-precision-clipper-cordless-rechargeable-barber-electric-beard-shaver-1mm-t-blade-beard-trimmer-product-images-orvhszur1oy-p598881865-0-202302271243.jpg?im=Resize=(1000,1000)"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Trimmers</p>
                  <h6>Min 50% off</h6>
                </div>
              </div>
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://images.naptol.com/usr/local/csp/staticContent/product_images/horizontal/750x750/3-Austrian-Diamond-Jewellery-Sets-(3AUD1)-o2.jpg"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Women Jewellery</p>
                  <h6>Min 70% off</h6>
                </div>
              </div>
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8PXMhEwieenVb0q-ZCZHrQANSDsRApOKwmQ&s"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Mobile Covers</p>
                  <h6>Starting ₹99</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="hm-offer-banner">
            <img
              style={{
                width: "100%",
                aspectRatio: "1/0.88",
                objectFit: "cover",
              }}
              src="https://www.hopscotch.in/blog/wp-content/uploads/2019/12/3j7k36.gif"
              alt=""
            />
          </div>
          <div className="hm-home-sec">
            <div className="hm-sec-head">
              <h2>Some text</h2>
              <button className="hm-sec-head-bt">&#10095;</button>
            </div>
            <div className="hm-sec-body-grid">
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://www.tracerindia.com/cdn/shop/files/01_1_699e3a3a-ba7c-439e-b258-6e57413834be.jpg?v=1688469238"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Casual shoes</p>
                  <h6>Min 60% off</h6>
                </div>
              </div>
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://www.jiomart.com/images/product/original/rvy5osgs3d/gulmeren-women-flats-fashion-slippers-stylish-model-flat-casual-daily-use-product-images-rvy5osgs3d-0-202311061829.jpg?im=Resize=(500,630)"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Flats</p>
                  <h6>Min 80% off</h6>
                </div>
              </div>
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://m.media-amazon.com/images/I/51hIyJRz9BL.jpg"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Water bottles</p>
                  <h6>Starting 299</h6>
                </div>
              </div>
              <div className="hm-sec-body-grid-tile">
                <img
                  src="https://images-static.nykaa.com/media/catalog/product/7/7/77c86abLWDB16174099_5.jpg?tr=w-500"
                  alt=""
                />
                <div className="hm-sec-foot">
                  <p>Gym bags</p>
                  <h6>Min 30% off</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobelFooter />
    </>
  );
};

export default HomePage;
