import React, { useEffect, useState } from "react";
import "./widgets.css";
import SwipeUpIcon from "@mui/icons-material/SwipeUp";

const GlobelFooter = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 4000) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "auto",
      }}
    >
      {showButton && (
        <button
          className="gftr-scroll-top"
          onClick={scrollToTop}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
            color: "white",
            border: "none",
            borderRadius: "50%",
            margin: "12px 12px 12px auto",
          }}
        >
          <SwipeUpIcon
            sx={{
              fontSize: 28,
              color: "#fff",
            }}
          />
        </button>
      )}
      <div className="globel-swift-footer">
        <div className="gftr-tile">
          <div className="gftr-fo0Ch">
            <div className="gftr-foLd"></div>
            <span>Social Media</span>
          </div>
          <button className="gftr-f7otBt">Facebook</button>
          <button className="gftr-f7otBt">WhatsApp</button>
          <button className="gftr-f7otBt">YouTube</button>
          <button className="gftr-f7otBt">Instagram</button>{" "}
        </div>
        <div className="gftr-tile">
          <div className="gftr-fo0Ch">
            <div className="gftr-foLd"></div>
            <span>Useful links</span>
          </div>
          <button className="gftr-f7otBt">Download app</button>
          <button className="gftr-f7otBt">About us</button>
          <button className="gftr-f7otBt">Contact us</button>
          <button className="gftr-f7otBt">Become a seller</button>
          <button className="gftr-f7otBt">FAQ</button>
          <button className="gftr-f7otBt">Help</button>
        </div>
        <div className="gftr-tile">
          <div className="gftr-fo0Ch">
            <div className="gftr-foLd"></div>
            <span>Consumer policy</span>
          </div>
          <button className="gftr-f7otBt">Terms of use</button>
          <button className="gftr-f7otBt">Privacy policy</button>
          <button className="gftr-f7otBt">Return & Refund</button>
        </div>
        <div className="gftr-divider"></div>
        <div className="gft-addrr-tile">
          <div className="gftr-fo0Ch">
            <div className="gftr-foLd"></div>
            <span>Mail us:</span>
          </div>
          <div className="gftr-address">
            SwiftCity ,
            <br />
            Village Ojal, Mashobra, District Shimla, Himachal Pradesh, 171007,
            <br />
            CIN:
            <br />
            E-mail address: swiftcity309@gmial.com
            <br />
            Telephone: +918580573129, +918219055119
          </div>
        </div>
      </div>
      <div className="gftr-copyright-container">© 2024-2025 SwiftCity.in</div>
    </div>
  );
};

export default GlobelFooter;
