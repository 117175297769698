/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSeasonalOffer = /* GraphQL */ `
  mutation CreateSeasonalOffer(
    $input: CreateSeasonalOfferInput!
    $condition: ModelSeasonalOfferConditionInput
  ) {
    createSeasonalOffer(input: $input, condition: $condition) {
      id
      offer_type
      offer_description
      offer_price
      offer_title
      offer_img
      is_applied
      starting_time
      ending_time
      offer_discount
      offer_banner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeasonalOffer = /* GraphQL */ `
  mutation UpdateSeasonalOffer(
    $input: UpdateSeasonalOfferInput!
    $condition: ModelSeasonalOfferConditionInput
  ) {
    updateSeasonalOffer(input: $input, condition: $condition) {
      id
      offer_type
      offer_description
      offer_price
      offer_title
      offer_img
      is_applied
      starting_time
      ending_time
      offer_discount
      offer_banner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeasonalOffer = /* GraphQL */ `
  mutation DeleteSeasonalOffer(
    $input: DeleteSeasonalOfferInput!
    $condition: ModelSeasonalOfferConditionInput
  ) {
    deleteSeasonalOffer(input: $input, condition: $condition) {
      id
      offer_type
      offer_description
      offer_price
      offer_title
      offer_img
      is_applied
      starting_time
      ending_time
      offer_discount
      offer_banner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeller = /* GraphQL */ `
  mutation CreateSeller(
    $input: CreateSellerInput!
    $condition: ModelSellerConditionInput
  ) {
    createSeller(input: $input, condition: $condition) {
      id
      banner_img
      followers_count
      products
      p_count
      profile_img
      ratings
      first_name
      last_name
      short_name
      seller_id
      shop_name
      verified
      pickup_locations {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeller = /* GraphQL */ `
  mutation UpdateSeller(
    $input: UpdateSellerInput!
    $condition: ModelSellerConditionInput
  ) {
    updateSeller(input: $input, condition: $condition) {
      id
      banner_img
      followers_count
      products
      p_count
      profile_img
      ratings
      first_name
      last_name
      short_name
      seller_id
      shop_name
      verified
      pickup_locations {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeller = /* GraphQL */ `
  mutation DeleteSeller(
    $input: DeleteSellerInput!
    $condition: ModelSellerConditionInput
  ) {
    deleteSeller(input: $input, condition: $condition) {
      id
      banner_img
      followers_count
      products
      p_count
      profile_img
      ratings
      first_name
      last_name
      short_name
      seller_id
      shop_name
      verified
      pickup_locations {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      approved
      images
      listing_id
      is_review
      rating
      review_title
      review_desc
      review_time
      user_id
      user_img
      user_name
      likes_count
      dislike_count
      reports_count
      type
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      approved
      images
      listing_id
      is_review
      rating
      review_title
      review_desc
      review_time
      user_id
      user_img
      user_name
      likes_count
      dislike_count
      reports_count
      type
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      approved
      images
      listing_id
      is_review
      rating
      review_title
      review_desc
      review_time
      user_id
      user_img
      user_name
      likes_count
      dislike_count
      reports_count
      type
      createdAt
      id
      updatedAt
      __typename
    }
  }
`;
export const createUserApplication = /* GraphQL */ `
  mutation CreateUserApplication(
    $input: CreateUserApplicationInput!
    $condition: ModelUserApplicationConditionInput
  ) {
    createUserApplication(input: $input, condition: $condition) {
      id
      app_version
      web_version
      search_sugg
      seasonal_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      categories {
        category
        category_image
        subcategories {
          name
          subcat_image
          options
          option_images
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserApplication = /* GraphQL */ `
  mutation UpdateUserApplication(
    $input: UpdateUserApplicationInput!
    $condition: ModelUserApplicationConditionInput
  ) {
    updateUserApplication(input: $input, condition: $condition) {
      id
      app_version
      web_version
      search_sugg
      seasonal_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      categories {
        category
        category_image
        subcategories {
          name
          subcat_image
          options
          option_images
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserApplication = /* GraphQL */ `
  mutation DeleteUserApplication(
    $input: DeleteUserApplicationInput!
    $condition: ModelUserApplicationConditionInput
  ) {
    deleteUserApplication(input: $input, condition: $condition) {
      id
      app_version
      web_version
      search_sugg
      seasonal_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      categories {
        category
        category_image
        subcategories {
          name
          subcat_image
          options
          option_images
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      user_addresses {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        region
        country
        adding_time
        additional_info
        phone
        additional_phone
        first_name
        last_name
        short_name
        full_name
        address_type
        delivery_days
        delivery_timing
        is_default_address
        __typename
      }
      user_cart {
        adding_time
        listing_id
        variation_type
        variation_title
        measurement_type
        selected_measurement
        quantity
        is_gift
        color
        gift_message
        gift_type
        covering
        deliver_gift_on
        gift_sender
        __typename
      }
      user_followings
      user_orders {
        swift_order_no
        swift_order_id
        order_awb
        delivery_service
        shipping_address {
          geo_point
          flat_house
          area_street
          landmark
          pincode
          town
          city
          state
          region
          country
          adding_time
          additional_info
          phone
          additional_phone
          first_name
          last_name
          short_name
          full_name
          address_type
          delivery_days
          delivery_timing
          is_default_address
          __typename
        }
        billing_address {
          geo_point
          flat_house
          area_street
          landmark
          pincode
          town
          city
          state
          region
          country
          adding_time
          additional_info
          phone
          additional_phone
          first_name
          last_name
          short_name
          full_name
          address_type
          delivery_days
          delivery_timing
          is_default_address
          __typename
        }
        operation_details {
          operation_time
          operation_stage
          operation_step
          operation_desc
          operation_progress
          operation_stages_count
          __typename
        }
        order_items {
          order_time
          listing_id
          listing_sku
          listing_images
          variation_type
          variation_title
          measurement_type
          selected_measurement
          order_mrp
          order_price
          single_order_type
          delivery_type
          listing_title
          listing_seller
          seller_id
          listing_brand
          color
          quantity
          is_gift
          gift_message
          gift_type
          covering
          deliver_gift_on
          gift_sender
          pickup_locations {
            geo_point
            flat_house
            area_street
            landmark
            pincode
            town
            city
            state
            __typename
          }
          __typename
        }
        order_time
        order_type
        customer_details {
          full_name
          short_name
          first_name
          last_name
          profile_name
          profile_phone
          profile_email
          profile_image
          __typename
        }
        payment_details {
          payment_method
          payment_type
          payment_from
          payee_name
          reciver_name
          swift_transaction_id
          payment_transaction_id
          __typename
        }
        createdAt
        __typename
      }
      user_account {
        user_email
        user_phone_number
        user_profile_image
        user_first_name
        user_last_name
        user_short_name
        user_full_name
        user_used_emails
        user_used_phone_numbers
        __typename
      }
      user_viewed_items
      user_reviewed
      user_searchs
      user_liked_items
      user_saved_items
      user_notifications
      order_draft {
        create_time
        listing_id
        variation_type
        variation_title
        measurement_type
        selected_measurement
        quantity
        is_gift
        gift_message
        gift_type
        covering
        deliver_gift_on
        gift_sender
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      user_addresses {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        region
        country
        adding_time
        additional_info
        phone
        additional_phone
        first_name
        last_name
        short_name
        full_name
        address_type
        delivery_days
        delivery_timing
        is_default_address
        __typename
      }
      user_cart {
        adding_time
        listing_id
        variation_type
        variation_title
        measurement_type
        selected_measurement
        quantity
        is_gift
        color
        gift_message
        gift_type
        covering
        deliver_gift_on
        gift_sender
        __typename
      }
      user_followings
      user_orders {
        swift_order_no
        swift_order_id
        order_awb
        delivery_service
        shipping_address {
          geo_point
          flat_house
          area_street
          landmark
          pincode
          town
          city
          state
          region
          country
          adding_time
          additional_info
          phone
          additional_phone
          first_name
          last_name
          short_name
          full_name
          address_type
          delivery_days
          delivery_timing
          is_default_address
          __typename
        }
        billing_address {
          geo_point
          flat_house
          area_street
          landmark
          pincode
          town
          city
          state
          region
          country
          adding_time
          additional_info
          phone
          additional_phone
          first_name
          last_name
          short_name
          full_name
          address_type
          delivery_days
          delivery_timing
          is_default_address
          __typename
        }
        operation_details {
          operation_time
          operation_stage
          operation_step
          operation_desc
          operation_progress
          operation_stages_count
          __typename
        }
        order_items {
          order_time
          listing_id
          listing_sku
          listing_images
          variation_type
          variation_title
          measurement_type
          selected_measurement
          order_mrp
          order_price
          single_order_type
          delivery_type
          listing_title
          listing_seller
          seller_id
          listing_brand
          color
          quantity
          is_gift
          gift_message
          gift_type
          covering
          deliver_gift_on
          gift_sender
          pickup_locations {
            geo_point
            flat_house
            area_street
            landmark
            pincode
            town
            city
            state
            __typename
          }
          __typename
        }
        order_time
        order_type
        customer_details {
          full_name
          short_name
          first_name
          last_name
          profile_name
          profile_phone
          profile_email
          profile_image
          __typename
        }
        payment_details {
          payment_method
          payment_type
          payment_from
          payee_name
          reciver_name
          swift_transaction_id
          payment_transaction_id
          __typename
        }
        createdAt
        __typename
      }
      user_account {
        user_email
        user_phone_number
        user_profile_image
        user_first_name
        user_last_name
        user_short_name
        user_full_name
        user_used_emails
        user_used_phone_numbers
        __typename
      }
      user_viewed_items
      user_reviewed
      user_searchs
      user_liked_items
      user_saved_items
      user_notifications
      order_draft {
        create_time
        listing_id
        variation_type
        variation_title
        measurement_type
        selected_measurement
        quantity
        is_gift
        gift_message
        gift_type
        covering
        deliver_gift_on
        gift_sender
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      user_addresses {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        region
        country
        adding_time
        additional_info
        phone
        additional_phone
        first_name
        last_name
        short_name
        full_name
        address_type
        delivery_days
        delivery_timing
        is_default_address
        __typename
      }
      user_cart {
        adding_time
        listing_id
        variation_type
        variation_title
        measurement_type
        selected_measurement
        quantity
        is_gift
        color
        gift_message
        gift_type
        covering
        deliver_gift_on
        gift_sender
        __typename
      }
      user_followings
      user_orders {
        swift_order_no
        swift_order_id
        order_awb
        delivery_service
        shipping_address {
          geo_point
          flat_house
          area_street
          landmark
          pincode
          town
          city
          state
          region
          country
          adding_time
          additional_info
          phone
          additional_phone
          first_name
          last_name
          short_name
          full_name
          address_type
          delivery_days
          delivery_timing
          is_default_address
          __typename
        }
        billing_address {
          geo_point
          flat_house
          area_street
          landmark
          pincode
          town
          city
          state
          region
          country
          adding_time
          additional_info
          phone
          additional_phone
          first_name
          last_name
          short_name
          full_name
          address_type
          delivery_days
          delivery_timing
          is_default_address
          __typename
        }
        operation_details {
          operation_time
          operation_stage
          operation_step
          operation_desc
          operation_progress
          operation_stages_count
          __typename
        }
        order_items {
          order_time
          listing_id
          listing_sku
          listing_images
          variation_type
          variation_title
          measurement_type
          selected_measurement
          order_mrp
          order_price
          single_order_type
          delivery_type
          listing_title
          listing_seller
          seller_id
          listing_brand
          color
          quantity
          is_gift
          gift_message
          gift_type
          covering
          deliver_gift_on
          gift_sender
          pickup_locations {
            geo_point
            flat_house
            area_street
            landmark
            pincode
            town
            city
            state
            __typename
          }
          __typename
        }
        order_time
        order_type
        customer_details {
          full_name
          short_name
          first_name
          last_name
          profile_name
          profile_phone
          profile_email
          profile_image
          __typename
        }
        payment_details {
          payment_method
          payment_type
          payment_from
          payee_name
          reciver_name
          swift_transaction_id
          payment_transaction_id
          __typename
        }
        createdAt
        __typename
      }
      user_account {
        user_email
        user_phone_number
        user_profile_image
        user_first_name
        user_last_name
        user_short_name
        user_full_name
        user_used_emails
        user_used_phone_numbers
        __typename
      }
      user_viewed_items
      user_reviewed
      user_searchs
      user_liked_items
      user_saved_items
      user_notifications
      order_draft {
        create_time
        listing_id
        variation_type
        variation_title
        measurement_type
        selected_measurement
        quantity
        is_gift
        gift_message
        gift_type
        covering
        deliver_gift_on
        gift_sender
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createListing = /* GraphQL */ `
  mutation CreateListing(
    $input: CreateListingInput!
    $condition: ModelListingConditionInput
  ) {
    createListing(input: $input, condition: $condition) {
      id
      p_title
      p_highlights {
        keys
        values
        __typename
      }
      p_desc
      p_rating
      p_brand
      p_warranty
      p_replace_time
      p_return_period
      p_delivery_type
      p_category
      p_subcategory
      p_verifier_id
      p_seller_listing_id
      p_seller_id
      p_ratings_count
      p_ratings_type_count {
        five
        four
        three
        two
        one
        __typename
      }
      p_reviews_count
      p_total_sold
      p_sku
      p_uid
      p_similar_id
      p_variation_id
      p_featured
      p_feature_priority
      p_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      p_swiftcity_special
      p_visibility
      p_weight
      p_variation_title
      p_variation_type
      p_city_codes
      p_default_variation
      p_image_paths
      p_image_urls
      p_dimensions {
        p_width
        p_height
        p_length
        p_thikness
        p_diameter
        __typename
      }
      p_video_paths
      p_measurements_type
      p_on_cod
      p_measurements {
        p_measurement
        p_mrp
        p_price
        p_qty
        __typename
      }
      p_search_tag
      p_desc_images
      p_default_mrp
      p_default_price
      p_default_off
      p_multi_pack_id
      p_verification_time
      p_gender
      p_color
      p_material
      p_style
      p_filter_measurments
      p_filter_variations
      p_about
      p_additional_info {
        keys
        values
        __typename
      }
      p_country_of_origin
      p_manufacturer
      p_seasonaloffer
      p_sundaysale
      p_limited_sale
      p_exclusive
      p_customisable
      p_customize_attributes {
        text_value_one
        text_value_two
        text_value_three
        int_value_one
        int_value_two
        single_image
        multiple_images
        data_map {
          string_value
          __typename
        }
        __typename
      }
      p_string_field_one
      p_string_field_two
      p_string_field_three
      p_int_field_one
      p_int_field_two
      p_int_field_three
      p_map_field_one {
        string_value
        __typename
      }
      p_map_field_two {
        string_value
        __typename
      }
      p_list_field_one
      p_list_field_two
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateListing = /* GraphQL */ `
  mutation UpdateListing(
    $input: UpdateListingInput!
    $condition: ModelListingConditionInput
  ) {
    updateListing(input: $input, condition: $condition) {
      id
      p_title
      p_highlights {
        keys
        values
        __typename
      }
      p_desc
      p_rating
      p_brand
      p_warranty
      p_replace_time
      p_return_period
      p_delivery_type
      p_category
      p_subcategory
      p_verifier_id
      p_seller_listing_id
      p_seller_id
      p_ratings_count
      p_ratings_type_count {
        five
        four
        three
        two
        one
        __typename
      }
      p_reviews_count
      p_total_sold
      p_sku
      p_uid
      p_similar_id
      p_variation_id
      p_featured
      p_feature_priority
      p_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      p_swiftcity_special
      p_visibility
      p_weight
      p_variation_title
      p_variation_type
      p_city_codes
      p_default_variation
      p_image_paths
      p_image_urls
      p_dimensions {
        p_width
        p_height
        p_length
        p_thikness
        p_diameter
        __typename
      }
      p_video_paths
      p_measurements_type
      p_on_cod
      p_measurements {
        p_measurement
        p_mrp
        p_price
        p_qty
        __typename
      }
      p_search_tag
      p_desc_images
      p_default_mrp
      p_default_price
      p_default_off
      p_multi_pack_id
      p_verification_time
      p_gender
      p_color
      p_material
      p_style
      p_filter_measurments
      p_filter_variations
      p_about
      p_additional_info {
        keys
        values
        __typename
      }
      p_country_of_origin
      p_manufacturer
      p_seasonaloffer
      p_sundaysale
      p_limited_sale
      p_exclusive
      p_customisable
      p_customize_attributes {
        text_value_one
        text_value_two
        text_value_three
        int_value_one
        int_value_two
        single_image
        multiple_images
        data_map {
          string_value
          __typename
        }
        __typename
      }
      p_string_field_one
      p_string_field_two
      p_string_field_three
      p_int_field_one
      p_int_field_two
      p_int_field_three
      p_map_field_one {
        string_value
        __typename
      }
      p_map_field_two {
        string_value
        __typename
      }
      p_list_field_one
      p_list_field_two
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteListing = /* GraphQL */ `
  mutation DeleteListing(
    $input: DeleteListingInput!
    $condition: ModelListingConditionInput
  ) {
    deleteListing(input: $input, condition: $condition) {
      id
      p_title
      p_highlights {
        keys
        values
        __typename
      }
      p_desc
      p_rating
      p_brand
      p_warranty
      p_replace_time
      p_return_period
      p_delivery_type
      p_category
      p_subcategory
      p_verifier_id
      p_seller_listing_id
      p_seller_id
      p_ratings_count
      p_ratings_type_count {
        five
        four
        three
        two
        one
        __typename
      }
      p_reviews_count
      p_total_sold
      p_sku
      p_uid
      p_similar_id
      p_variation_id
      p_featured
      p_feature_priority
      p_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      p_swiftcity_special
      p_visibility
      p_weight
      p_variation_title
      p_variation_type
      p_city_codes
      p_default_variation
      p_image_paths
      p_image_urls
      p_dimensions {
        p_width
        p_height
        p_length
        p_thikness
        p_diameter
        __typename
      }
      p_video_paths
      p_measurements_type
      p_on_cod
      p_measurements {
        p_measurement
        p_mrp
        p_price
        p_qty
        __typename
      }
      p_search_tag
      p_desc_images
      p_default_mrp
      p_default_price
      p_default_off
      p_multi_pack_id
      p_verification_time
      p_gender
      p_color
      p_material
      p_style
      p_filter_measurments
      p_filter_variations
      p_about
      p_additional_info {
        keys
        values
        __typename
      }
      p_country_of_origin
      p_manufacturer
      p_seasonaloffer
      p_sundaysale
      p_limited_sale
      p_exclusive
      p_customisable
      p_customize_attributes {
        text_value_one
        text_value_two
        text_value_three
        int_value_one
        int_value_two
        single_image
        multiple_images
        data_map {
          string_value
          __typename
        }
        __typename
      }
      p_string_field_one
      p_string_field_two
      p_string_field_three
      p_int_field_one
      p_int_field_two
      p_int_field_three
      p_map_field_one {
        string_value
        __typename
      }
      p_map_field_two {
        string_value
        __typename
      }
      p_list_field_one
      p_list_field_two
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSellerListing = /* GraphQL */ `
  mutation CreateSellerListing(
    $input: CreateSellerListingInput!
    $condition: ModelSellerListingConditionInput
  ) {
    createSellerListing(input: $input, condition: $condition) {
      id
      p_title
      p_highlights {
        keys
        values
        __typename
      }
      p_desc
      p_brand
      p_warranty
      p_replace_time
      p_return_period
      p_category
      p_subcategory
      p_seller_id
      p_sku
      p_variation_id
      p_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      p_visibility
      p_status
      p_notice
      p_variation_title
      p_variation_type
      p_weight
      p_default_variation
      p_image_paths
      p_image_urls
      p_dimensions {
        p_width
        p_height
        p_length
        p_thikness
        p_diameter
        __typename
      }
      p_video_paths
      p_measurements_type
      p_measurements {
        p_measurement
        p_mrp
        p_price
        p_qty
        __typename
      }
      p_pickup_locations {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        __typename
      }
      p_tags
      p_on_cod
      p_desc_images
      p_multiple_pack_id
      p_creation_time
      p_multi_pack_id
      p_gender
      p_color
      p_material
      p_style
      p_about
      p_additional_info {
        keys
        values
        __typename
      }
      p_country_of_origin
      p_manufacturer
      p_customisable
      p_customize_attributes {
        text_value_one
        text_value_two
        text_value_three
        int_value_one
        int_value_two
        single_image
        multiple_images
        data_map {
          string_value
          __typename
        }
        __typename
      }
      p_string_field_one
      p_string_field_two
      p_string_field_three
      p_int_field_one
      p_int_field_two
      p_int_field_three
      p_map_field_one {
        string_value
        __typename
      }
      p_map_field_two {
        string_value
        __typename
      }
      p_list_field_one
      p_list_field_two
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSellerListing = /* GraphQL */ `
  mutation UpdateSellerListing(
    $input: UpdateSellerListingInput!
    $condition: ModelSellerListingConditionInput
  ) {
    updateSellerListing(input: $input, condition: $condition) {
      id
      p_title
      p_highlights {
        keys
        values
        __typename
      }
      p_desc
      p_brand
      p_warranty
      p_replace_time
      p_return_period
      p_category
      p_subcategory
      p_seller_id
      p_sku
      p_variation_id
      p_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      p_visibility
      p_status
      p_notice
      p_variation_title
      p_variation_type
      p_weight
      p_default_variation
      p_image_paths
      p_image_urls
      p_dimensions {
        p_width
        p_height
        p_length
        p_thikness
        p_diameter
        __typename
      }
      p_video_paths
      p_measurements_type
      p_measurements {
        p_measurement
        p_mrp
        p_price
        p_qty
        __typename
      }
      p_pickup_locations {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        __typename
      }
      p_tags
      p_on_cod
      p_desc_images
      p_multiple_pack_id
      p_creation_time
      p_multi_pack_id
      p_gender
      p_color
      p_material
      p_style
      p_about
      p_additional_info {
        keys
        values
        __typename
      }
      p_country_of_origin
      p_manufacturer
      p_customisable
      p_customize_attributes {
        text_value_one
        text_value_two
        text_value_three
        int_value_one
        int_value_two
        single_image
        multiple_images
        data_map {
          string_value
          __typename
        }
        __typename
      }
      p_string_field_one
      p_string_field_two
      p_string_field_three
      p_int_field_one
      p_int_field_two
      p_int_field_three
      p_map_field_one {
        string_value
        __typename
      }
      p_map_field_two {
        string_value
        __typename
      }
      p_list_field_one
      p_list_field_two
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSellerListing = /* GraphQL */ `
  mutation DeleteSellerListing(
    $input: DeleteSellerListingInput!
    $condition: ModelSellerListingConditionInput
  ) {
    deleteSellerListing(input: $input, condition: $condition) {
      id
      p_title
      p_highlights {
        keys
        values
        __typename
      }
      p_desc
      p_brand
      p_warranty
      p_replace_time
      p_return_period
      p_category
      p_subcategory
      p_seller_id
      p_sku
      p_variation_id
      p_offers {
        offer_type
        offer_description
        offer_price
        offer_title
        offer_img
        is_applied
        starting_time
        ending_time
        offer_discount
        offer_banner
        __typename
      }
      p_visibility
      p_status
      p_notice
      p_variation_title
      p_variation_type
      p_weight
      p_default_variation
      p_image_paths
      p_image_urls
      p_dimensions {
        p_width
        p_height
        p_length
        p_thikness
        p_diameter
        __typename
      }
      p_video_paths
      p_measurements_type
      p_measurements {
        p_measurement
        p_mrp
        p_price
        p_qty
        __typename
      }
      p_pickup_locations {
        geo_point
        flat_house
        area_street
        landmark
        pincode
        town
        city
        state
        __typename
      }
      p_tags
      p_on_cod
      p_desc_images
      p_multiple_pack_id
      p_creation_time
      p_multi_pack_id
      p_gender
      p_color
      p_material
      p_style
      p_about
      p_additional_info {
        keys
        values
        __typename
      }
      p_country_of_origin
      p_manufacturer
      p_customisable
      p_customize_attributes {
        text_value_one
        text_value_two
        text_value_three
        int_value_one
        int_value_two
        single_image
        multiple_images
        data_map {
          string_value
          __typename
        }
        __typename
      }
      p_string_field_one
      p_string_field_two
      p_string_field_three
      p_int_field_one
      p_int_field_two
      p_int_field_three
      p_map_field_one {
        string_value
        __typename
      }
      p_map_field_two {
        string_value
        __typename
      }
      p_list_field_one
      p_list_field_two
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
