import React, { useState, useContext, useEffect } from "react";
import "./my-addresses.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import CatDrop from "../../widgets/categories";
import GlobelFooter from "../../widgets/footer";

const MyAddresses = () => {
  return (
    <>
      <GlobelHeader />
      <CatDrop /> <GlobelFooter />
    </>
  );
};

export default MyAddresses;
