import React, { useState, useContext, useEffect } from "react";
import "./cart.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import CatDrop from "../../widgets/categories";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import { UserContext } from "../../userContext";
import { getListing } from "../../graphql/queries";
import { updateUser } from "../../graphql/mutations";
import { getUrl } from "aws-amplify/storage";
import Snackbar from "@mui/material/Snackbar";
import GlobelFooter from "../../widgets/footer";
import { Fade } from "@mui/material";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";

const Cart = () => {
  const { client, user, loading, fetchUserData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [cartList, setCartList] = useState([]);
  const [cartImgs, setCartImgs] = useState([]);
  const [toastText, setToast] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [cartSubtotal, setCartSubtotal] = useState(0);
  const [cartTotalItems, setCartTotalItems] = useState(0);
  const [cartTotalQtys, setCartTotalQtys] = useState(0);
  const [cartTotalMrp, setCartTotalMrp] = useState(0);
  const [cartTotalDiscount, setCartTotalDiscount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  setTitle("Shopping cart - SwiftCity");
  const formatIndianNumber = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const handleSnackClose = () => {
    setShowToast(false);
  };

  const getOffPers = (mrp, price) => {
    const off = ((mrp - price) / mrp) * 100;
    if (off) {
      return parseInt(off);
    } else {
      return 0;
    }
  };

  const getCartMeasureInd = (mesList, selMes) => {
    let firstInd = mesList.findIndex((item) => item.p_measurement === selMes);
    if (firstInd === -1) {
      firstInd = 0;
    }
    return firstInd;
  };

  const setOrderDraft = async () => {
    setIsLoading(true);
    const date = new Date();
    const awsDateTime = date.toISOString();
    let normalizedData = cartList.map((doc, index) => ({
      create_time: awsDateTime,
      listing_id: doc.id,
      variation_type: user.user_cart[index].variation_type,
      variation_title: user.user_cart[index].variation_title,
      measurement_type: user.user_cart[index].measurement_type,
      selected_measurement: user.user_cart[index].selected_measurement,
      quantity: user.user_cart[index].quantity,
      is_gift: doc.is_gift,
      gift_message: "",
      gift_type: "none",
      covering: "none",
      deliver_gift_on: awsDateTime,
      gift_sender: "none",
    }));
    try {
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
            order_draft: normalizedData,
          },
        },
      });
      fetchUserData();
    } catch (error) {
      console.error(
        "Error updating order:",
        error.response?.errors || error.message || error
      );
    } finally {
      setIsLoading(false);
      window.location.href = "/checkout";
    }
  };

  const getCartItems = async () => {
    setIsLoading(true);
    try {
      let itemsList = await Promise.all(
        user.user_cart.map(async (cartItem) => {
          const listing = await getSingleListing(cartItem.listing_id);
          return listing;
        })
      );
      console.log(itemsList);
      const imgUrls = await Promise.all(
        itemsList.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      let cartMrp = 0;
      let cartPrice = 0;
      let cartQty = 0;
      let cartWeight = 0;
      let cartCharges = 0;
      itemsList.forEach((item, index) => {
        const mesInd = getCartMeasureInd(
          item.p_measurements,
          user.user_cart[index].selected_measurement
        );
        if (item.p_measurements[mesInd].p_qty > 5) {
          cartMrp += item.p_measurements[mesInd].p_mrp;
          cartPrice += item.p_measurements[mesInd].p_price;
        }
        cartQty += user.user_cart[index].quantity;
        cartWeight += item.p_weight * user.user_cart[index].quantity;
        itemsList[index].is_gift = false;
      });
      if (cartWeight < 500) {
        cartCharges = 49;
      } else if (cartWeight >= 500 && cartWeight < 1000) {
        cartCharges = 79;
      } else if (cartWeight >= 1000 && cartWeight < 1500) {
        cartCharges = 99;
      } else if (cartWeight >= 1500 && cartWeight < 2000) {
        cartCharges = 149;
      } else if (cartWeight >= 2000) {
        cartCharges = 199;
      }
      setCartList(itemsList);
      setCartImgs(imgUrls);
      setCartSubtotal(cartPrice);
      setCartTotalItems(itemsList.length);
      setCartTotalQtys(cartQty);
      setCartTotalMrp(cartMrp);
      setCartTotalDiscount(getOffPers(cartMrp, cartPrice));
      setDeliveryCharges(cartCharges);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  const minItemQty = async (qty, ind) => {
    setIsLoading(true);
    if (qty > 1) {
      let userCartList = user.user_cart;
      let normalizedData = userCartList.map((doc) => ({
        adding_time: doc.adding_time,
        listing_id: doc.listing_id,
        variation_type: doc.variation_type,
        variation_title: doc.variation_title,
        measurement_type: doc.measurement_type,
        selected_measurement: doc.selected_measurement,
        quantity: doc.quantity,
        is_gift: doc.is_gift,
        color: doc.color,
        gift_message: doc.gift_message,
        gift_type: doc.gift_type,
        covering: doc.covering,
        deliver_gift_on: doc.deliver_gift_on,
        gift_sender: doc.gift_sender,
      }));
      normalizedData[ind].quantity = qty - 1;
      try {
        await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
              user_cart: normalizedData,
            },
          },
        });
        fetchUserData();
      } catch (error) {
        console.error(
          "Error updating cart:",
          error.response?.errors || error.message || error
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setToast("At least 1 unit is required.");
      setShowToast(true);
    }
  };

  const plusItemQty = async (qty, ind) => {
    setIsLoading(true);
    if (qty < 10) {
      let userCartList = user?.user_cart ?? [];
      let normalizedData = userCartList.map((doc) => ({
        adding_time: doc.adding_time,
        listing_id: doc.listing_id,
        variation_type: doc.variation_type,
        variation_title: doc.variation_title,
        measurement_type: doc.measurement_type,
        selected_measurement: doc.selected_measurement,
        quantity: doc.quantity,
        is_gift: doc.is_gift,
        color: doc.color,
        gift_message: doc.gift_message,
        gift_type: doc.gift_type,
        covering: doc.covering,
        deliver_gift_on: doc.deliver_gift_on,
        gift_sender: doc.gift_sender,
      }));
      normalizedData[ind].quantity = qty + 1;
      try {
        await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
              user_cart: normalizedData,
            },
          },
        });
        fetchUserData();
      } catch (error) {
        console.error(
          "Error updating cart:",
          error.response?.errors || error.message || error
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setToast("Maximum of 10 units allowed per item.");
      setShowToast(true);
    }
  };

  const removeCartItem = async (itemId) => {
    let userCartList = user.user_cart;
    let normalizedData = userCartList.map((doc) => ({
      adding_time: doc.adding_time,
      listing_id: doc.listing_id,
      variation_type: doc.variation_type,
      variation_title: doc.variation_title,
      measurement_type: doc.measurement_type,
      selected_measurement: doc.selected_measurement,
      quantity: doc.quantity,
      is_gift: doc.is_gift,
      color: doc.color,
      gift_message: doc.gift_message,
      gift_type: doc.gift_type,
      covering: doc.covering,
      deliver_gift_on: doc.deliver_gift_on,
      gift_sender: doc.gift_sender,
    }));
    normalizedData = normalizedData.filter(
      (item) => item.listing_id !== itemId
    );
    try {
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
            user_cart: normalizedData,
          },
        },
      });
      setCartList([]);
      fetchUserData();
      setToast("Item successfully removed from shopping cart.");
      setShowToast(true);
    } catch (error) {
      console.error(
        "Error updating cart:",
        error.response?.errors || error.message || error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getSingleListing = async (id) => {
    try {
      const result = await client.graphql({
        query: getListing,
        variables: { id: id },
      });
      return result.data.getListing;
    } catch (error) {
      console.error("Error fetching single items:", error);
    }
  };

  useEffect(() => {
    if (!loading) {
      getCartItems();
    }
  }, [loading]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <GlobelHeader isMobLocSel={true} top={0} />
      <CatDrop />
      <div className="crt-container">
        <div className="crt-list-box">
          <div
            style={{
              paddingBottom: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "5px",
              borderBottom: "1px solid #e4e4e4",
            }}
          >
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
            >
              Shopping Cart
            </span>
          </div>
          {cartList.length > 0 ? (
            <div className="crt-list-items">
              {cartList.map((cartItem, cartInd) => (
                <div key={cartInd * 3.5} className="crt-item-tile">
                  <div
                    onClick={() =>
                      (window.location.href = `/listing?title=${encodeURIComponent(
                        cartItem.p_title
                      )}&slid=${encodeURIComponent(cartItem.id)}`)
                    }
                    className="crt-img-box"
                  >
                    <img
                      className="crt-item-img"
                      src={cartImgs[cartInd]}
                      alt={`cart item ${cartInd + 1}`}
                    />
                  </div>
                  <div className="crt-tile-sub-box">
                    <div className="cart-item-title">{cartItem.p_title}</div>
                    <div
                      className="it-item-prov"
                      style={{
                        display: "flex",
                        alignItems: "end",
                        gap: "8px",
                      }}
                    >
                      <div
                        className="it-em-rp"
                        style={{
                          display: "flex",
                        }}
                      >
                        <span
                          className="it-rupee-symbol"
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          ₹
                        </span>
                        <span
                          className="it-itprice"
                          style={{
                            fontSize: "1.4rem",
                          }}
                        >
                          {formatIndianNumber(
                            cartItem.p_measurements[
                              getCartMeasureInd(
                                cartItem.p_measurements,
                                user.user_cart[cartInd].selected_measurement
                              )
                            ].p_price
                          )}
                        </span>
                      </div>
                      <span
                        className="it-itmrp"
                        style={{
                          fontWeight: "500",
                          fontSize: "1rem",
                          textDecoration: "line-through",
                          color: "#626262",
                        }}
                      >
                        ₹
                        {formatIndianNumber(
                          cartItem.p_measurements[
                            getCartMeasureInd(
                              cartItem.p_measurements,
                              user.user_cart[cartInd].selected_measurement
                            )
                          ].p_mrp
                        )}
                      </span>
                      <span
                        className="it-off"
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          color: "#0084ff",
                        }}
                      >
                        {`${getOffPers(
                          cartItem.p_measurements[
                            getCartMeasureInd(
                              cartItem.p_measurements,
                              user.user_cart[cartInd].selected_measurement
                            )
                          ].p_mrp,
                          cartItem.p_measurements[
                            getCartMeasureInd(
                              cartItem.p_measurements,
                              user.user_cart[cartInd].selected_measurement
                            )
                          ].p_price
                        )}% off`}
                      </span>
                    </div>
                    {cartItem.p_measurements[
                      getCartMeasureInd(
                        cartItem.p_measurements,
                        user.user_cart[cartInd].selected_measurement
                      )
                    ].p_qty < 20 && (
                      <span className="stock-label">{`Only ${
                        cartItem.p_measurements[
                          getCartMeasureInd(
                            cartItem.p_measurements,
                            user.user_cart[cartInd].selected_measurement
                          )
                        ].p_qty
                      } left in stock!`}</span>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        userSelect: "none",
                      }}
                    >
                      <input
                        type="checkbox"
                        value={cartItem.is_gift}
                        onChange={() =>
                          setCartList((preItems) => {
                            const newItems = [...preItems];
                            newItems[cartInd].is_gift =
                              !newItems[cartInd].is_gift;
                            return newItems;
                          })
                        }
                        id={`gift${cartInd * 4.5}`}
                      />
                      <label
                        htmlFor={`gift${cartInd * 4.5}`}
                        style={{
                          fontSize: "0.8rem",
                          cursor: "pointer",
                          color: "#0084ff",
                        }}
                      >
                        send as a gift
                      </label>
                    </div>
                    {user.user_cart[cartInd].variation_type.trim() !== "" && (
                      <div className="selections">
                        <span className="col-ti">
                          {`${user.user_cart[cartInd].variation_type}: `}
                        </span>
                        <span className="sel-col">
                          {user.user_cart[cartInd].variation_title}
                        </span>
                      </div>
                    )}
                    {user.user_cart[cartInd].measurement_type !== "none" && (
                      <div className="selections">
                        <span className="siz-ti">
                          {`${user.user_cart[cartInd].measurement_type}: `}
                        </span>
                        <span className="sel-siz">
                          {user.user_cart[cartInd].selected_measurement}
                        </span>
                      </div>
                    )}
                    <div className="item-btn-row">
                      <div className="qty-btn">
                        <button
                          className="qty-dec"
                          onClick={() =>
                            minItemQty(
                              user.user_cart[cartInd].quantity,
                              cartInd
                            )
                          }
                        >
                          -
                        </button>
                        <span className="item-qty">
                          {user.user_cart[cartInd].quantity}
                        </span>
                        <button
                          onClick={() =>
                            plusItemQty(
                              user.user_cart[cartInd].quantity,
                              cartInd
                            )
                          }
                          className="qty-inc"
                        >
                          +
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <button className="buy-item">Buy Now</button>
                        <button
                          className="remove-cart"
                          onClick={() =>
                            removeCartItem(user.user_cart[cartInd].listing_id)
                          }
                        >
                          <DeleteSweepRoundedIcon
                            sx={{
                              fontSize: 22,
                              color: "#3f3f3f",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="crt-empty-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "20px",
              }}
            >
              <div style={{ position: "relative" }}>
                <svg
                  viewBox="0 0 64 64"
                  width="100"
                  height="100"
                  fill="none"
                  stroke="rgb(248, 190, 0)"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-cart"
                >
                  <circle cx="23" cy="56" r="4"></circle>
                  <circle cx="49" cy="56" r="4"></circle>
                  <path d="M2 10h8l10 35h32l8-24H50"></path>
                </svg>
                <span
                  className="crt-alert-count"
                  style={{
                    position: "absolute",
                    fontSize: "2.5rem",
                    left: "30px",
                  }}
                >
                  🙁
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span>Oops! Your cart is looking a bit lonely.</span>
                <button
                  onClick={() => (window.location.href = "/")}
                  style={{
                    width: "fit-content",
                    padding: "8px 12px",
                    backgroundColor: "#f8be00",
                    fontWeight: "600",
                    border: "none",
                    margin: "15px 0",
                    borderRadius: "2px",
                    fontSize: "0.9rem",
                  }}
                >
                  Continue Shopping →
                </button>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                  }}
                >
                  <div
                    className="ac-list-count-tile"
                    style={{
                      minWidth: "150px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <BookmarksOutlinedIcon
                        sx={{
                          fontSize: 18,
                          color: "#272727",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                          color: "#272727",
                        }}
                      >
                        Saved items
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "space-between",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: "600",
                          color: "#0084ff",
                        }}
                      >
                        {user?.user_saved_items
                          ? user.user_saved_items.length
                          : 0}
                      </span>
                      <button
                        className="ac-my-lists-viewall"
                        onClick={() =>
                          (window.location.href = "/items?myList=savedItems")
                        }
                      >
                        View all →
                      </button>
                    </div>
                  </div>
                  <div
                    className="ac-list-count-tile"
                    style={{
                      minWidth: "150px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <ThumbUpOffAltOutlinedIcon
                        sx={{
                          fontSize: 18,
                          color: "#272727",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "600",
                          color: "#272727",
                        }}
                      >
                        Liked items
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "space-between",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: "600",
                          color: "#0084ff",
                        }}
                      >
                        {user?.user_liked_items
                          ? user.user_liked_items.length
                          : 0}
                      </span>
                      <button
                        className="ac-my-lists-viewall"
                        onClick={() =>
                          (window.location.href = "/items?myList=likedItems")
                        }
                      >
                        View all →
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {cartList.length > 0 && (
          <div className="crt-aggregation-container">
            <div
              style={{
                padding: " 12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                }}
              >
                Subtotal:
              </span>
              <span
                className="crt-aggri-total"
                style={{
                  fontWeight: "600",
                  color: "rgb(186, 115, 0)",
                }}
              >
                {`₹${formatIndianNumber(cartSubtotal)}`}
              </span>
              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <span className="crt-aggri-txt">Total items:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {cartTotalItems}
                </span>
              </div>
              <div>
                <span className="crt-aggri-txt">Total items in quantity:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {cartTotalQtys}
                </span>
              </div>
              <div>
                <span className="crt-aggri-txt">Total price of items:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {`₹${formatIndianNumber(cartTotalMrp)}`}
                </span>
              </div>
              <div>
                <span className="crt-aggri-txt">Overall discount:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {`-₹${formatIndianNumber(
                    cartTotalMrp - cartSubtotal
                  )} (${cartTotalDiscount}%)`}
                </span>
              </div>
              {/* <div>
              <span className="crt-aggri-txt">GST:</span>{" "}
              <span
                className="crt-aggri-txt"
                style={{
                  fontWeight: "600",
                }}
              >
              </span>
            </div> */}
              <div>
                <span className="crt-aggri-txt">Deliver charges:</span>{" "}
                <span
                  className="crt-aggri-txt"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {`₹${deliveryCharges}`}
                </span>
              </div>
              <button
                className="crt-proceed"
                onClick={setOrderDraft}
                style={{
                  padding: "8px 12px",
                  backgroundColor: "#f8be00",
                  fontWeight: "600",
                  border: "none",
                  marginTop: "15px",
                  borderRadius: "2px",
                  fontSize: "0.9rem",
                }}
              >
                Proceed to buy →
              </button>

              <span
                className="crt-notice"
                style={{
                  fontSize: "0.8rem",
                  padding: "5px 10px",
                  borderRadius: "6px",
                  backgroundColor: "#ffedb7",
                  marginTop: "15px",
                }}
              >
                <b>Note: </b>Items in your cart will always reflect the most
                recent price displayed on their product detail pages.
              </span>
            </div>
          </div>
        )}
      </div>
      <GlobelFooter />
      <Snackbar
        open={showToast}
        onClose={handleSnackClose}
        TransitionComponent={Fade}
        message={toastText}
        key={Fade.name}
        autoHideDuration={3000}
      />
      {(isLoading || loading) && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default Cart;
