import React, { useState, useRef, useEffect, useContext } from "react";
import "./account.css";
import { generateClient } from "aws-amplify/api";
import GlobelHeader from "../../widgets/header";
import GlobelFooter from "../../widgets/footer";
import CatDrop from "../../widgets/categories";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { UserContext } from "../../userContext";
import { getListing } from "../../graphql/queries";
import { getUrl } from "aws-amplify/storage";

const MyAccount = () => {
  const client = generateClient();
  const { user, loading } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [recentItems, setRecentItems] = useState([]);
  const [recentItemImages, setRecentItemImgages] = useState([]);
  const [likedItems, setLikedItems] = useState([]);
  const [likedItemImages, setLikedItemImgages] = useState([]);

  const getMyLists = async () => {
    const lastFiveItems = user.user_viewed_items.slice(-5);
    setIsLoading(true);
    try {
      const itemsList = await Promise.all(
        lastFiveItems.map(async (listingId) => {
          const listing = await getSingleListing(listingId);
          return listing;
        })
      );
      const imgUrls = await Promise.all(
        itemsList.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      setRecentItems(itemsList);
      setRecentItemImgages(imgUrls);
      getLikedItems();
    } catch (error) {
      console.error("Error fetching recent items:", error);
    }
  };

  const getLikedItems = async () => {
    const lastFiveLikedItems = user.user_liked_items.slice(-5);
    try {
      const itemsList = await Promise.all(
        lastFiveLikedItems.map(async (listingId) => {
          const listing = await getSingleListing(listingId);
          return listing;
        })
      );
      const imgUrls = await Promise.all(
        itemsList.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      setLikedItems(itemsList);
      setLikedItemImgages(imgUrls);
    } catch (error) {
      console.error("Error fetching recent items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSingleListing = async (id) => {
    try {
      const result = await client.graphql({
        query: getListing,
        variables: { id: id },
      });
      return result.data.getListing;
    } catch (error) {
      console.error("Error fetching single items:", error);
    }
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!loading) {
      getMyLists();
    }
  }, [loading]);

  return (
    <>
      <GlobelHeader />
      <CatDrop />
      <div className="ac-main-container">
        <div
          style={{
            fontSize: "1.5rem",
            padding: "12px",
          }}
        >
          Your account
        </div>
        <div className="ac-header">
          <div className="ac-profile-img">
            <img src="./favicon.ico" alt="My Profile" />
          </div>
          <div
            className="ac-head-profile-data"
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "15px",
            }}
          >
            {user?.user_account?.user_full_name ? (
              <span className="ac-user-fullname">
                {user.user_account.user_full_name}
              </span>
            ) : (
              <span className="ac-user-fullname">Guest User</span>
            )}
            {user?.user_account?.user_phone_number ? (
              <span className="ac-user-phone">
                {user.user_account.user_phone_number}
              </span>
            ) : (
              <span className="ac-user-phone">+91 1234567890</span>
            )}
            {user?.user_account?.user_email ? (
              <span className="ac-user-email">
                {user.user_account.user_email}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="ac-list-count-row">
          <div className="ac-list-count-tile">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <LocalMallOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Your orders
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  color: "#0084ff",
                }}
              >
                {user?.user_orders ? user.user_orders.length : 0}
              </span>
              <button className="ac-my-lists-viewall">View all →</button>
            </div>
          </div>
          <div className="ac-list-count-tile">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <svg
                viewBox="0 0 64 64"
                width="18"
                height="18"
                fill="none"
                stroke="#272727"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-cart"
              >
                <circle cx="23" cy="56" r="4"></circle>
                <circle cx="49" cy="56" r="4"></circle>
                <path d="M2 10h8l10 35h32l8-24H50"></path>
              </svg>
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Cart items
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  color: "#0084ff",
                }}
              >
                {user?.user_cart ? user.user_cart.length : 0}
              </span>
              <button className="ac-my-lists-viewall">View all →</button>
            </div>
          </div>
          <div className="ac-list-count-tile">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <BookmarksOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Saved items
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  color: "#0084ff",
                }}
              >
                {user?.user_saved_items ? user.user_saved_items.length : 0}
              </span>
              <button
                className="ac-my-lists-viewall"
                onClick={() =>
                  (window.location.href = "/items?myList=savedItems")
                }
              >
                View all →
              </button>
            </div>
          </div>
          <div className="ac-list-count-tile">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <ThumbUpOffAltOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Liked items
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "600",
                  color: "#0084ff",
                }}
              >
                {user?.user_liked_items ? user.user_liked_items.length : 0}
              </span>
              <button
                className="ac-my-lists-viewall"
                onClick={() =>
                  (window.location.href = "/items?myList=likedItems")
                }
              >
                View all →
              </button>
            </div>
          </div>
        </div>
        <div className="ac-page-body">
          <div className="ac-recent-activity-container">
            {recentItems.length > 0 && (
              <div>
                <div className="ac-recent-activity-head">
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Recently viewed
                  </span>
                  <button
                    className="ac-my-lists-viewall"
                    onClick={() =>
                      (window.location.href = "/items?myList=recentItems")
                    }
                  >
                    View all →
                  </button>
                </div>
                <div className="ac-recent-list">
                  {recentItems.map((item, index) => (
                    <div
                      key={index}
                      className="ac-recent-item-tile"
                      onClick={() =>
                        (window.location.href = `/listing?title=${encodeURIComponent(
                          item.p_title
                        )}&slid=${encodeURIComponent(item.id)}`)
                      }
                    >
                      <div className="ac-recent-img-box">
                        <img
                          src={recentItemImages[index]}
                          alt={`Recent_item_${index * 6}`}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: "0.6rem",
                          padding: "5px",
                          fontWeight: "600",
                        }}
                      >
                        {item.p_title}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {likedItems.length > 0 && (
              <div>
                <div
                  className="ac-recent-activity-head"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Recently liked
                  </span>
                  <button
                    className="ac-my-lists-viewall"
                    onClick={() =>
                      (window.location.href = "/items?myList=likedItems")
                    }
                  >
                    View all →
                  </button>
                </div>
                <div className="ac-recent-list">
                  {likedItems.map((item, index) => (
                    <div
                      key={index}
                      className="ac-recent-item-tile"
                      onClick={() =>
                        (window.location.href = `/listing?title=${encodeURIComponent(
                          item.p_title
                        )}&slid=${encodeURIComponent(item.id)}`)
                      }
                    >
                      <div className="ac-recent-img-box">
                        <img
                          src={likedItemImages[index]}
                          alt={`Liked_item_${index * 6}`}
                        />
                      </div>
                      <span
                        style={{
                          fontSize: "0.6rem",
                          padding: "5px",
                          fontWeight: "600",
                        }}
                      >
                        {item.p_title}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="ac-action-options-container">
            <div
              className="ac-action-option-tile"
              onClick={() => (window.location.href = "/edit-profile")}
            >
              <ModeEditOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Edit profile
              </span>
              <KeyboardArrowRightRoundedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                  marginLeft: "auto",
                }}
              />
            </div>
            <div
              className="ac-action-option-tile"
              onClick={() => (window.location.href = "/my-addresses")}
            >
              <PlaceOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                My addresses
              </span>
              <KeyboardArrowRightRoundedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                  marginLeft: "auto",
                }}
              />
            </div>

            <div className="ac-action-option-tile">
              <NotificationsActiveOutlinedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Notifications
              </span>
              <KeyboardArrowRightRoundedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                  marginLeft: "auto",
                }}
              />
            </div>
            <div className="ac-action-option-tile">
              <SupportAgentIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Help center
              </span>
              <KeyboardArrowRightRoundedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                  marginLeft: "auto",
                }}
              />
            </div>
            <div className="ac-action-option-tile">
              <StorefrontIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Become a seller
              </span>
              <KeyboardArrowRightRoundedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                  marginLeft: "auto",
                }}
              />
            </div>
            <div className="ac-action-option-tile">
              <LogoutRoundedIcon
                sx={{
                  fontSize: 18,
                  color: "#272727",
                }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "600",
                  color: "#272727",
                }}
              >
                Log out
              </span>
            </div>
          </div>
        </div>
      </div>
      <GlobelFooter />
    </>
  );
};

export default MyAccount;
