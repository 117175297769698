import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";
import "./listing.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import { useNavigate, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import {
  getListing,
  listListings,
  reviewsByTime,
  getSeller,
} from "../../graphql/queries";
import { getUrl } from "aws-amplify/storage";
import Rating from "@mui/material/Rating";
import ShareSharpIcon from "@mui/icons-material/ShareSharp";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import StarSharpIcon from "@mui/icons-material/StarSharp";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CatDrop from "../../widgets/categories";
import GlobelFooter from "../../widgets/footer";
import { UserContext } from "../../userContext";
import { updateUser } from "../../graphql/mutations";
import { Fade } from "@mui/material";

const Listing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { client, user, loading, fetchUserData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [listingData, setListingData] = useState({});
  const [listingDesc, setLiDesc] = useState([]);
  const [listingImgs, setListingImgs] = useState([]);
  const [listingVariations, setListingVariations] = useState([]);
  const [variationUrls, setVariationUrls] = useState([]);
  const [similarListings, setSimilarListings] = useState([]);
  const [similarUrls, setSimilarUrls] = useState([]);
  const [categoryVariations, setCategoryVariations] = useState([]);
  const [carVarUrls, setCatVarUrls] = useState([]);
  const [deskImgInd, setDeskImgInd] = useState(0);
  const [measureInd, setMeasureInd] = useState(0);
  const [listingRating, setListingRating] = useState(5.0);
  const [listingHiKeys, setListingHiKeys] = useState([]);
  const [listingHiValues, setListingHiValues] = useState([]);
  const [isDescOpen, setIsDescOpen] = useState(false);
  const scrollSimiContainerRef = useRef(null);
  const scrollMoreContainerRef = useRef(null);
  const revPopupRef = useRef(null);
  const [showSimiLeftButton, setShowSimiLeftButton] = useState(false);
  const [showSimiRightButton, setShowSimiRightButton] = useState(true);
  const [showMoreLeftButton, setShowMoreLeftButton] = useState(false);
  const [showMoreRightButton, setShowMoreRightButton] = useState(true);
  const linkedListingID = new URLSearchParams(location.search).get("slid");
  const [liRatingPercent, setLiRatingPercent] = useState({
    four: 0,
    three: 0,
    two: 0,
    one: 0,
  });
  const [listingReviews, setListingReviews] = useState([]);
  const [isReviewPopup, setReviewPopup] = useState(false);
  const [reviewImgInd, setReviewImgInd] = useState(0);
  const [popupIndRev, setPopupIndRev] = useState({});
  const [revPopupImgs, setRevPopupImgs] = useState([]);
  const [sellerProfile, setSellerProfile] = useState({});
  const [showToast, setShowToast] = useState(false);

  const [toastText, setToast] = useState("");

  let reviewList = [];
  let similarItemsList = [];
  let catItemsList = [];

  const handleSnackClose = () => {
    setShowToast(false);
  };

  const updateRecentHistory = async () => {
    let viewedList = user?.user_viewed_items ?? [];
    if (viewedList.length === 99) {
      viewedList = [...viewedList.slice(1)];
    }
    if (!viewedList.includes(linkedListingID) && user?.user_viewed_items) {
      viewedList.push(linkedListingID);
      try {
        await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
              user_viewed_items: viewedList,
            },
          },
        });
        fetchUserData();
      } catch (error) {
        console.error("Error updating recent history:", error);
      }
    }
  };

  const handleLikeListing = async () => {
    let likedList = user?.user_liked_items ?? [];
    let isRemoved = false;
    if (likedList.length === 99) {
      likedList = [...likedList.slice(1)];
    }
    if (likedList.includes(linkedListingID)) {
      likedList = likedList.filter((item) => item !== linkedListingID);
      isRemoved = true;
    } else {
      likedList.push(linkedListingID);
      isRemoved = false;
    }
    if (user?.user_liked_items) {
      try {
        await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
              user_liked_items: likedList,
            },
          },
        });
        fetchUserData();
        if (isRemoved) {
          setToast("Item successfully removed from your likes.");
        } else {
          setToast("Item successfully added to your likes.");
        }
        setShowToast(true);
      } catch (error) {
        console.error("Error updating my like:", error);
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      updateRecentHistory();
    }
  }, [isLoading]);

  const setPopupImgList = async (rev, ind) => {
    try {
      const imgUrls = await Promise.all(
        rev.images.map(async (path) => {
          const imgUrl = await fetchImageUrl(path);
          return imgUrl;
        })
      );
      setRevPopupImgs(imgUrls);
      setPopupIndRev(rev);
      setReviewImgInd(ind);
      setReviewPopup(true);
      document.body.style.overflow = "hidden";
    } catch (error) {
      console.error("Error fetching image URLs:", error);
    }
  };

  const handleCartAdding = async () => {
    setIsLoading(true);
    let userCartList = user?.user_cart ?? [];
    let normalizedData = userCartList.map((doc) => ({
      adding_time: doc.adding_time,
      listing_id: doc.listing_id,
      variation_type: doc.variation_type,
      variation_title: doc.variation_title,
      measurement_type: doc.measurement_type,
      selected_measurement: doc.selected_measurement,
      quantity: doc.quantity,
      is_gift: doc.is_gift,
      color: doc.color,
      gift_message: doc.gift_message,
      gift_type: doc.gift_type,
      covering: doc.covering,
      deliver_gift_on: doc.deliver_gift_on,
      gift_sender: doc.gift_sender,
    }));
    if (normalizedData.length === 99) {
      normalizedData = [...normalizedData.slice(1)];
    }
    if (normalizedData.some((item) => item.listing_id === linkedListingID)) {
      window.location.href = "/cart";
    } else {
      if (user) {
        const date = new Date();
        const awsDateTime = date.toISOString();
        normalizedData.push({
          adding_time: awsDateTime,
          listing_id: linkedListingID,
          variation_type: listingData.p_variation_type,
          variation_title: listingData.p_variation_title,
          measurement_type: listingData.p_measurements_type,
          selected_measurement:
            listingData.p_measurements[measureInd].p_measurement,
          quantity: 1,
          is_gift: false,
          color: listingData.p_color,
          gift_message: "none",
          gift_type: "none",
          covering: "none",
          deliver_gift_on: awsDateTime,
          gift_sender: "none",
        });
        try {
          await client.graphql({
            query: updateUser,
            variables: {
              input: {
                id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
                user_cart: normalizedData,
              },
            },
          });
          fetchUserData();
          setToast("Item successfully added to cart.");
          setShowToast(true);
        } catch (error) {
          console.error(
            "Error updating cart:",
            error.response?.errors || error.message || error
          );
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const setOrderDraft = async () => {
    setIsLoading(true);
    const date = new Date();
    const awsDateTime = date.toISOString();
    try {
      await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: "73c547aa-82f0-4594-9a5a-4d589b7079d7",
            order_draft: [
              {
                create_time: awsDateTime,
                listing_id: linkedListingID,
                variation_type: listingData.p_variation_type,
                variation_title: listingData.p_variation_title,
                measurement_type: listingData.p_measurements_type,
                selected_measurement:
                  listingData.p_measurements[measureInd].p_measurement,
                quantity: 1,
                is_gift: false,
                gift_message: "",
                gift_type: "none",
                covering: "none",
                deliver_gift_on: awsDateTime,
                gift_sender: "none",
              },
            ],
          },
        },
      });
      fetchUserData();
    } catch (error) {
      console.error(
        "Error updating order:",
        error.response?.errors || error.message || error
      );
    } finally {
      setIsLoading(false);
      window.location.href = "/checkout";
    }
  };

  const getMyListing = async () => {
    try {
      document.body.style.overflow = "hidden";
      const result = await client.graphql({
        query: getListing,
        variables: { id: linkedListingID },
      });
      const imgUrls = await Promise.all(
        result.data.getListing.p_image_paths.map(async (path) => {
          const url = await fetchImageUrl(path);
          return url;
        })
      );
      setTitle(`Buy ${result.data.getListing.p_title}`);
      setListingImgs(imgUrls);
      setListingRating(result.data.getListing.p_rating);
      setListingData(result.data.getListing);
      getListingVariations(result.data.getListing.p_variation_id);
      const spaceDesc = result.data.getListing.p_desc.split("<br>");
      setListingHiKeys(result.data.getListing.p_highlights.keys);
      setListingHiValues(result.data.getListing.p_highlights.values);
      fetchSellerProfile(result.data.getListing.p_seller_id);
      const ratingsCount = result.data.getListing.p_ratings_type_count;
      const totalRatings =
        ratingsCount.five +
        ratingsCount.four +
        ratingsCount.three +
        ratingsCount.two +
        ratingsCount.one;
      setLiRatingPercent({
        four: calculateRatePercentage(ratingsCount.four, totalRatings),
        three: calculateRatePercentage(ratingsCount.three, totalRatings),
        two: calculateRatePercentage(ratingsCount.two, totalRatings),
        one: calculateRatePercentage(ratingsCount.one, totalRatings),
      });
      setLiDesc(spaceDesc);
      getSimilarListings(result.data.getListing.p_similar_id, null);
      getCatListings(result.data.getListing.p_category, null);
      getListingReviews(null);
    } catch (error) {
      console.error("Error fetching offers", error);
    }
  };

  const calculateRatePercentage = (min, max) => {
    if (max === 0) return 0;
    return parseInt((min / max) * 100);
  };

  const fetchSellerProfile = async (sellerId) => {
    try {
      const result = await client.graphql({
        query: getSeller,
        variables: { id: "6153015e-5c95-4937-b7ae-a5ef04ec936e" },
      });
      setSellerProfile(result.data.getSeller);
    } catch (error) {
      console.error("Error fetching Seller", error);
    }
  };

  const calcuFourRate = () => {
    const totalRatings =
      100 -
      liRatingPercent.four -
      liRatingPercent.three -
      liRatingPercent.two -
      liRatingPercent.one;
    return totalRatings;
  };

  const getListingVariations = async (ID) => {
    try {
      const result = await client.graphql({
        query: listListings,
        variables: {
          filter: { p_variation_id: { eq: ID } },
        },
      });
      const imgUrls = await Promise.all(
        result.data.listListings.items.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      setVariationUrls(imgUrls);
      setListingVariations(result.data.listListings.items);
      setIsLoading(false);
      document.body.style.overflow = "auto";
    } catch (error) {
      console.error("Error fetching offers", error);
    }
  };

  const getSimilarListings = async (ID, nextToken) => {
    try {
      const result2 = await client.graphql({
        query: listListings,
        variables: {
          filter: {
            p_similar_id: { eq: ID },
            and: [
              {
                p_default_variation: { eq: true },
              },
            ],
          },
          limit: 4,
          nextToken: nextToken,
        },
      });
      const { items: newItems, nextToken: newNextToken } =
        result2.data.listListings;
      similarItemsList = [...similarItemsList, ...newItems];
      if (similarItemsList.length < 20 && newNextToken) {
        await getListingReviews(ID, newNextToken);
      }
      const imgUrls = await Promise.all(
        similarItemsList.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      setSimilarUrls(imgUrls);
      setSimilarListings(similarItemsList);
    } catch (error) {
      console.error("Error fetching offers", error);
    }
  };

  const getCatListings = async (cat, nextToken) => {
    try {
      const result = await client.graphql({
        query: listListings,
        variables: {
          filter: {
            p_category: { eq: cat },
            and: [
              {
                p_default_variation: { eq: true },
              },
            ],
          },
          limit: 4,
          nextToken: nextToken,
        },
      });
      const { items: newItems, nextToken: newNextToken } =
        result.data.listListings;
      catItemsList = [...catItemsList, ...newItems];
      if (catItemsList.length < 20 && newNextToken) {
        await getCatListings(cat, newNextToken);
      }
      const imgUrls = await Promise.all(
        catItemsList.map(async (listing) => {
          const imgUrl = await fetchImageUrl(listing.p_image_paths[0]);
          return imgUrl;
        })
      );
      setCatVarUrls(imgUrls);
      setCategoryVariations(catItemsList);
    } catch (error) {
      console.error("Error fetching offers", error);
    }
  };

  const getListingReviews = async (nextToken) => {
    try {
      const res = await client.graphql({
        query: reviewsByTime,
        variables: {
          type: "Review",
          sortDirection: "DESC",
          filter: {
            listing_id: { eq: linkedListingID },
            and: [
              {
                approved: { eq: true },
              },
              {
                is_review: { eq: true },
              },
            ],
          },
          limit: 4,
          nextToken: nextToken,
        },
      });
      const { items: newItems, nextToken: newNextToken } =
        res.data.reviewsByTime;
      reviewList = [...reviewList, ...newItems];
      if (reviewList.length < 4 && newNextToken) {
        await getListingReviews(newNextToken);
      }
      setListingReviews(reviewList);
    } catch (error) {
      console.error("Error fetching Reviews:", error);
    }
    // const awsCurrentDateTime = new Date().toISOString();
    // await client.graphql({
    //   query: mutations.createReview,
    //   variables: {
    //     input: {
    //       approved: true,
    //       images: [],
    //       listing_id: linkedListingID,
    //       is_review: false,
    //       rating: 5.0,
    //       review_title: "",
    //       review_desc: "",
    //       review_time: awsCurrentDateTime,
    //       user_id: "user005",
    //       user_img:
    //         "public/seller/listings/V9L7I3u0h7a0e2g7a1L3/61zJ-SKSNFL._SY741_.jpg",
    //       user_name: "RaNTA singh",
    //       likes_count: 360,
    //       dislike_count: 400,
    //       reports_count: 40,
    //       type: "Review",
    //     },
    //   },
    // });
    // console.log("uploaded");
  };

  const [networkDate, setNetworkDate] = useState(null);
  const [isSunday, setIsSunday] = useState(false);

  const convertTimeStamp = (awsTime) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(awsTime).toLocaleDateString("en-US", options);
    return date;
  };

  const fetchNetworkDate = async () => {
    try {
      const response = await fetch("http://worldtimeapi.org/api/ip");
      const data = await response.json();
      const date = new Date(data.datetime);

      setNetworkDate(date);
      setIsSunday(date.getDay() === 0);
    } catch (error) {
      console.error("Error fetching the network date:", error);
    }
  };

  const formatIndianNumber = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const reviewImgNext = () => {
    if (revPopupImgs.length - 1 === reviewImgInd) {
      setReviewImgInd(0);
    } else {
      setReviewImgInd(reviewImgInd + 1);
    }
  };

  const reviewImgPrev = () => {
    if (reviewImgInd === 0) {
      setReviewImgInd(revPopupImgs.length - 1);
    } else {
      setReviewImgInd(reviewImgInd - 1);
    }
  };

  const reviewPopupClose = () => {
    document.body.style.overflow = "auto";
    setRevPopupImgs([]);
    setReviewPopup(false);
  };

  const handleClickOutsideRevPopup = (event) => {
    if (revPopupRef.current && !revPopupRef.current.contains(event.target)) {
      document.body.style.overflow = "auto";
      setRevPopupImgs([]);
      setReviewPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideRevPopup);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRevPopup);
    };
  }, []);

  useEffect(() => {
    fetchNetworkDate();
    getMyListing();
  }, []);

  const getOffPers = (mrp, price) => {
    const off = ((mrp - price) / mrp) * 100;
    return parseInt(off);
  };

  const fetchImageUrl = async (path) => {
    try {
      const fetchedData = await getUrl({ path });
      return fetchedData.url.toString();
    } catch (error) {
      console.error("Error fetching the URL:", error);
      throw error;
    }
  };

  useLayoutEffect(() => {
    if (scrollSimiContainerRef.current) {
      const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } =
          scrollSimiContainerRef.current;
        const tolerance = 5;

        setShowSimiLeftButton(scrollLeft > 0);
        setShowSimiRightButton(
          scrollLeft < scrollWidth - clientWidth - tolerance
        );
      };

      const container = scrollSimiContainerRef.current;
      container.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [similarListings]);

  useLayoutEffect(() => {
    if (scrollMoreContainerRef.current) {
      const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } =
          scrollMoreContainerRef.current;
        const tolerance = 5;

        setShowMoreLeftButton(scrollLeft > 0);
        setShowMoreRightButton(
          scrollLeft < scrollWidth - clientWidth - tolerance
        );
      };

      const container = scrollMoreContainerRef.current;
      container.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [categoryVariations]);

  const scrollSimiLeft = () => {
    const scrollAmount = window.innerWidth / 1.3;
    scrollSimiContainerRef.current.scrollBy({
      left: -scrollAmount,
      behavior: "smooth",
    });
  };

  const scrollSimiRight = () => {
    const scrollAmount = window.innerWidth / 1.3;
    scrollSimiContainerRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  const scrollMoreLeft = () => {
    const scrollAmount = window.innerWidth / 1.3;

    scrollMoreContainerRef.current.scrollBy({
      left: -scrollAmount,
      behavior: "smooth",
    });
  };

  const scrollMoreRight = () => {
    const scrollAmount = window.innerWidth / 1.3;
    scrollMoreContainerRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <>
      <GlobelHeader />
      <CatDrop />
      <div className="item-body">
        <div className="item-slider-container">
          {listingImgs.map((img, index) => (
            <div key={index} className="item-slide">
              <img src={img} alt={`listingimg${index * 7}`} />
            </div>
          ))}
        </div>
        <div className="listing-imgs-container">
          <div
            className="desktop-img-list"
            style={{
              flexDirection: "column",
              gap: "4px",
            }}
          >
            {listingImgs.map((img, index) => (
              <div
                key={index}
                className="list-sing-img"
                onMouseEnter={() => setDeskImgInd(index)}
                style={{
                  border: "2px solid",
                  borderColor: deskImgInd === index ? "#0084ff" : "white",
                }}
              >
                <img src={img} alt={`listingimg${index * 3}`} />
              </div>
            ))}
          </div>
          <div className="desktop-img-container">
            <img src={listingImgs[deskImgInd]} alt="listing-ing-im" />
          </div>
        </div>
        <div className="item-data">
          <div className="title-container">
            <span className="item-brand">{listingData.p_brand}</span>
            <h1>{listingData.p_title}</h1>
            <div className="item-rating-row">
              <div className="item-rating-row-1">
                <Rating
                  name="half-rating-read"
                  value={listingRating}
                  precision={0.1}
                  readOnly
                  sx={{
                    color: "#ff0048",
                  }}
                />
                <GroupsRoundedIcon
                  sx={{ fontSize: 22, color: "#000", margin: "0 5px" }}
                />
                <span
                  className="item-ratings-count"
                  style={{
                    fontSize: "0.7rem",
                  }}
                >
                  {listingData.p_ratings_count}
                </span>
              </div>
              <div className="action-icon-row">
                <button
                  onClick={handleLikeListing}
                  className="li-act-icon"
                  style={{
                    backgroundColor: "#f4f3f3",
                    border: "none",
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  <ThumbUpRoundedIcon
                    sx={{
                      fontSize: 26,
                      color: user?.user_liked_items
                        ? user.user_liked_items.includes(linkedListingID)
                          ? "#ff0048"
                          : "#000"
                        : "#000",
                    }}
                  />
                </button>
                <button
                  className="li-act-icon"
                  style={{
                    backgroundColor: "#f4f3f3",
                    border: "none",
                    height: "40px",
                    width: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  <ShareSharpIcon sx={{ fontSize: 26, color: "#000" }} />
                </button>
              </div>
            </div>
            {listingData.p_measurements && (
              <div className="product-price-row">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <span className="rupee-symbol">₹</span>
                  <div className="product-price">
                    {formatIndianNumber(
                      listingData.p_measurements[measureInd].p_price
                    )}
                  </div>
                </div>
                <div className="product-mrp">
                  ₹
                  {formatIndianNumber(
                    listingData.p_measurements[measureInd].p_mrp
                  )}
                </div>
                <div className="product-off">{`${getOffPers(
                  listingData.p_measurements[measureInd].p_mrp,
                  listingData.p_measurements[measureInd].p_price
                )}% off`}</div>
              </div>
            )}
            {((listingData.p_sundaysale && isSunday) ||
              listingData.p_seasonaloffer ||
              listingData.p_limited_sale) && (
              <div className="listing-offer-container">
                {listingData.p_sundaysale && isSunday ? (
                  <span
                    style={{
                      padding: "0 10px 0 3px",
                    }}
                  >
                    Sunday sale
                  </span>
                ) : listingData.p_seasonaloffer ? (
                  <span
                    style={{
                      padding: "0 10px 0 3px",
                    }}
                  >
                    Seasonal offer
                  </span>
                ) : listingData.p_limited_sale ? (
                  <span>Limited time deal</span>
                ) : null}
              </div>
            )}
          </div>
          {listingVariations.length !== 0 && (
            <div
              className="listing-variant-box"
              style={{
                marginTop: "12px",
              }}
            >
              <div
                className="li-var-tp"
                style={{
                  fontWeight: "600",
                  fontSize: "0.8rem",
                }}
              >
                {listingData.p_variation_type}
              </div>
              <div className="variant-container">
                {listingVariations.map((vari, variIndo) => (
                  <div
                    className="variant-tile"
                    key={variIndo}
                    onClick={() =>
                      (window.location.href = `/listing?title=${encodeURIComponent(
                        vari.p_title
                      )}&slid=${encodeURIComponent(vari.id)}`)
                    }
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "3px",
                      cursor: "pointer",
                      border:
                        vari.id === linkedListingID
                          ? "2px solid #0084ff"
                          : "1px solid #dddddd",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "contain",
                      }}
                      src={variationUrls[variIndo]}
                      alt="Vari-pic"
                    />
                    <span
                      style={{
                        fontSize: "0.7rem",
                        fontWeight: "600",
                      }}
                    >
                      {vari.p_variation_title}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {listingData.p_measurements_type !== "none" && (
            <div className="listing-mesbox">
              <div
                className="li-var-tp"
                style={{
                  order: "3",
                  fontWeight: "600",
                  fontSize: "0.8rem",
                }}
              >
                {listingData.p_measurements_type}
              </div>
              {listingData.p_measurements && (
                <div className="p_measurements-container">
                  {listingData.p_measurements.map((mes, mesInd) => (
                    <button
                      onClick={() => setMeasureInd(mesInd)}
                      style={{
                        backgroundColor:
                          measureInd === mesInd ? "#0084ff" : "white",
                        color: measureInd === mesInd ? "white" : "black",
                        borderColor:
                          measureInd === mesInd ? "#0084ff" : "black",
                      }}
                      className="li-measurements-box"
                      key={mesInd}
                    >
                      {mes.p_measurement}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="listing-brief">
            {listingHiKeys.length > 0 && (
              <div className="lihicont" style={{ padding: "15px 12px" }}>
                <div
                  style={{
                    order: "3",
                    fontWeight: "600",
                    fontSize: "1.1rem",
                    marginBottom: "10px",
                  }}
                >
                  Product details
                </div>
                <div>
                  {listingHiKeys.map((key, mesInd) => (
                    <div
                      className="hi-tile-row"
                      key={mesInd}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        fontSize: "0.8rem",
                        alignItems: "start",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        className="hi-tile-key"
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {key}:
                      </div>
                      <div className="hi-tile-value">
                        {listingHiValues[mesInd]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="cart-buy-btn">
            <button className="item-cart-button" onClick={handleCartAdding}>
              {user?.user_cart.some(
                (item) => item.listing_id === linkedListingID
              )
                ? "VIEW CART"
                : "ADD TO CART"}
            </button>
            <button className="item-buy-button" onClick={setOrderDraft}>
              BUY NOW
            </button>
          </div>
          <div className="seller-data-container">
            <div
              className="liseldata-hd"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="lislpro"
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
              >
                <StorageImage
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                  }}
                  path="public/seller/listings/A1E5N2Z8J5x7M7D7W6v1/615ke24vqQL._SY741_.jpg"
                  alt="seller-profile"
                />
              </div>
              <span
                style={{
                  fontSize: "1rem",
                  color: "#0084ff",
                  marginLeft: "4px",
                  fontWeight: "600",
                }}
              >
                {sellerProfile.shop_name}
              </span>
              <button
                style={{
                  fontSize: "0.9rem",
                  color: "#0084ff",
                  marginLeft: "auto",
                  padding: "5px 10px",
                  border: "1px solid #0084ff",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontWeight: "600",
                }}
              >
                Follow +
              </button>
            </div>
            <div
              className="lisdarow"
              style={{
                display: "flex",
                gap: "5px",
                marginTop: "12px",
              }}
            >
              <div>
                {formatIndianNumber(sellerProfile.followers_count)} followers
              </div>
              <div>{sellerProfile.ratings} ★ overall ratings</div>
              <div>{formatIndianNumber(sellerProfile.p_count)} products</div>
            </div>
          </div>
          <div className="lidesc">
            <div
              onClick={() => setIsDescOpen(!isDescOpen)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  fontSize: "1.1rem",
                }}
              >
                Description
              </span>
              <button
                className="li-desc-show"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                  height: "35px",
                  width: "35px",
                  border: "1px solid #dddddd",
                  borderRadius: "50%",
                }}
              >
                {!isDescOpen ? (
                  <KeyboardArrowDownRoundedIcon
                    sx={{
                      fontSize: 24,
                      color: "#000",
                    }}
                  />
                ) : (
                  <KeyboardArrowUpRoundedIcon
                    sx={{
                      fontSize: 24,
                      color: "#000",
                    }}
                  />
                )}
              </button>
            </div>
            <div
              className={`li-desc-text ${isDescOpen ? "open" : ""}`}
              style={{
                fontSize: "0.8rem",
                color: "#3f3f3f",
                margin: "12px 0",
              }}
            >
              {listingDesc.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
          <div
            className="divider"
            style={{
              borderBottom: "1px solid #dddddd",
              width: "100%",
              order: "9",
            }}
          ></div>
          <div className="item-vantages">
            {listingData.p_replace_time > 0 && (
              <div
                className="listing-vantage-tile"
                style={{
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <div
                  className="vant-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    background:
                      "linear-gradient(120deg, blue, #0084ff, #84c3ff)",
                  }}
                >
                  <PublishedWithChangesRoundedIcon
                    sx={{
                      fontSize: 40,
                      color: "#fff",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontSize: "0.7rem",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#353535",
                  }}
                >
                  {`${listingData.p_replace_time} days Returns`}
                </span>
              </div>
            )}
            {listingData.p_warranty > 0 && (
              <div
                className="listing-vantage-tile"
                style={{
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <div
                  className="vant-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    background:
                      "linear-gradient(120deg, blue, #0084ff, #84c3ff)",
                  }}
                >
                  <TaskAltRoundedIcon
                    sx={{
                      fontSize: 40,
                      color: "#fff",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontSize: "0.7rem",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#353535",
                  }}
                >
                  {`${listingData.p_warranty} days warranty`}
                </span>
              </div>
            )}
            {listingData.p_on_cod && (
              <div
                className="listing-vantage-tile"
                style={{
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <div
                  className="vant-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    background:
                      "linear-gradient(120deg, blue, #0084ff, #84c3ff)",
                  }}
                >
                  <CurrencyRupeeRoundedIcon
                    sx={{
                      fontSize: 40,
                      color: "#fff",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontSize: "0.7rem",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#353535",
                  }}
                >
                  COD available
                </span>
              </div>
            )}
            {listingData.p_brand !== "" && (
              <div
                className="listing-vantage-tile"
                style={{
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <div
                  className="vant-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    background:
                      "linear-gradient(120deg, blue, #0084ff, #84c3ff)",
                  }}
                >
                  <EmojiEventsOutlinedIcon
                    sx={{
                      fontSize: 40,
                      color: "#fff",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontSize: "0.7rem",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#353535",
                  }}
                >
                  Popular brand
                </span>
              </div>
            )}
            {listingData.p_rating >= 4 && (
              <div
                className="listing-vantage-tile"
                style={{
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                <div
                  className="vant-icon"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    height: "50px",
                    width: "50px",
                    background:
                      "linear-gradient(120deg, blue, #0084ff, #84c3ff)",
                  }}
                >
                  <StarOutlineRoundedIcon
                    sx={{
                      fontSize: 46,
                      color: "#fff",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontSize: "0.7rem",
                    textAlign: "center",
                    fontWeight: "600",
                    color: "#353535",
                  }}
                >
                  4+ Rated
                </span>
              </div>
            )}
            <div
              className="listing-vantage-tile"
              style={{
                width: "50px",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <div
                className="vant-icon"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  height: "50px",
                  width: "50px",
                  background: "linear-gradient(120deg, blue, #0084ff, #84c3ff)",
                }}
              >
                <AssuredWorkloadOutlinedIcon
                  sx={{
                    fontSize: 34,
                    color: "#fff",
                  }}
                />
              </div>
              <span
                style={{
                  fontSize: "0.7rem",
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#353535",
                }}
              >
                Secure payment
              </span>
            </div>
          </div>
          {/* {listingData.p_swiftcity_special && (
            <div
              className="listing-special-container"
              style={{
                order: "10",
                margin: "auto",
                marginTop: "-15px",
                padding: "2px 20px 0 20px",
                marginBottom: "10px",
                border: "1px solid #dddddd",
                borderRadius: "30px",
              }}
            >
              <img
                style={{
                  width: "100px",
                }}
                src="./assets/images/swift_special.png"
                alt="swift_sp"
              />
            </div>
          )} */}
        </div>
      </div>
      <div className="divi" />
      <div
        style={{
          padding: "12px",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            marginBottom: "12px",
          }}
        >
          Similar items
        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {showSimiLeftButton && (
            <button
              className="ltr-slide-btn"
              onClick={scrollSimiLeft}
              style={{
                left: "0",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                backgroundColor: "rgba(41, 41, 41, 0.5)",
                zIndex: "10",
              }}
            >
              <ArrowBackIosRoundedIcon
                sx={{
                  fontSize: 20,
                  color: "#fff",
                }}
              />
            </button>
          )}
          <div
            className="similiar-list-flex"
            ref={scrollSimiContainerRef}
            style={{
              padding: "0 3px",
              scrollBehavior: "smooth",
              display: "flex",
              overflowX: "auto",
              gap: "6px",
              minWidth: "calc(100% - 6px)",
            }}
          >
            {similarListings.map((listin, indo) => (
              <div
                className="li-listing-box"
                style={{
                  position: "relative",
                }}
                key={indo}
                onClick={() =>
                  (window.location.href = `/listing?name=${encodeURIComponent(
                    listin.p_title
                  )}&slid=${encodeURIComponent(listin.id)}`)
                }
              >
                <div
                  className="it-img-container"
                  style={{
                    width: "100%",
                    aspectRatio: "4/5",
                    borderRadius: "6px 6px 0 0",
                    backgroundImage: "url('./preloaderimg.jpeg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                  }}
                >
                  <div className="it-ongoing-offer">
                    {listin.p_sundaysale && isSunday ? (
                      <span
                        style={{
                          padding: "0 10px 0 3px",
                        }}
                      >
                        Sunday sale
                      </span>
                    ) : listin.p_seasonaloffer ? (
                      <span
                        style={{
                          padding: "0 10px 0 3px",
                        }}
                      >
                        Seasonal offer
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <img
                    src={similarUrls[indo]}
                    alt={`Listing_${indo * 307}swiftciy.png`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "4px 4px 0 0",
                    }}
                  />
                </div>
                <div
                  className="it-item-dadiv"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    zIndex: "200",
                  }}
                >
                  {listin.p_swiftcity_special && (
                    <img
                      style={{
                        width: "60px",
                        margin: "auto",
                      }}
                      src="./assets/images/swift_special.png"
                      alt="swift_sp"
                    />
                  )}

                  <div
                    className="it-brand"
                    style={{
                      fontWeight: "600",
                      fontSize: "0.8rem",
                      margin: "auto",
                    }}
                  >
                    {listin.p_brand}
                  </div>
                  <div className="it-title">{listin.p_title}</div>
                  <div
                    className="it-ratings-row"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      margin: "auto",
                      gap: "4px",
                    }}
                  >
                    <Rating
                      name="half-rating-read"
                      value={listin.p_rating}
                      precision={0.1}
                      readOnly
                      sx={{
                        color: "#ff0048",
                      }}
                    />
                    <span
                      className="it-ratings-count"
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {`(${listin.p_ratings_count})`}
                    </span>
                  </div>
                  <div
                    className="it-item-prov"
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    <div
                      className="it-em-rp"
                      style={{
                        display: "flex",
                      }}
                    >
                      <span
                        className="it-rupee-symbol"
                        style={{
                          fontSize: "0.7rem",
                        }}
                      >
                        ₹
                      </span>
                      <span
                        className="it-itprice"
                        style={{
                          fontSize: "1.2rem",
                        }}
                      >
                        {formatIndianNumber(listin.p_measurements[0].p_price)}
                      </span>
                    </div>
                    <span
                      className="it-itmrp"
                      style={{
                        fontWeight: "500",
                        fontSize: "0.9rem",
                        textDecoration: "line-through",
                        color: "#626262",
                      }}
                    >
                      ₹{formatIndianNumber(listin.p_measurements[0].p_mrp)}
                    </span>
                    <span
                      className="it-off"
                      style={{
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        color: "#0084ff",
                      }}
                    >
                      {`${getOffPers(
                        listin.p_measurements[0].p_mrp,
                        listin.p_measurements[0].p_price
                      )}% off`}
                    </span>
                  </div>
                  <span
                    className="it-ismorevari-txt"
                    style={{
                      fontSize: "0.8rem",
                      color: "#0085ba",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    Multiple variations available »
                  </span>
                </div>
              </div>
            ))}
          </div>
          {showSimiRightButton && (
            <button
              onClick={scrollSimiRight}
              className="ltr-slide-btn"
              style={{
                right: "0",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                backgroundColor: "rgba(41, 41, 41, 0.5)",
                zIndex: "10",
              }}
            >
              <ArrowForwardIosRoundedIcon
                sx={{
                  fontSize: 20,
                  color: "#fff",
                }}
              />
            </button>
          )}
        </div>
      </div>
      <div className="divi" />
      <div
        style={{
          padding: "12px",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            marginBottom: "12px",
          }}
        >
          {`More in ${listingData.p_subcategory}`}
        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {showMoreLeftButton && (
            <button
              className="ltr-slide-btn"
              onClick={scrollMoreLeft}
              style={{
                left: "0",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                backgroundColor: "rgba(41, 41, 41, 0.5)",
                zIndex: "10",
              }}
            >
              <ArrowBackIosRoundedIcon
                sx={{
                  fontSize: 20,
                  color: "#fff",
                }}
              />
            </button>
          )}
          <div
            className="similiar-list-flex"
            ref={scrollMoreContainerRef}
            style={{
              padding: "0 3px",
              scrollBehavior: "smooth",
              display: "flex",
              overflowX: "auto",
              gap: "6px",
              minWidth: "calc(100% - 6px)",
            }}
          >
            {categoryVariations.map((listin, indo) => (
              <div
                className="li-listing-box"
                style={{
                  position: "relative",
                }}
                key={indo}
                onClick={() =>
                  (window.location.href = `/listing?name=${encodeURIComponent(
                    listin.p_title
                  )}&slid=${encodeURIComponent(listin.id)}`)
                }
              >
                <div
                  className="it-img-container"
                  style={{
                    width: "100%",
                    aspectRatio: "4/5",
                    borderRadius: "6px 6px 0 0",
                    backgroundImage: "url('./preloaderimg.jpeg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                  }}
                >
                  <div className="it-ongoing-offer">
                    {listin.p_sundaysale && isSunday ? (
                      <span
                        style={{
                          padding: "0 10px 0 3px",
                        }}
                      >
                        Sunday sale
                      </span>
                    ) : listin.p_seasonaloffer ? (
                      <span
                        style={{
                          padding: "0 10px 0 3px",
                        }}
                      >
                        Seasonal offer
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <img
                    src={carVarUrls[indo]}
                    alt={`Listing_${indo * 307}swiftciy.png`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "4px 4px 0 0",
                    }}
                  />
                </div>
                <div
                  className="it-item-dadiv"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    zIndex: "200",
                  }}
                >
                  {listin.p_swiftcity_special && (
                    <img
                      style={{
                        width: "60px",
                        margin: "auto",
                      }}
                      src="./assets/images/swift_special.png"
                      alt="swift_sp"
                    />
                  )}

                  <div
                    className="it-brand"
                    style={{
                      fontWeight: "600",
                      fontSize: "0.8rem",
                      margin: "auto",
                    }}
                  >
                    {listin.p_brand}
                  </div>
                  <div className="it-title">{listin.p_title}</div>
                  <div
                    className="it-ratings-row"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      margin: "auto",
                      gap: "4px",
                    }}
                  >
                    <Rating
                      name="half-rating-read"
                      value={listin.p_rating}
                      precision={0.1}
                      readOnly
                      sx={{
                        color: "#ff0048",
                      }}
                    />
                    <span
                      className="it-ratings-count"
                      style={{
                        fontSize: "0.8rem",
                      }}
                    >
                      {`(${listin.p_ratings_count})`}
                    </span>
                  </div>
                  <div
                    className="it-item-prov"
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    <div
                      className="it-em-rp"
                      style={{
                        display: "flex",
                      }}
                    >
                      <span
                        className="it-rupee-symbol"
                        style={{
                          fontSize: "0.7rem",
                        }}
                      >
                        ₹
                      </span>
                      <span
                        className="it-itprice"
                        style={{
                          fontSize: "1.2rem",
                        }}
                      >
                        {formatIndianNumber(listin.p_measurements[0].p_price)}
                      </span>
                    </div>
                    <span
                      className="it-itmrp"
                      style={{
                        fontWeight: "500",
                        fontSize: "0.9rem",
                        textDecoration: "line-through",
                        color: "#626262",
                      }}
                    >
                      ₹{formatIndianNumber(listin.p_measurements[0].p_mrp)}
                    </span>
                    <span
                      className="it-off"
                      style={{
                        fontWeight: "600",
                        fontSize: "0.9rem",
                        color: "#0084ff",
                      }}
                    >
                      {`${getOffPers(
                        listin.p_measurements[0].p_mrp,
                        listin.p_measurements[0].p_price
                      )}% off`}
                    </span>
                  </div>
                  <span
                    className="it-ismorevari-txt"
                    style={{
                      fontSize: "0.8rem",
                      color: "#0085ba",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    Multiple variations available »
                  </span>
                </div>
              </div>
            ))}
          </div>
          {showMoreRightButton && (
            <button
              onClick={scrollMoreRight}
              className="ltr-slide-btn"
              style={{
                right: "0",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                backgroundColor: "rgba(41, 41, 41, 0.5)",
                zIndex: "10",
              }}
            >
              <ArrowForwardIosRoundedIcon
                sx={{
                  fontSize: 20,
                  color: "#fff",
                }}
              />
            </button>
          )}
        </div>
      </div>
      <div className="divi" />
      <div className="review-cont">
        <div className="ratings-total">
          <div className="r-t-h">
            <span
              style={{
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              Customer reviews
            </span>
            <button
              className="rate-listing"
              onClick={() =>
                (window.location.href = `/create-review?name=${encodeURIComponent(
                  listingData.p_title
                )}&slid=${encodeURIComponent(linkedListingID)}`)
              }
            >
              Add
            </button>
          </div>
          <div
            style={{
              padding: "10px",
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              borderRadius: "20px",
              border: "1px solid #dddddd",
            }}
          >
            <div
              style={{
                fontSize: "1.7rem",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                paddingRight: "5px",
              }}
            >
              <span
                style={{
                  fontSize: "1.8rem",
                  fontWeight: "600",
                  color: "#ff0048",
                }}
              >
                {listingData.p_rating}
              </span>
              <StarSharpIcon sx={{ fontSize: 30, color: "#ff0048" }} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderLeft: "1px solid #dddddd",
                paddingLeft: "5px",
              }}
            >
              <GroupsRoundedIcon
                sx={{ fontSize: 20, color: "#000", margin: "0 5px" }}
              />
              <span
                style={{
                  fontSize: "0.9rem",
                  color: "#000",
                }}
              >
                {formatIndianNumber(listingData.p_ratings_count)}
              </span>
            </div>
          </div>
          <div className="review-data-box">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "10px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                  }}
                >
                  5
                </span>
                <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "10px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                  }}
                >
                  4
                </span>
                <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "10px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                  }}
                >
                  3
                </span>
                <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "10px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                  }}
                >
                  2
                </span>
                <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "10px",
                }}
              >
                <span
                  style={{
                    fontSize: "0.7rem",
                    color: "#0085ba",
                    fontWeight: "600",
                  }}
                >
                  1
                </span>
                <StarSharpIcon sx={{ fontSize: 14, color: "#0085ba" }} />
              </div>
            </div>
            <div
              className="rate-row-colum"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  margin: "0 4px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "5px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    width: `${calcuFourRate()}%`,
                    backgroundColor: "#0085ba",
                    height: "10px",
                    transition: "width 0.3s ease",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "0 4px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "5px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    width: `${liRatingPercent.four}%`,
                    backgroundColor: "#0085ba",
                    height: "10px",
                    transition: "width 0.3s ease",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "0 4px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "5px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    width: `${liRatingPercent.three}%`,
                    backgroundColor: "#0085ba",
                    height: "10px",
                    transition: "width 0.3s ease",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "0 4px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "5px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    width: `${liRatingPercent.two}%`,
                    backgroundColor: "#0085ba",
                    height: "10px",
                    transition: "width 0.3s ease",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  margin: "0 4px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "5px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                <div
                  style={{
                    width: `${liRatingPercent.one}%`,
                    backgroundColor: "#0085ba",
                    height: "10px",
                    transition: "width 0.3s ease",
                  }}
                ></div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#0085ba",
                  fontWeight: "600",
                  height: "10px",
                  width: "fit-content",
                }}
              >
                {`${calcuFourRate()}%`}
              </div>
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#0085ba",
                  fontWeight: "600",
                  height: "10px",
                  width: "fit-content",
                }}
              >
                {`${liRatingPercent.four}%`}
              </div>
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#0085ba",
                  fontWeight: "600",
                  height: "10px",
                  width: "fit-content",
                }}
              >
                {`${liRatingPercent.three}%`}
              </div>
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#0085ba",
                  fontWeight: "600",
                  height: "10px",
                  width: "fit-content",
                }}
              >
                {`${liRatingPercent.two}%`}
              </div>
              <div
                style={{
                  fontSize: "0.7rem",
                  color: "#0085ba",
                  fontWeight: "600",
                  height: "10px",
                  width: "fit-content",
                }}
              >
                {`${liRatingPercent.one}%`}
              </div>
            </div>
          </div>
        </div>
        <div className="divi-vert" />
        <div
          className="li-reviews-container"
          style={{
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              margin: "12px",
            }}
          >
            Latest reviews
          </div>
          {listingReviews.length > 0 ? (
            <div
              className="li-reviews-list"
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "12px 0",
              }}
            >
              {listingReviews.map((rvw, rvInd) => (
                <div
                  className="rv-li-tile"
                  key={rvInd}
                  style={{
                    backgroundColor: rvInd % 2 === 0 ? "#f4f4f4" : "white",
                    padding: "15px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div
                    className="rv-li-tile-head"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="li-rv-profile"
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                        }}
                      >
                        <StorageImage
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          alt="cat"
                          path={rvw.user_img}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "600",
                          }}
                        >
                          {rvw.user_name}
                        </span>
                        <span
                          style={{
                            fontSize: "0.7rem",
                            fontWeight: "600",
                            fontStyle: "italic",
                            color: "#5b5b5b",
                          }}
                        >
                          {convertTimeStamp(rvw.createdAt)}
                        </span>
                      </div>
                    </div>
                    <Rating
                      name="half-rating-read"
                      defaultValue={rvw.rating}
                      precision={0.1}
                      readOnly
                      sx={{
                        color: "#ff0048",
                      }}
                    />
                  </div>
                  <div
                    className="rv-liti-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        color: "#1f1f1f",
                      }}
                    >
                      {rvw.review_title}
                    </div>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        color: "#383838",
                        marginTop: "4px",
                      }}
                    >
                      {rvw.review_desc}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      {rvw.images.map((imgg, imgInd) => (
                        <div
                          className="li-rvimg"
                          key={imgInd}
                          onClick={() => setPopupImgList(rvw, imgInd)}
                          style={{
                            borderRadius: "2px",
                            aspectRatio: "1/1",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        >
                          <StorageImage
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "2px",
                              objectFit: "cover",
                            }}
                            alt="review-swiftcity"
                            path={imgg}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "12px",
                margin: "20px 12px 12px 12px",
                border: "1px solid #dddddd",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                }}
              >
                No reviews yet!
              </span>
              <span
                style={{
                  fontSize: "0.9rem",
                }}
              >
                Be the first to share your thoughts on this product! Your review
                can help others make the right choice.
              </span>
            </div>
          )}

          {listingReviews.length > 3 && (
            <button
              style={{
                backgroundColor: "white",
                border: "none",
                color: "#0084ff",
                textDecoration: "underline",
                marginLeft: "15px",
                fontWeight: "600",
              }}
              onClick={() =>
                navigate(`/product-reviews?slid=${linkedListingID}`)
              }
            >
              View all reviews »
            </button>
          )}
        </div>
      </div>
      <div className="divi" />

      {isReviewPopup && (
        <div className="lisingrev-overlay">
          <div className="lisingrev-box" ref={revPopupRef}>
            <div
              className="lisingrev-head"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "12px",
              }}
            >
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                All photos
              </span>
              <button
                className="close-lisingrev-btn"
                onClick={reviewPopupClose}
              >
                <CloseRoundedIcon
                  sx={{
                    fontSize: 26,
                    color: "#000",
                    fontWeight: "600",
                  }}
                />
              </button>
            </div>
            <div
              className="lisingrev-content"
              style={{
                display: "flex",
                gap: "12px",
              }}
            >
              <div
                className="main-lirevimglistcont"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  objectFit: "cover",
                  position: "relative",
                }}
              >
                {revPopupImgs.length > 1 && (
                  <button
                    onClick={reviewImgPrev}
                    style={{
                      left: "0",
                      borderRadius: "50%",
                      minWidth: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      backgroundColor: "rgba(41, 41, 41, 0.5)",
                      zIndex: "10",
                      position: "absolute",
                    }}
                  >
                    <ArrowBackIosRoundedIcon
                      sx={{
                        fontSize: 20,
                        color: "#fff",
                      }}
                    />
                  </button>
                )}
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#dddddd",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "2px",
                  }}
                >
                  <img
                    className="lirvindimg"
                    style={{
                      width: "100%",
                      aspectRatio: "1/1",
                      borderRadius: "2px",
                      objectFit: "contain",
                    }}
                    alt="review-swiftcity"
                    src={revPopupImgs[reviewImgInd]}
                  />
                </div>
                {revPopupImgs.length > 1 && (
                  <button
                    onClick={reviewImgNext}
                    style={{
                      right: "0",
                      borderRadius: "50%",
                      minWidth: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      backgroundColor: "rgba(41, 41, 41, 0.5)",
                      zIndex: "10",
                      position: "absolute",
                    }}
                  >
                    <ArrowForwardIosRoundedIcon
                      sx={{
                        fontSize: 20,
                        color: "#fff",
                      }}
                    />
                  </button>
                )}
              </div>
              <div
                className="rv-anodatabox"
                style={{
                  flexDirection: "column",
                  maxWidth: "300px",
                  padding: "10px",
                  borderRadius: "12px",
                  backgroundColor: "#f3f3f3",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "30px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="li-rv-profile"
                      style={{
                        width: "35px",
                        height: "35px",
                        minWidth: "35px",
                        borderRadius: "50%",
                        border: "1px solid blue",
                      }}
                    >
                      <StorageImage
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                          padding: "1px",
                        }}
                        alt="cat"
                        path={popupIndRev.user_img}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "600",
                        }}
                      >
                        {popupIndRev.user_name}
                      </span>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "600",
                          fontStyle: "italic",
                          color: "#5b5b5b",
                        }}
                      >
                        {convertTimeStamp(popupIndRev.createdAt)}
                      </span>
                    </div>
                  </div>
                  <div className="rating-data-box">
                    <Rating
                      name="half-rating-read"
                      size="small"
                      defaultValue={popupIndRev.rating}
                      precision={0.1}
                      readOnly
                      sx={{
                        color: "#ff0048",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    color: "#1f1f1f",
                    marginTop: "15px",
                  }}
                >
                  {popupIndRev.review_title}
                </div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: "#383838",
                    marginTop: "4px",
                  }}
                >
                  {popupIndRev.review_desc}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <GlobelFooter />
      <Snackbar
        open={showToast}
        onClose={handleSnackClose}
        TransitionComponent={Fade}
        message={toastText}
        key={Fade.name}
        autoHideDuration={3000}
      />
      {isLoading && (
        <div className="loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default Listing;
