import React, { useState } from "react";
import "./auth.css";
import { setTitle } from "../../widgets/setTitle";
import GlobelHeader from "../../widgets/header";
import CatDrop from "../../widgets/categories";

const SignIn = () => {
  setTitle("SignUp - SwiftCity");
  const [numberInput, setNumberInput] = useState("");
  const [isAuthanticating, setIsAuthanticating] = useState(false);
  const [oTPInput, setoTPInput] = useState("");

  const handleNumberInputChange = (e) => {
    const inputValue = e.target.value.trim();
    if (/^[0-9]*$/.test(inputValue)) {
      setNumberInput(inputValue);
    }
  };

  const handleOTPInputChange = (e) => {
    const inputValue = e.target.value.trim();
    if (/^[0-9]*$/.test(inputValue)) {
      setoTPInput(inputValue);
    }
  };

  const toggleButtonClick = () => {
    setIsAuthanticating(!isAuthanticating);
  };

  const toggleChangeNumberClick = () => {
    setNumberInput("");
    setoTPInput("");
    setIsAuthanticating(false);
  };

  return (
    <>
      <GlobelHeader />
      <CatDrop />
      <div className="log-container">
        <div className="log-cont-head">
          <span
            style={{
              fontSize: "1.6rem",
            }}
          >
            {isAuthanticating ? "Verification" : "Sign In"}
          </span>
          <div
            style={{
              display: "flex",
              gap: "3px",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              {isAuthanticating
                ? `Enter the OTP sent to +91-${numberInput}`
                : "Enter your phone number to continue"}
            </span>
            {isAuthanticating && (
              <button
                onClick={toggleChangeNumberClick}
                style={{
                  padding: "0",
                  backgroundColor: "transparent",
                  color: "#0084ff",
                  fontSize: "0.8rem",
                  border: "none",
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
              >
                ← Change number
              </button>
            )}
          </div>
        </div>
        <div className="log-body-container">
          {isAuthanticating ? (
            <input
              type="tel"
              onChange={handleOTPInputChange}
              value={oTPInput}
              maxLength={6}
              id="log-otp-input"
              placeholder="Verification Code"
              style={{
                padding: "8px",
                borderRadius: "4px",
                fontSize: "1rem",
                border: "1px solid #ccc",
                width: "100%",
                outline: "none",
                transition: "border-color 0.3s",
              }}
            />
          ) : (
            <div
              className="log-input-row"
              style={{ position: "relative", margin: "8px 0" }}
            >
              <span
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  pointerEvents: "none",
                  color: "#656565",
                }}
              >
                +91
              </span>
              <input
                type="tel"
                onChange={handleNumberInputChange}
                value={numberInput}
                maxLength={10}
                id="log-phone-input"
                placeholder="Phone number"
                onFocus={(e) => (e.target.style.borderColor = "#0084ff")}
                onBlur={(e) => (e.target.style.borderColor = "#ccc")}
                style={{
                  padding: "8px 8px 8px 40px",
                  borderRadius: "4px",
                  fontSize: "1rem",
                  border: "1px solid #ccc",
                  width: "100%",
                  outline: "none",
                  transition: "border-color 0.3s",
                }}
              />
            </div>
          )}

          {isAuthanticating && (
            <div
              className="log-sell-box"
              style={{
                marginBottom: "12px",
                marginTop: "8px",
              }}
            >
              <span
                style={{
                  fontSize: "0.8rem",
                }}
              >
                Did not receive the OTP?
              </span>
              <button
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  color: "#0084ff",
                  border: "none",
                  fontWeight: "600",
                  width: "fit-content",
                }}
              >
                Resend
              </button>
            </div>
          )}
          {isAuthanticating ? (
            <button
              onClick={toggleButtonClick}
              style={{
                padding: "8px 0",
                border: "none",
                backgroundColor: "#0084ff",
                borderRadius: "8px",
                fontSize: "1rem",
                fontWeight: "600",
                color: "white",
                margin: "12px 0",
              }}
            >
              Verify
            </button>
          ) : (
            <button
              onClick={toggleButtonClick}
              style={{
                padding: "8px 0",
                border: "none",
                backgroundColor: "#0084ff",
                borderRadius: "8px",
                fontSize: "1rem",
                fontWeight: "600",
                color: "white",
                margin: "12px 0",
              }}
            >
              Continue
            </button>
          )}

          {!isAuthanticating && (
            <span
              style={{
                fontSize: "0.8rem",
              }}
            >
              By clicking, I agree to SwiftCity's{" "}
              <button
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  color: "#0084ff",
                  border: "none",
                  fontWeight: "500",
                }}
              >
                Terms & Conditions
              </button>{" "}
              and{" "}
              <button
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  color: "#0084ff",
                  border: "none",
                  fontWeight: "500",
                }}
              >
                Privacy Policy
              </button>
            </span>
          )}
        </div>
        {!isAuthanticating && (
          <div className="log-sell-box">
            <span
              style={{
                fontSize: "0.8rem",
              }}
            >
              Are you a seller?
            </span>
            <button
              style={{
                padding: "0",
                backgroundColor: "white",
                color: "#0084ff",
                border: "none",
                fontWeight: "500",
                width: "fit-content",
              }}
            >
              Click here
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SignIn;
